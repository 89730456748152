// Assessment, DonutLarge, Equalizer, Timeline, ShowChart, TrendingDown/TrendingUp, MultilineChart, TableChart
import { useEffect, useState } from 'react';
import SideMenu from './SideMenu';
import TopBar from './TopBar';

function Navigation({ isAuthenticated }) {
  const [open, setOpen] = useState(false);
  const [forceDrawerOpen, setForceDrawerOpen] = useState(false);

 useEffect(() => {
   if (!isAuthenticated)
     setOpen(false)
 }, [isAuthenticated]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => !forceDrawerOpen && setOpen(false);
  return (
    <>
      <TopBar onDrawerOpen={handleDrawerOpen} open={open} isAuthenticated={isAuthenticated} />
      <SideMenu onDrawerClose={handleDrawerClose} open={open} hide forceDrawerOpen={setForceDrawerOpen} />
    </>
  );
}

export default Navigation;