import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ORDERS_SIMPLE, GET_PRODUCT_TYPE_DETAILS } from '../../api/orders';
import { GET_CLASSIFICATION_DETAILS } from '../../api/classifications';
import { GET_PRODUCTS_AGGREGATE } from '../../api/products';
import { GET_PRODUCT_TYPE_MEASUREMENTS, GET_PRODUCT_MEASUREMENTS } from '../../api/measurements';
import { GET_FOREIGN_OBJECTS } from '../../api/foreignObjects'; 

import { DataSelectionContext, usePageTitle } from '../../contexts';
import ForeignObjectsTable from './ForeignObjectsTable';
import Quality from '../pizza/Dashboard2/Quality';
import Toppings from '../pizza/Dashboard/Toppings';
import { DashboardRow } from '../pizza/Dashboard/containers';
import OrderList from './list';
import { Grid, List, ListItem, ListItemText, ListSubheader, Typography, CircularProgress, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withTheme from '@mui/styles/withTheme';
import { formatDistance, format, startOfDay, addDays, differenceInMinutes  } from 'date-fns';
import { formatInterval, formatPercentage, formatNumber } from '../../helpers';
import * as constants from "../../constants"
import AreaChart from '../charts/AreaChart';
import addMinutes from 'date-fns/addMinutes';
import { useParams, withRouter } from 'react-router';
import GaugeChart from '../charts/GaugeChart';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: '0em'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '2em'
    }
  },
  containerHeader: {
    '&': {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
      //marginTop: '2em',
      marginBottom: 0,
      color: theme.headerColor,
      fontFamily: 'gotham',
      position: 'relative',
      fontSize: '2em',
      zIndex: 1,
      overflow: 'hidden',
      textAlign: 'center',
      fontWeight: 500,
    },
    '&:before, &:after': {
      position: 'absolute',
      top: '51%',
      overflow: 'hidden',
      width: '49.4%',
      height: '1px',
      content: '""',
      backgroundColor: theme.headerColor,
    },
    '&:after': {
      marginLeft: '0.4%',
    },
    '&:before': {
      marginLeft: '-49.9%',
      textAlign: 'right',
    }
  },
  header: {
    textTransform: 'uppercase',
    letterSpacing: '0.2rem',
    fontSize: '1.0rem',
    marginTop: '1em',
    [theme.breakpoints.down('md')]: {
      '&': {
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        marginTop: '1.5em',
        marginBottom: '0.5em',
        color: theme.headerColor,
        fontFamily: 'gotham',
        position: 'relative',
        fontSize: '1.5rem',
        zIndex: 1,
        overflow: 'hidden',
        textAlign: 'center',
        fontWeight: 500,
      },
      '&:before, &:after': {
        position: 'absolute',
        top: '51%',
        overflow: 'hidden',
        width: '49.4%',
        height: '1px',
        content: '""',
        backgroundColor: theme.headerColor,
      },
      '&:after': {
        marginLeft: '0.4%',
      },
      '&:before': {
        marginLeft: '-49.9%',
        textAlign: 'right',
      }
    }
  },
  list: {
    width: '98%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  productivityText: {
    fontFamily: theme.fontFamily,
    color: theme.headerColor,
    fontWeight: 500,
    fontSize: 'min(10vw, 2em)'
  }
}));

function mapToClassificationData(products, classificationDetails) {
    // eslint-disable-next-line no-unused-vars
    const inputClassificationDetails = [{
        "__typename": "production_intelligence_spec_return_type_classification_details",
         "tm": new Date("2022-01-19T18:32:00+00:00"),
         "time_bucket": "2022-01-19T18:32:00+00:00",
         "classification": 1,
         "count": 1,
         "result_id": 6011683,
         "result_name": "ToppingAmount" 
    }];
    // eslint-disable-next-line no-unused-vars
    const inputProducts = [{
        "__typename": "production_intelligence_spec_return_type_product_count",
         "time_bucket": "2022-01-19T17:53:00+00:00",
         "lane": 0,
         "product_type_id": 1642568840138234,
         "count_total": 6,
         "count_failed": 0
        }];
    const resultTypes = classificationDetails.map(d => d.result_name).filter((d, i, arr) => arr.indexOf(d) === i);

    const grouped = Object.entries(constants.classificationsDisplay).map(([classificationIndex, { tag }]) => {
        const results = resultTypes.map(resultType => {
            const count = classificationDetails
                .filter(d => {
                    const isSameResultName = d.result_name === resultType;
                    const isSameClassificationIndex =  d.classification_index === parseInt(classificationIndex);
                    return isSameResultName && isSameClassificationIndex;
                })
                .reduce((sum, d) => sum + (d.count || 0), 0);
            return { name: resultType, count };
        });
        const count = results.reduce((sum, d) => sum + d.count, 0);
        return { classification: tag, results, count };
    });
    const countOk = products.map(d => d.count_total - d.count_failed).reduce((sum, d) => sum + d, 0);
    const indexOk  = Object.entries(constants.classificationsDisplay).find(([classificationIndex, { tag }]) => tag === 'ok')[0];
    grouped[indexOk].count = countOk;
    return grouped;
}

function getToppingData(dataProductTypeMeasurements, dataProductMeasurements){
    if (!dataProductTypeMeasurements || !dataProductMeasurements)
        return [];
    // eslint-disable-next-line no-unused-vars
    const dptm = {
        "measurement_type":{"id":1,"name":"width"},
        "product_type":{"id":1643605631499826,"name":"1980 (156892 2pk) Grandiosa Classic 10x575g"},
        "value":280
    };
    // eslint-disable-next-line no-unused-vars
    const dpm = {lane: 1, measurement_type_id: 579089562, value: 0.18533061};
    // multiple dpm points to same target, i.e. 
    //  {id: 443798030, name: 'dist_Cheese_Q3} AND {id: 443798022, name: 'dist_Cheese_Q1'} points to
    // id: 647382705, name: "target_Cheese"
    // dpm does not have target name, only measurement_type_id (i.e. dist_Cheese_Q1 id and NOT target_Cheese id)
    // map id 443798022 to Cheese and then Cheese to 647382705/"target_Cheese"

    const targetPrefix = 'target_';
    const productTypeMeasurements = dataProductTypeMeasurements.measurements || [];
    const productMeasurements = dataProductMeasurements.measurements || [];
    const targets = productTypeMeasurements 
        .filter(d => d?.measurement_type?.name?.startsWith(targetPrefix))
        .map(d => ({
            name: d.measurement_type.name.slice(targetPrefix.length),
            target: d.value
        }))
    ;
    const getMeasurementTypeName = measurementTypeId =>
        productTypeMeasurements.find(ptm => ptm.measurement_type?.id === measurementTypeId)?.measurement_type.name
    const getTarget = measurementTypeId =>{
        const measurementTypeName = getMeasurementTypeName(measurementTypeId);
        if (!measurementTypeName)
            return null;
        //const distributionPrefix = 'dist_';
        //const distributionPrefixLength = distributionPrefix.length;
        const distributionSuffix = '_Qx';
        const distributionSuffixLength = distributionSuffix.length;
        //const getName = d => d.slice(distributionPrefixLength, -distributionSuffixLength);
        const quadrant = measurementTypeName.slice(-distributionSuffixLength+1);
        return {
            ...(targets.find(target => measurementTypeName.includes(target.name)) || {}),
            quadrant
        };
    }

    const productMeasurementsWithTargets = productMeasurements.map(dpm => {
        const target = getTarget(dpm.measurement_type_id);
        if (!target)
            return null;
        return {
            ...dpm,
            ...target
        };
    })
    // eslint-disable-next-line no-unused-vars
    const pmwt = {
        "lane": 0,
        "measurement_type_id": 631863132,
        "value": 0.1420918,
        "target": 0.135922,
        "name": "cheese",
        "quadrant": "Q3"
    };
    const common = productMeasurementsWithTargets.reduce((acc, d, i, arr) => {
        if (!d)
            return acc;
        const isAdded = acc.some(({ name }) => name === d.name);
        if (isAdded)
            return acc;
        const laneEntries = arr.filter(laneEntry => laneEntry && laneEntry.name && laneEntry.name === d?.name)
        const quadrants = Object.keys(laneEntries.reduce((acc, d) => ({ ...acc, [d.quadrant]: 0 }), {})).sort();
        const quadrantValues = quadrants.map(quadrant =>
            laneEntries
                .filter(d => d.quadrant === quadrant)
                .reduce((acc, d, i, arr) => acc + d.value / arr.length, 0)
        );
        const newEntry = {
            ...d,
            quadrantValues,
            lane: -1
        }
        return [...acc, newEntry];
    }, []);
    return common;
}


function Header({ children }){
  const classes = useStyles();
  return (
    <ListSubheader component="div" id="nested-list-subheader">
      <Typography variant="h4" className={classes.header}>{children}</Typography>
    </ListSubheader >
  );
}

function convertToAreaChartData(data){
  //const exampleOutput = { "tm": "2021-09-15T12:40:00.000Z", "ok": 0, "info": 0, "warn": 0, "fail": 0 };
  //const exampleInput = { "bucket": "2021-09-15T07:50:00+00:00", "count": 5, "classification_index": 0 };
  const reduced = data.reduce((acc, d) => {
    const oldData = acc[d.bucket] || { tm: d.bucket, ok: 0, info: 0, warn: 0, fail: 0};
    const classification = constants.classificationsDisplay[d.classification_index];
    const tag = classification.tag;
    const newData = { ...oldData, [tag]: oldData[tag] + d.count };
    return { ...acc, [d.bucket]: newData };
  }, {});
  const chartData = Object.entries(reduced).map(([tm, data]) => ({ ...data, tm: (new Date(tm)).getTime() }));
  return chartData;
}

function mapToProductionData(products, classificationDetails) {
    const classificationTags = Object.values(constants.classificationsDisplay).map(d => d.tag);
    const inputData = products.map(d => {
        const classificationInput = classificationDetails
            .filter(c => c.time_bucket === d.time_bucket)
            .map(c => ({ ...c, classification: classificationTags[c.classification_index] }))
        ;
        const classificationData = classificationTags.reduce((acc, t) => ({
            ...acc,
            [t]: (acc[t] || 0) + classificationInput.filter(c => c.classification === t).reduce((a, c) => a + c.count, 0)
        }), { ok: d.count_total - d.count_failed })
        return { ...d, ...classificationData }
    })
    return inputData;
}

function Container({ name, children }) {
    const classes = useStyles();
    return (
        <div>
            <h1 className={classes.containerHeader}>{name}</h1>
            <DashboardRow>
                {children}
            </DashboardRow>
        </div>
    )
}


function Orders({ theme, history }){

  const [getClassificationDetails, classificationResult] = useLazyQuery(GET_CLASSIFICATION_DETAILS);
  const { data: dataClassificationDetails, loading: loadingClassificationDetails, error: errorClassificationDetails } = classificationResult;

  const [getProductAggregates, productAggregatesResult] = useLazyQuery(GET_PRODUCTS_AGGREGATE);
  const { data: dataProductAggregates, loading: loadingProductAggregates, error: errorProductAggregates } = productAggregatesResult;

  const [getForeignObjects, foreignObjectsResult] = useLazyQuery(GET_FOREIGN_OBJECTS );
  const { data: dataForeignObjects, loading: loadingForeignObjects, error: errorForeignObjects } = foreignObjectsResult;

  const [getProductTypeMeasurements, productTypeMeasurementsResult] = useLazyQuery(GET_PRODUCT_TYPE_MEASUREMENTS );
  const { data: dataProductTypeMeasurements, loading: loadingProductTypeMeasurements, error: errorProductTypeMeasurements } = productTypeMeasurementsResult;

  const classes = useStyles();
  const { dateRange } = useContext(DataSelectionContext);
  const { startDate, endDate } = dateRange.value;
  const [order, setOrder] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (!id)
      return;
    const tm = new Date(parseInt(id.slice(0, -3)))
    const startDate = startOfDay(tm);
    if (startDate < dateRange.value.startDate || startDate > dateRange.value.endDate) {
      const endDate = addDays(startDate, 1);
      dateRange.set({ startDate, endDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const title = order ? `Orders / ${order.name}` : `Orders /  ${format(startDate, 'd. MMMM yyyy')} .. ${format(endDate, 'd. MMMM yyyy')}`;
  usePageTitle(title);

  const pageSize = 5;
  const [page, setPage] = useState(1);

  const { data: dataOrders, error: errorOrders, loading: loadingOrders, refetch: refetchOrders } = useQuery(GET_ORDERS_SIMPLE, {
    variables: {
      limit: pageSize,
      offset: pageSize * (page - 1),
      tmStart: startDate,
      tmEnd: endDate,
      order_by: { id: 'desc' }
    },
    onCompleted: data => {
    }
  });

  if (errorOrders)
    console.warn(errorOrders);

  const orderCount = dataOrders?.orderAggregates?.aggregate?.count || 0;

  const [getProductMeasurements, productMeasurementsResult] = useLazyQuery(GET_PRODUCT_MEASUREMENTS);
  const { data: dataProductMeasurements, loading: loadingProductMeasurements, error: errorProductMeasurements } = productMeasurementsResult;
  useEffect(() => {
    if (!id || !dataOrders)
      return;
    const order_id = parseInt(id);
    const order = (dataOrders?.orders || []).find(order => order.id === order_id);
    if (!order)
      return;
    setOrder(order);

    const product_type_id = order.product_types[0]?.id;
    const { tm_start, tm_end } = order;
    getClassificationDetails({
      variables: { product_type_id },
      //onCompleted: data => { console.log('Classification details fetched', data) }
    });
    getProductAggregates({
      variables: { product_type_id },
      //onCompleted: data => { console.log('Products fetched', data) }
    });
    getForeignObjects({
      variables: { tm_start, tm_end },
      //onCompleted: data => { console.log('Foreign objects fetched', data) }
    });
    getProductTypeMeasurements({
      variables: { product_type_id },
      onCompleted: data => {
        //console.log('Product type measurements fetched', data);
        const measurement_prefix = 'dist_';
        const measurementTypeIds = (data?.measurements || [])
            .filter(d => d?.measurement_type?.name?.startsWith(measurement_prefix))
            .map(d => d.measurement_type.id)
            ;
        const measurementTypeIdsAsString = `{${measurementTypeIds.toString()}}`
        getProductMeasurements({
          variables: { tm_start, tm_end, measurement_type_ids: measurementTypeIdsAsString },
          //onCompleted: data => console.log('Measurements fetched', data)
        });
      } 
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrders, id]);

  const handleOrderSelected = order => history.push(`/orders/${order.id}`);

  const dataKeys = [Object.values(constants.classificationsDisplay).map(({ displayName, tag }) => ({
    name: displayName,
    key: tag,
    color: theme.results[tag]
  }))];

  const tagToDisplayName = Object.values(constants.classificationsDisplay).reduce((acc, { displayName, tag }) => ({ ...acc, [tag]: displayName }), {});

  // optimize: Only calculate when dataProductTypeDetails?.productTypeDetails changes
  //const productTypeDetails = dataProductTypeDetails?.productTypeDetails || [];
  //const chartData = convertToAreaChartData(productTypeDetails);
  const setTimestamp = d => ({ ...d, tm: new Date(d.time_bucket) });
  const products = Object.values((dataProductAggregates?.products || [])
    .map(setTimestamp)
    .reduce((acc, d) => {
      const key = d.time_bucket;
      const oldData = acc[key];
      const newData = {
        ...(oldData || d),
        count_total: (oldData?.count_total || 0) + d.count_total,
        count_failed: (oldData?.count_failed || 0) + d.count_failed
      };
      return { ...acc, [key]: newData }
    }, {})
  );
  const classificationDetails = Object.values((dataClassificationDetails?.classificationDetails || [])
    .map(setTimestamp)
    .reduce((acc, d) => {
      const key = `${d.time_bucket}#${d.classification}#${d.result_id}`;
      const oldData = acc[key];
      const newData = {
        ...(oldData || d),
        count: (oldData?.count || 0) + d.count
      };
      return {
        ...acc,
        [key]: newData
      }
    }, {})
  );
  ;
  const productionData = mapToProductionData(products, classificationDetails);
  const chartData = productionData.map(d => ({
    ...d,
    tm: d.tm.getTime()
  }))
  //const productionWithoutGaps = fillGaps(productionData );
  //console.log({ productionData, productionWithoutGaps})
  const classifications = mapToClassificationData(products, classificationDetails);

  //const classifications = mapToClassificationData(products, classificationDetails);
  const orders = dataOrders?.orders || [];

  //const totalProductCount = order?.product_count_total || 0;
  const totalProductCount = dataProductAggregates?.products?.reduce((acc, d) => acc + d.count_total, 0) || 0;
  const failedProductCount = dataProductAggregates?.products?.reduce((acc, d) => acc + d.count_failed, 0) || 0;
  const failedProductPct = totalProductCount > 0 ? failedProductCount / totalProductCount : 0;
  //order.product_count_failed / totalProductCount
  //const orderStart = new Date(order?.tm_start);
  //const orderEnd = order?.tm_end ? new Date(order?.tm_end) : new Date();
  const orderStart = productionData.reduce((acc, d) => acc && acc < d.tm ? acc : d.tm, null) || new Date(order?.tm_start);
  const orderEnd = productionData.reduce((acc, d) => acc && acc > d.tm ? acc : d.tm, null) || (order?.tm_end ? new Date(order?.tm_end) : new Date());
  const productionTimeMinutes = differenceInMinutes(orderEnd, orderStart);
  const avgProductsPerMinute = totalProductCount / (productionTimeMinutes || 1);
  const capacityProductsPerMinute = 120;
  const productivity = 100 * avgProductsPerMinute / capacityProductsPerMinute;


  const toppingsData = getToppingData(dataProductTypeMeasurements, dataProductMeasurements)
  const toppings = toppingsData.map(d => ({
    name: d.name,
    //first is overall
    coverage: d.quadrantValues.slice(1).map(v => v / d.target)
  }));


  const loadingClassification = loadingClassificationDetails || loadingProductAggregates;
  const errorClassification = [errorClassificationDetails, errorProductAggregates].filter(d => !!d);
  if (errorClassification.length > 0)
    console.error(errorClassification);

  const loadingToppings = loadingProductMeasurements || loadingProductTypeMeasurements; 
  const errorToppings = [errorProductTypeMeasurements, errorProductMeasurements].filter(d => !!d);
  if (errorToppings.length > 0)
    console.error(errorToppings);

  return (
    <div className={classes.container}>
      <Grid container spacing={0} direction="row" justifyContent="space-around" alignItems="flex-start" style={{ minHeight: 'min(100vw,24vh)', width: '100%' }}>
        <Grid item xs={12} md={4} style={{ height: '100%' }}>
          <OrderList
            orders={orders}
            selectedOrder={order}
            onRefresh={useCallback(() => refetchOrders(), [refetchOrders])}
            onClick={handleOrderSelected}
            orderCount={orderCount}
            pageSize={pageSize}
            currentPage={page}
            setPage={setPage}
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ height: '100%' }}>
          <List
            className={classes.list}
            subheader={<Header>Order info</Header>}
          >
            {order &&
              <>
                <ListItem>
                  <ListItemText primary={order.name} secondary='Name' />
                </ListItem>
                <ListItem>
                  <ListItemText primary={formatInterval(orderStart, orderEnd)} secondary='Period' />
                </ListItem>
                <ListItem>
                  <ListItemText primary={formatDistance(orderStart, orderEnd, { addSuffix: false })} secondary='Duration' />
                </ListItem>
                <ListItem>
                  <ListItemText primary={formatNumber(totalProductCount)} secondary='Products total' />
                </ListItem>
                <ListItem>
                  <ListItemText primary={formatPercentage(failedProductPct)} secondary='Products failed' />
                </ListItem>
              </>
            }
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <Header>
            Productivity
          </Header>
          <div style={{ width: 'min(100vw,95%)', height: 'min(90vw,25vh)' }}>
            <GaugeChart value={productivity} />
          </div>
          <span className={classes.productivityText}>
            {Math.round(avgProductsPerMinute).toLocaleString()} Product{Math.round(avgProductsPerMinute) === 1 ? '' : 's'}/min
          </span>
        </Grid>
        <Grid item xs={12}>
          <Container name='Production'>
            <div style={{ width: 'min(100vw,95%)', height: 'min(100vw,400px)' }}>
              <AreaChart
                data={chartData}
                dataKeys={dataKeys}
                tickFormatter={d => format(d, 'HH:mm')}
                tooltipLabelFormatter={(value, name) =>
                  <span>
                    {format(value, 'HH:mm')} .. {format(addMinutes(value, 10), 'HH:mm')}
                    <br />
                    <span style={{ fontSize: 'small' }}>
                      {format(value, 'd. MMMM yyyy')}
                    </span>
                  </span>}
                tooltipFormatter={(value, name, props) => [value, tagToDisplayName[name]]}
                legendFormatter={(value, entry, index) => tagToDisplayName[value]}
                scale='time'
                type='number'
              />
            </div>
          </Container>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '2em'}}>
          <Container name='Classification'>
            {loadingClassification && <CircularProgress />}
            {errorClassification.length > 0 && <Alert severity='error'>Failed to load classification data</Alert>}
            {!loadingClassification && <Quality classifications={classifications} />}
          </Container>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '2em'}}>
          <Container name='Toppings'>
            {loadingToppings && <CircularProgress />}
            {errorToppings.length > 0 && <Alert severity='error'>Failed to load topping data</Alert>}
            {!loadingToppings && <Toppings toppings={toppings} />}
          </Container>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginTop: '2em' }}>
          <Container name='Foreign objects'>
            <ForeignObjectsTable data={dataForeignObjects?.foreignObjects || []} />
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default withTheme(withRouter(Orders));