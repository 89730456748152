import { useState, useEffect } from 'react';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
//import { gql/*, useQuery*/ } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//import { __schema as schema } from './schema';
import schema from './schema.json';
import inMemoryJWT from './authentication/inMemoryJwt';
import buildQuery from './buildQuery';
import './App.css';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import Dashboard from './components/pizza/Dashboard2';
import Images from './components/Images';
import Navigation from './components/Navigation';
import { AuthenticationContext, TitleContext, DataSelectionContext, usePageTitle } from './contexts';
import { CircularProgress } from '@mui/material';
import { SignIn, SignOut, DashboardAutosignin } from './components/authentication';
//import { subHours } from 'date-fns';
import { startOfDay, subDays } from 'date-fns';
import Orders from './components/orders';
import ProductTypes from './components/ProductTypes';
import Events from './components/events'

function ProtectedRoute({ children, ...restOfProps }) {
  return (
    <AuthenticationContext.Consumer>
      {({ isAuthenticated }) => 
        <Route {...restOfProps} >
          {isAuthenticated && children}
          {!isAuthenticated && <Redirect to="/signin" />}
        </Route>

      }
    </AuthenticationContext.Consumer>
  );
}

function ExamplePage() {
  usePageTitle('Example page title');
  return (
    <div>Example content</div>
  )
}

const uri = 'https://api.trivision.dk/v1/graphql';

// authentication
const buildDataProvider = async (setDataProvider) => {
  const authLink = setContext(async () => {
    if (!inMemoryJWT.getToken()) {
      const refreshSuccess = await inMemoryJWT.performTokenRefresh();
      if (!refreshSuccess)
        return null;
    }
    const tokenInfo = inMemoryJWT.getToken();
    const token = tokenInfo && tokenInfo.accessToken;
    if (!token)
      return null;
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  });

  const httpLink = createHttpLink({ uri });
  const myClient = new ApolloClient({
    //link: authLink.concat(splitLink),
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  //const dataProvider = await buildGraphQLProvider({ client: myClient, buildQuery, introspection: { schema } })
  const dataProvider = { client: myClient, buildQuery, introspection: { schema } };
  setDataProvider(() => dataProvider);
};

function Loading() {
  return <CircularProgress />
}

function App() {
  const [pageTitle, setPageTitle] = useState('');
  const [dataProvider, setDataProvider] = useState(null);
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [dateRange, setDateRange] = useState({ startDate: startOfDay(subDays(new Date(), 7)), endDate: new Date()});
  //const [dateRange, setDateRange] = useState({ startDate: subDays(new Date(), 7), endDate: new Date()});
  //const [dateRange, setDateRange] = useState({ startDate: subHours(new Date(), 6), endDate: new Date()});

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const token = inMemoryJWT.getToken();
        if (token){
          setIsAuthenticated(token);
          setToken(token);
        }
        else {
          const isAuthenticated = await inMemoryJWT.performTokenRefresh();
          setIsAuthenticated(isAuthenticated );
          setToken(isAuthenticated ? inMemoryJWT.getToken() : null);
        }
      }
      catch (e) {
        console.warn(e);
        setToken(null);
      }
    };
    refreshToken();
  }, []);
  useEffect(() => buildDataProvider(setDataProvider), []);

  if (isAuthenticated === null)
    return <Loading />;

  if (!dataProvider)
    return <Loading />;

  const setTokenAndIsAuthenticated = token => {
    setToken(token);
    setIsAuthenticated(!!token);
  }

  return (
    <AuthenticationContext.Provider value={{ isAuthenticated, token }}>
      <ApolloProvider client={dataProvider.client}>
        <TitleContext.Provider value={{ pageTitle, setPageTitle }}>
        <DataSelectionContext.Provider value={{ dateRange: { set: setDateRange, value: dateRange } }}>
          <BrowserRouter>
            <div className="App">
              <Navigation isAuthenticated={isAuthenticated} />
              <div style={{ paddingTop: '4em' }}>
                <Route exact path='/signin'>
                  <SignIn isAuthenticated={isAuthenticated} setToken={setTokenAndIsAuthenticated} />
                </Route>
                <Route exact path='/signout'>
                  <SignOut token={token} isAuthenticated={isAuthenticated} setToken={setTokenAndIsAuthenticated} />
                </Route>
                <Route exact path='/'>
                  <SignIn isAuthenticated={isAuthenticated} setToken={setTokenAndIsAuthenticated} />
                </Route>
                <ProtectedRoute path='/images'>
                  <Images />
                </ProtectedRoute>
                <Route  path='/dashboardautosignin'>
                  <DashboardAutosignin isAuthenticated={isAuthenticated} setToken={setTokenAndIsAuthenticated} />
                </Route>
                <ProtectedRoute path='/example_page'>
                  <ExamplePage />
                </ProtectedRoute>
                <ProtectedRoute path='/dashboard'>
                  <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute exact path='/orders'>
                  <Orders />
                </ProtectedRoute>
                <ProtectedRoute path='/orders/:id'>
                  <Orders />
                </ProtectedRoute>
                <ProtectedRoute exact path='/productTypes'>
                  <ProductTypes />
                </ProtectedRoute>
                <ProtectedRoute exact path='/events'>
                  <Events />
                </ProtectedRoute>
              </div>
            </div>
          </BrowserRouter>
        </DataSelectionContext.Provider>
        </TitleContext.Provider>
      </ApolloProvider>
    </AuthenticationContext.Provider>
  );
}

export default App;
