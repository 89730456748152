import * as React from 'react';
import { Grid } from '@mui/material';
import PizzaTopping from '../../charts/PizzaTopping';
import { Header } from './containers';

//interface ToppingChartContainerProps{
//    name: string;
//    coverage: any;
//}
interface ToppingCoverage {
    name: string;
    coverage: number[];
}
function ToppingChartContainer({ name, coverage } : ToppingCoverage){
        //<Box p={{xs: 1, sm: 3, md: 4 }}></Box>
    return (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{ height: '100%'}}>
            <div style={{ width: 'min(100vw,95%)', height: '17vh'}}>
                <PizzaTopping coverage={coverage} />
            </div>
            <Header>{name}</Header>
        </Grid>
    )
}

interface ToppingsProps {
    toppings: Array<ToppingCoverage>;
}
const Toppings = ({ toppings } : ToppingsProps) => <>{toppings.map(d => <ToppingChartContainer key={d.name} {...d} />)}</>;

export default Toppings;
