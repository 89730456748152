import ClickAwayListener from '@mui/material/ClickAwayListener';
import Drawer from '@mui/material/Drawer';
import IconButton  from '@mui/material/IconButton';
import List  from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon  from '@mui/material/ListItemIcon';
import ListItemText  from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider'
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// Assessment, DonutLarge, Equalizer, Timeline, ShowChart, TrendingDown/TrendingUp, MultilineChart, TableChart
import DashboardIcon from '@mui/icons-material/Dashboard';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import OrderIcon from '@mui/icons-material/ListAlt';
import ProductTypesIcon from '@mui/icons-material/ColorLens';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CustomerIcon from '@mui/icons-material/Apartment';
import EventIcon from '@mui/icons-material/Book';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { DataSelectionContext, AuthenticationContext, DataContextType } from '../../contexts';
import { drawerWidth } from '../../constants';
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import format from 'date-fns/format';
import ProductionLineSelector from './ProductionLineSelector';
import { Theme } from '@mui/material/styles/createTheme';
import React from 'react';

const useStyles = makeStyles((theme : Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  bottomPush: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    paddingBottom: '1em',
  }
}));

interface DatePickerProps {
  open: boolean;
  toggle: () => void;
  setDateRange: (dateRange: DateRange) => void;
};

function DatePicker({ open, toggle, setDateRange } : DatePickerProps) {
  // convert selected local tz to UTC
  const convertLocalTzToUTC = (tm : Date) => new Date(tm.getTime() - tm.getTimezoneOffset() * 60 * 1000);
  const convert = (range: DateRange) => Object.entries(range).reduce((acc, [k, v]) => ({ ...acc, [k]: convertLocalTzToUTC(v) }), {});
  return (
        <div style={{ position: 'fixed', top: '5em', zIndex: 2 }}>
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range) => setDateRange(convert(range))}
          />
        </div>
  );
}

function ShortDate({ start, end } : { start?: Date, end?: Date }) {
  if (!start)
    return <i>No date selected</i>;
  if (!end || start.getTime() === end.getTime())
    return <>{format(start, 'd. MMMM yyyy')}</>;
  if (start.getMonth() === end.getMonth())
    return <>{format(start, 'd')}..{format(end, 'd. MMMM yyyy')}</>;
  if (start.getFullYear() === end.getFullYear())
    return <>{format(start, 'd/M')}..{format(end, 'd/M-yyyy')}</>;
  return <>{format(start, 'd/M-yyyy')}..{format(end, 'd/M-yyyy')}</>;
}

type ListItemLinkProps = {
  text: string;
  Icon: React.ElementType;
  to: any;
  //onClick: (e: any) => void;
  onClick: any;
  className?: string;
};

function ListItemLink({ text, Icon, to, ...props } : ListItemLinkProps ) {
  return (
    <ListItem button key={text} component={Link} to={to} {...props}>
      <ListItemIcon><Icon /></ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
}

function ListItemDateSelector() {
  const [dateRangeOpen, setDateRangeOpen] = useState(false);
  const toggleDateRangeOpen = () => setDateRangeOpen(!dateRangeOpen);
    return (
        <ListItem button>
            <ListItemIcon onClick={toggleDateRangeOpen}><DateRangeIcon /></ListItemIcon>
            <DataSelectionContext.Consumer>
                {({ dateRange } : DataContextType ) =>
                    <>
                        <DatePicker open={dateRangeOpen} toggle={toggleDateRangeOpen} setDateRange={dateRange.set} />
                        <span onClick={toggleDateRangeOpen}>
                            <ShortDate start={dateRange?.value?.startDate} end={dateRange?.value?.endDate} />
                        </span>
                    </>
                }
            </DataSelectionContext.Consumer>
        </ListItem>
    );
}

function ListItemProductionLineSelector({forceDrawerOpen} : { forceDrawerOpen: (open: boolean) => void }) {
  const [open, setOpen] = useState(false);
  const setOpenAndForceDrawer = (open : boolean) => {
      forceDrawerOpen(open);
      setOpen(open);
  };
    return (
        <ListItem >
            <ListItemIcon onClick={() => setOpenAndForceDrawer(!open)}><CustomerIcon /></ListItemIcon>
            <AuthenticationContext.Consumer>
                {({ token }) =>
                    <ProductionLineSelector token={token} open={open} setOpen={setOpenAndForceDrawer} />
                }
            </AuthenticationContext.Consumer>
        </ListItem>
    );
}


interface SideMenuProps {
  onDrawerClose: (event: any) => void;
  open: boolean;
  hide: boolean;
  forceDrawerOpen: (open: boolean) => void;
};

function SideMenu({ onDrawerClose, open, hide, forceDrawerOpen } : SideMenuProps) {
  const classes = useStyles();
  if (hide && !open)
    return <div />;
  return (
    <ClickAwayListener onClickAway={onDrawerClose}>
        <Drawer variant="permanent" className={clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}>
            <div>
                <IconButton onClick={onDrawerClose} size="large">
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <ListItemProductionLineSelector forceDrawerOpen={forceDrawerOpen} />
            <ListItemDateSelector />
            <Divider />
            <List>
                <ListItemLink onClick={onDrawerClose} text='Dashboard' Icon={DashboardIcon} to='/dashboard' />
                <ListItemLink onClick={onDrawerClose} text='Images' Icon={PhotoLibraryIcon} to='/images' />
                <ListItemLink onClick={onDrawerClose} text='Orders' Icon={OrderIcon} to='/orders' />
                <ListItemLink onClick={onDrawerClose} text='Product types' Icon={ProductTypesIcon} to='/productTypes' />
                <ListItemLink onClick={onDrawerClose} text='Events' Icon={EventIcon} to='/events' />
            </List>
            <ListItemLink onClick={onDrawerClose} className={classes.bottomPush} text='Logout' Icon={PhotoLibraryIcon} to='/signout' />
            <Divider />
        </Drawer>
    </ClickAwayListener>
  );
}

export default SideMenu;