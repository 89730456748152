import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ResponsiveContainer, Legend, Tooltip, Area, YAxis, XAxis, CartesianGrid, AreaChart } from 'recharts';
import { AxisDomain, ScaleType } from 'recharts/types/util/types';
import { Props as DotProps } from 'recharts/types/shape/Dot';
import { Formatter as LegendFormatter } from 'recharts/types/component/DefaultLegendContent';
import { Theme } from '@mui/material/styles/createTheme';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { CurveType } from 'recharts/types/shape/Curve';

const useStyles = makeStyles((theme : Theme) => ({
    areaChart: {
        fontFamily: theme.typography.fontFamily,
    },
}));

interface ChartProps<T> {
    //data: Array<T>,
    data: Array<any>,
    xAxisDataKey?: string;
    // Format header of tooltip
    tooltipLabelFormatter?: ((label: any, payload: Payload<ValueType, NameType>[]) => React.ReactNode),
    // Format the tooltip values
    tooltipFormatter?: Function,
    // Format the legend values
    legendFormatter?: LegendFormatter;
    // Format the x axis ticks, and tooltip header, if labelHeaderFormatter is not present
    tickFormatter?: (value: any, index: number) => string;
    //dataKeys = [[{ key: 'value', color: '#8884d8' }]],
    dataKeys: Array<Array<{key: string, color: string, stroke?: string, type?: CurveType }>>;
    disableLegend?: boolean;
    disableTooltip?: boolean;
    disableAnimation?: boolean;
    yAxisDomain?: AxisDomain;
    scale?: Function | ScaleType;
    type?: "number" | "category";
    // AreaDot is not exported from 'recharts/types/cartesian/Area';
    dot?: React.ReactElement<SVGElement> | ((props: any) => React.ReactElement<SVGElement>) | ((props: any) => React.ReactElement<SVGElement>) | DotProps | boolean;
};

function Chart<T>({
    data,
    xAxisDataKey = 'tm',
    tooltipLabelFormatter, // Format header of tooltip
    tooltipFormatter, // Format the tooltip values
    legendFormatter, // Format the legend values
    tickFormatter, // Format the x axis ticks, and tooltip header, if labelHeaderFormatter is not present
    dataKeys = [[{ key: 'value', color: '#8884d8' }]],
    disableLegend,
    disableTooltip,
    disableAnimation,
    yAxisDomain,
    scale = 'time',
    type,
    dot,
}: ChartProps<T>) {
    const classes = useStyles();
    data.sort((a, b) => a[xAxisDataKey] > b[xAxisDataKey] ? 1 : -1);
    return (
        <ResponsiveContainer>
            <AreaChart
                className={classes.areaChart}
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={xAxisDataKey}
                    interval="preserveStartEnd"
                    domain={['auto', 'auto']}
                    scale={scale}
                    type={type}
                    tickFormatter={tickFormatter}
                />

                <YAxis
                    domain={yAxisDomain}
                />
                {
                    dataKeys.map((keys, stackId) =>
                        keys.map(({ key, color, stroke, type = 'monotone' }, idx) =>
                            <Area
                                key={`${stackId}_${idx}`}
                                type={type}
                                dataKey={key}
                                stackId={stackId}
                                stroke={stroke || color}
                                fill={color}
                                isAnimationActive={!disableAnimation}
                                dot={dot}
                                connectNulls
                            />
                        )
                    )
                }
                {!disableLegend &&
                    <Legend
                        formatter={legendFormatter}
                    />
                }
                {!disableTooltip &&
                    <Tooltip
                        formatter={tooltipFormatter}
                        labelFormatter={tooltipLabelFormatter}
                    />
                }
            </AreaChart>
        </ResponsiveContainer>
    );
}


export default Chart;

