import { gql } from '@apollo/client';

const generate = (name, fields, tableName, customQueries = {}, customResponseParsers = {}) => ({
  name,
  tableName: `production_intelligence_${tableName ? tableName : name}`,
  fields,
  customQueries,
  customResponseParsers,
});

const customers = generate('customers', ['id', 'name']);
const productionLines = generate('productionLines', ['id', 'name'], 'production_lines');
const settingsAndFields = 'settings { key, value, type }';
const customerAndProductionLinesFields = [...customers.fields, settingsAndFields, `production_lines { id, name, ${settingsAndFields} }`]
const customerAndProductionLines = generate('customerAndProductionLines', customerAndProductionLinesFields, 'customers');
const events = generate('events', ['id', 'time', 'lane', 'type { name }']);
const orders = generate('orders', ['id', 'name', 'tm_start', 'tm_end', 'production_line { name }']);
const lastHourProduction = generate('lastHourProduction', ['id: line_id', 'last_product', 'first_product', 'total_products_count', 'failed_products_count', 'production_line { name }'], 'v_last_hour_production')

//production_intelligence_mv_hourly_production(where: {bucket: {_eq: "${(new Date(Math.floor(new Date().getTime() / (60*60000)) *60*60000)).toISOString()}"}}) {
const oneDayInMs = 1000 * 60 * 60 * 24;
const getCurrentProduction = () => gql`query getCurrentProduction {
  production_intelligence_v_last_24_hours_production {
    customer_id
    line_id
    total_products_count
    failed_products_count
    first_product
    last_product
  }
  production_intelligence_v_last_hour_production {
    customer_id
    line_id
    total_products_count
    failed_products_count
    first_product
    last_product
  }
  production_intelligence_mv_hourly_production(where: {bucket: {_gte: "${(new Date(Math.floor((new Date().getTime() - oneDayInMs) / (60*60000)) *60*60000)).toISOString()}"}}) {
    customer_id
    line_id
    bucket
    failed_products_count
    first_product
    last_product
    total_products_count
  }
  production_intelligence_orders(order_by: {id: desc}, limit: 1) {
    customer_id
    line_id
    name
    tm_start
  }
}`;
const parseCurrentProduction = response => {
  const {
    production_intelligence_mv_hourly_production: hourlyProduction,
    production_intelligence_orders: orders,
    production_intelligence_v_last_24_hours_production: lastDay,
    production_intelligence_v_last_hour_production: lastHour,
  } = response.data;
  const getId = d => d.line_id;
  const hasSameId = id => d => d.line_id === id;
  const responseData = [hourlyProduction, orders, lastDay, lastHour];
  const ids = responseData.map(d => d.map(getId).filter((d, i, arr) => arr.indexOf(d) === i)).reduce((acc, d) => [...acc, ...d], []).filter((d, i, arr) => arr.indexOf(d) === i);
  console.log({ response, responseData, ids })
  const data = ids.map(id => ({
    id,
    hourlyProduction: hourlyProduction.filter(hasSameId(id)),
    orders: orders.filter(hasSameId(id)),
    lastDay: lastDay.filter(hasSameId(id)),
    lastHour: lastHour.filter(hasSameId(id)),
  }));
  return {
    data,
    total: ids.length
  }
}
const currentProduction = generate('currentProduction', [], 'current_production', { GET_LIST: getCurrentProduction, GET_MANY: getCurrentProduction }, { GET_LIST: parseCurrentProduction, GET_MANY: parseCurrentProduction});

const queryInfos = [
  // regular direct queries
  customers, productionLines, events, orders, lastHourProduction,
  customerAndProductionLines,
  // custom queries
  currentProduction,
];


/*
id
name
tm_start
tm_end
production_line { name }
*/
export default function buildQuery(introspectionResults) {
  return (raFetchType, resourceName, params) => {
    const queryInfo = queryInfos.find(queryInfo => queryInfo.name === resourceName);
    if (!queryInfo)
        throw new Error(`Table "${resourceName}" is not available. Please use one of the following: ${JSON.stringify(queryInfos.map(queryInfo => queryInfo.name))}`);

    const customResponseParser = queryInfo.customResponseParsers[raFetchType];
    const customQueryGenerator = queryInfo.customQueries[raFetchType];
    if (customQueryGenerator){
      const query = customQueryGenerator();
      console.log({ query })
      return {
        query,
        variables: {},
        parseResponse: response => {
          console.log({ response })
          if (customResponseParser)
            return customResponseParser(response);
          return response;
        }
      };
    }
    console.log(`QUERY ${raFetchType} ${resourceName}`)
    const gqlx = gql;
    switch (raFetchType) {
      case 'GET_LIST':
        return {
          query: gqlx`query GET_${resourceName}($limit: Int, $offset: Int, $order_by: [${queryInfo.tableName}_order_by!]) {
          data: ${queryInfo.tableName} (limit: $limit, offset: $offset, order_by: $order_by) {
              ${queryInfo.fields.reduce((acc, d) => `${acc}${acc.length > 0 ? ', ':''}${d}`)}
          },
          aggregateData: ${queryInfo.tableName}_aggregate {
            aggregate {
              totalCount: count
            }
          }
        }`,
          //variables: params, // params = { id: ... }
          variables: {
            limit: params.pagination ? params.pagination.perPage : 100,
            offset: params.pagination ? (params.pagination.page - 1) * params.pagination.perPage : 0,
            //order_by: params.sort ? { [params.sort.field]: params.sort.order === 'DESC' ? 'desc' : 'asc' } : null
            order_by: params.sort ? { production_line: { name: (params.sort.order === 'DESC' ? 'desc' : 'asc') } } : {}
          },
          parseResponse: response => {
            const total = response.data.aggregateData.aggregate.totalCount;
            //const result = { data: response.data.data.map((d, id) => ({ ...d, production_line: d.production_line.name, id })), total };
            const result = { data: response.data.data, total };
            //const result = { data: response.data.data.map((d, id) => ({ ...d, production_line: d.production_line.name, id: d.line_id })), total };
            return result;
          }
        };
      default: return {};
    }
    //case 'GET_ONE':
    //return {
    //query: gql`query ${resource[raFetchType].name}($id: ID) {
    //data: ${resource[raFetchType].name}(id: $id) {
    //}
    //}`,
    //variables: params, // params = { id: ... }
    //parseResponse: response => response.data,
    //}
    //break;
    //// ... other types handled here
    //default: break;
    //}
  };
}
