import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ReportIcon from '@mui/icons-material/Report';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
  fail: {
    backgroundColor: theme.results.fail
  },
  ok: {
    backgroundColor: theme.results.ok
  }
}))

function ResultEntry({ name }) {
  const isOk = name === 'OK';
  const classes = useStyles();
  const rootClass = isOk ? classes.ok : classes.fail;
  const getIcon = () => isOk ? <CheckIcon /> : <ReportIcon />
  return (
    <ListItem classes={{ root: rootClass }}>
      <ListItemIcon>
        {getIcon()}
      </ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );
}

function Results({ results, className, Header }) {
  
  const errors = Object.values(results || {}).map(d => d.Name).filter((d, i, arr) => arr.indexOf(d) === i);
  //const errorsOrOk = errors.length === 0 ? ['OK'] : errors;

  return (
    <List
      className={className}
      subheader={<Header>Results</Header>}
    >
      {errors.map(d => <ResultEntry key={d} name={d} />)}
    </List>
  );
}

export default Results;