import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles/createTheme';
import React from 'react';

type ThemeAddons = {
    headerColor: string;
    fontFamily: string;
    results: {
        [key : string]: string;
    }
};

const useStyles = makeStyles((theme : Theme & ThemeAddons) => ({
    header: {
        fontFamily: theme.fontFamily,
        color: theme.headerColor,
        fontWeight: 500,
        fontSize: 'min(10vw, 2em)'
    }
}));

interface HeaderProps {
    children: JSX.Element | string;
}
const Header = ({ children } : HeaderProps) => {
    const classes = useStyles();
    return (
        <span className={classes.header}>
            {children}
        </span>
    );
};

interface DashboardRowProps {
    children: JSX.Element;
}
const DashboardRow = ({ children } : DashboardRowProps) =>
    <Grid container spacing={0} direction="row" justifyContent="space-around" alignItems="center" style={{ minHeight: 'min(100vw,24vh)', width: '100%' }}>
        {React.Children.toArray(children)}
    </Grid>;

export {
    DashboardRow,
    Header,
};