import React, { useCallback } from 'react';
import Overlays from './Overlays';
import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import useImage from 'use-image';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';

const scaleBy = 1.01;

function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1, p2) {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
}

function isTouchEnabled() {
    return ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0);
}


export function Image({ imagePath, originalImageSize, overlaysEnabled, overlays, overlayOpacity, /*imageScale = 1.0, setImageScale,*/ width, showDebugOverlay }) {
    const [imageScale, setImageScale] = useState(1.0);
    const stageRef = useRef();
    const [stageSize, setStageSize] = useState({ width: 10, height: 10 });
    const [image] = useImage(imagePath);
    if (image && originalImageSize){
        const { width, height } = originalImageSize;
        if (width && image.width !== width)
            image.width = width;
        if (height && image.height !== height)
            image.height = height;
    }
    let lastCenter = null;
    let lastDist = 0;

    const getStageScale = () => stageRef?.current?.scaleX() || 1.0;
    const minVisibleSize = 100;
    const limitPosition = (d, min, max) => Math.max(-getStageScale() * min + minVisibleSize, Math.min(max - minVisibleSize, d));
    const setStagePosition = (x, y) => stageRef?.current?.position({ x: limitPosition(x, image?.width, width), y: limitPosition(y, image?.height, stageRef?.current?.height()) });
    const getStagePosition = () => stageRef?.current?.position() || { x: 0, y: 0 };
    const getStagePointerPosition = () => stageRef?.current?.getPointerPosition() || { x: 0, y: 0 };
    const stageBatchDraw = useCallback(() => stageRef?.current?.batchDraw(), []);

    useEffect(() => {
        if (image) {
            const scaleX = width / image.width;
            const scale = Math.max(Math.min(1.0, scaleX), 0.1);
            setImageScale(scale);
            const imageRatio = image.height / image.width;
            setStageSize({ width, height: width * imageRatio });
            if (stageRef.current !== null) {
                const stage = stageRef.current;
                stage.scale({ x: scale, y: scale });
                const newPos = { x: 0, y: 0, }
                stage.position(newPos);
                stageBatchDraw();
            }
        }
        return () => { }
    }, [imagePath, image, width, setImageScale, stageBatchDraw]);

    const setStageScale = useCallback(
        (scale) => {
            stageRef?.current?.scale({ x: scale, y: scale });
            setImageScale(scale);
        },
        [setImageScale],
    );

    useEffect(() => {
        setStageScale(imageScale);
        stageBatchDraw();
        return () => { }
    }, [imageScale, setStageScale, stageBatchDraw]);

    const zoomStage = e => {
        e.evt.preventDefault();
        if (stageRef.current === null)
            return;
        const oldScale = getStageScale();
        const pointerPos = getStagePointerPosition();
        const position = getStagePosition();
        const mousePointTo = { x: (pointerPos.x - position.x) / oldScale, y: (pointerPos.y - position.y) / oldScale };
        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
        setStageScale(newScale);
        setStagePosition(pointerPos.x - mousePointTo.x * newScale, pointerPos.y - mousePointTo.y * newScale)
        stageBatchDraw();
    }

    const handleTouch = e => {
        const [touch1, touch2] = e.evt.touches;
        const stage = stageRef.current;
        if (stage === null || !touch1 || !touch2)
            return;
        e.evt.preventDefault();

        if (stage.isDragging())
            stage.stopDrag();

        const p1 = { x: touch1.clientX, y: touch1.clientY };
        const p2 = { x: touch2.clientX, y: touch2.clientY };

        if (!lastCenter)
            return lastCenter = getCenter(p1, p2);

        const newCenter = getCenter(p1, p2);
        const dist = getDistance(p1, p2);

        if (!lastDist)
            lastDist = dist;

        const oldScale = getStageScale();
        //const pointerPos = getStagePointerPosition();
        const position = getStagePosition();
        // local coordinates of center point
        const pointTo = { x: (newCenter.x - position.x) / oldScale, y: (newCenter.y - position.y) / oldScale };
        const scale = oldScale * (dist / lastDist);
        setStageScale(scale);

        // calculate new position of the stage
        const dx = newCenter.x - lastCenter.x;
        const dy = newCenter.y - lastCenter.y;

        setStagePosition(newCenter.x - pointTo.x * scale + dx, newCenter.y - pointTo.y * scale + dy);
        stageBatchDraw();

        lastDist = dist;
        lastCenter = newCenter;
    }

    const handleTouchEnd = () => {
        lastCenter = null;
        lastDist = 0;
    };

    const imageSize = { width: image?.width, height: image?.height };
    return (
        <Stage width={stageSize.width} height={stageSize.height}
            draggable={!isTouchEnabled()}
            onWheel={zoomStage}
            onTouchMove={handleTouch}
            onTouchEnd={handleTouchEnd}
            ref={stageRef}
        >
            <Layer>
                <KonvaImage image={image} />
                <Overlays overlays={overlays} overlaysEnabled={overlaysEnabled} overlayOpacity={overlayOpacity} showDebugOverlay={showDebugOverlay} imageScale={imageScale * 0.5} imageSize={imageSize} />
            </Layer>
        </Stage>
    );
}


function useWindowSize() {
    const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined, });
    useEffect(() => {
        const handleResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight, });
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

export function FullWidthImage({ padding = 24, ...props }) {
    const targetRef = useRef();
    const size = useWindowSize();
    const [dimensions, setDimensions] = useState({ width: 10, height: 10 });
    useLayoutEffect(() => {
        if (targetRef.current)
            setDimensions({ width: targetRef.current.offsetWidth, height: targetRef.current.offsetHeight });
    }, [size]);
    return (
        <div ref={targetRef} style={{ padding }}>
            <Image width={dimensions.width - 2 * padding} {...props} />
        </div>
    )
}


