import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState  } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from '../../authentication/authentication';
import inMemoryJWT from '../../authentication/inMemoryJwt';
import { Button, Input } from '../common';
import Typography from '@mui/material/Typography';
import { localStorageUsernameKey, localStoragePasswordKey } from '../../constants';

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: '330px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        marginTop: '2em',
        '& > *': {
            margin: '1em 0',
        }
    },
    container: {
        [theme.breakpoints.down('md')]: {
            margin: '0.5em',
        },
        [theme.breakpoints.up('sm')]: {
            margin: '2em auto',
        },
        padding: '2em',
        maxWidth: 'min(600px, 90vw)',
    }
}))

function load(){
    const username = window.localStorage.getItem(localStorageUsernameKey);
    const password = window.localStorage.getItem(localStoragePasswordKey);
    //console.log(`Loaded ${username} // ${password}`);
    return { username, password };
}

function save(username, password){
    console.log(`Saving ${username} // ${password}`);
    window.localStorage.setItem(localStorageUsernameKey, username);
    window.localStorage.setItem(localStoragePasswordKey, password);
}

function SignIn({ isAuthenticated, setToken }) {
    const classNames = useStyles();
    const history = useHistory();
    const [usernameInputValue, setUsernameInputValue] = useState('');
    const [passwordInputValue, setPasswordInputValue] = useState('');

    const handleAuthenticate = async (username, password) => {
        const response = await login(username, password);
        if (response) {
            save(username, password);
            inMemoryJWT.setToken(response);
            const tokenInfo = inMemoryJWT.getToken();
            setToken(tokenInfo);
            history.push('/dashboard');
        }
    };

    useEffect(() => {
        const { username, password } = load();
        if (!username || !password)
            return;
        handleAuthenticate(username, password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Paper className={classNames.container}>
            <Typography variant="h3">Sign in</Typography>
            <form className={classNames.form}>
                <Input labelText='username' type='text' value={usernameInputValue} onChange={e => setUsernameInputValue(e.target.value)} />
                <Input labelText='password' type='password' value={passwordInputValue} onChange={e => setPasswordInputValue(e.target.value)} />
                <Button variant='contained' color='primary' onClick={() => handleAuthenticate(usernameInputValue, passwordInputValue)}>Log in</Button>
            </form>
        </Paper>
    )
}

export default SignIn;
