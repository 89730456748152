import { gql } from '@apollo/client';

export const GET_PRODUCT_ERRORS = gql`
    query GetProductErrors(
        $customer_id: Int! = 7,
        $line_id: Int! = 32,
        $limit: Int! = 10000,
        $ignored_result_type_ids: [Int!],
        $tm_start: timestamptz,
        $tm_end: timestamptz,
        $offset: Int
    ) {
        productErrors: production_intelligence_product_errors (
            where: {
                customer_id: {_eq: $customer_id},
                line_id: {_eq: $line_id},
                time: {_gte: $tm_start, _lte: $tm_end}
                result_type_id: { _nin: $ignored_result_type_ids }
            },
            limit: $limit,
            offset: $offset
        ){
            lane
            time
            result_type_id
            type{
            name
            }
        }
    }
`
