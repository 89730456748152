import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles/createTheme';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DecodedToken } from '../../authentication/authentication'

// Apartment / Business = customer/plant
// Straighten / Theaters / ViewStream = Production line

const useStyles = makeStyles((theme : Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface ProductionLineSelectorProps {
    token?: DecodedToken;
    open: boolean;
    setOpen: (open: boolean) => void;
};

type ProductionLine = {
    id: string;
    name: string;
};

export default function ProductionLineSelector({ token, open, setOpen } : ProductionLineSelectorProps) {
    const customers  = token?.customerInfo || [];
    const productionLines = customers.reduce((acc, customerInfo) => [...acc, ...Object.values(customerInfo.productionLines)], []);
    const productionLine = productionLines[0];
    const [value, setValue] = useState<string>(productionLine?.id);
    const classes = useStyles();
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const handleChange = (e: SelectChangeEvent<string>) => setValue(e.target.value);
    
    //useEffect(() => {
    //    const p = customers.find(c => Object.values(c.productionLines).find(p => p));
    //}, [token]);

    //...([...Object.values(customer.productionLines), {id:2, name:'abc'}].map(productionLine =>
    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">Line</InputLabel>
            <Select
                id="grouped-select"
                onChange={handleChange}
                value={value}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                style={{ top: '0.4em', height: '2.5em' }}
            >
                {customers.map(customer =>
                    [
                        <ListSubheader>{customer.name}</ListSubheader>,
                        ...(Object.values(customer.productionLines).map((productionLine: any)  => {
                            if (productionLine instanceof Object)
                                return <MenuItem dense={false} key={productionLine?.id} value={productionLine.id}>{productionLine.name}</MenuItem>
                            else
                                return <></>;
                        }
                        ))
                    ]
                )}
            </Select>
        </FormControl>
    );
}