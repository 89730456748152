import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator2<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function getComparator(order: any, orderBy: any) {
    return order === 'desc'
        ? function (a: any, b: any) { return descendingComparator(a, b, orderBy); }
        : function (a: any, b: any) { return -descendingComparator(a, b, orderBy); };
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
}

//const headCellsx: readonly HeadCell[] = [
//  { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)', },
//  { id: 'calories', numeric: true, disablePadding: false, label: 'Calories', },
//  { id: 'fat', numeric: true, disablePadding: false, label: 'Fat (g)', },
//  { id: 'carbs', numeric: true, disablePadding: false, label: 'Carbs (g)', },
//  { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)', },
//];

interface EnhancedTableProps<T> {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof T;
  rowCount: number;
  multiSelect: boolean;
  headCells: readonly HeadCell<T>[];
  getIdentifier: (d: T) => Identifier;
}

function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, multiSelect, headCells, getIdentifier } =
    props;
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {multiSelect &&
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        }
        {headCells.map((headCell, idx) => (
          <TableCell
            key={/*headCell.id*/ idx}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type Identifier = string | number;
interface TableProps<T> {
  rows: readonly T[];
  getIdentifier: (row: T) => Identifier;
  onClick?: (row: T[]) => void;
  multiSelect?: boolean;
  headCells: readonly HeadCell<T>[];
};

export default function EnhancedTable<T>({ rows, getIdentifier, multiSelect = false, headCells, onClick } : TableProps<T>) {
  const [order, setOrder] = React.useState<Order>('asc');
  const firstKey = headCells[0].id;
  //const [orderBy, setOrderBy] = React.useState<keyof T>(firstKey);
  const [orderBy, setOrderBy] = React.useState<keyof T>(firstKey);
  const [selected, setSelected] = React.useState<readonly Identifier[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!multiSelect)
      return;

    let newSelected: readonly Identifier[] = [];
    if (event.target.checked) 
      newSelected = rows.map((n) => getIdentifier(n));
    const selectedRows = rows.filter(row => newSelected.includes(getIdentifier(row)));
    onClick?.(selectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, identifier: Identifier) => {
    const selectedIndex = selected.indexOf(identifier);

    let newSelected: readonly Identifier[] = [];

    if (!multiSelect){
      newSelected = ~selectedIndex ? [] : [identifier];
    } else if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, identifier);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedRows = rows.filter(row => newSelected.includes(getIdentifier(row)));
    onClick?.(selectedRows);
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: Identifier) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              multiSelect={multiSelect}
              headCells={headCells}
              getIdentifier={getIdentifier}
            />
            <TableBody>
              {[...rows].sort(getComparator(order, orderBy))
                //stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(getIdentifier(row));
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, getIdentifier(row))}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={getIdentifier(row)}
                      selected={isItemSelected}
                    >
                      {multiSelect &&
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      }
                      {headCells.map(({ id, numeric } , idx) => {
                        const content = row[id];
                        const key : string | number = typeof id === 'symbol' ? idx : id;
                        if (idx === 0)
                          return <TableCell key={key} component="th" id={labelId} scope="row" padding="none" > {(typeof content === 'number') ? content.toLocaleString() : content} </TableCell>;
                        return <TableCell key={key} align="right">{(typeof content === 'number') ? content.toLocaleString() : content}</TableCell>
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
