import * as React from 'react';
import { Grid, Typography } from "@mui/material";
import withTheme from '@mui/styles/withTheme';
import styled from "styled-components";
import { classifications as availableClassifications} from "../../../constants"
import PieChart from '../../charts/PieChart';
import { Header } from '../Dashboard/containers';

interface ClassificationResult {
        name: string;
        count: number;
}
export interface ClassificationType {
    classification: string;
    results: Array<ClassificationResult>;
    count: number;
};

const classificationsMap = Object.values(availableClassifications).reduce((acc, d) => ({ ...acc, [d.tag]: d.displayName }), {});
const getDisplayname = (classificationTag : string) => classificationsMap[classificationTag] || classificationTag;

const getColor = (baseColor : string, opacityPct : number, colorOverride?: string) => {
    if (colorOverride)
        return colorOverride;
    const offset = 64;
    const opacity = Math.ceil(offset + (255 - offset) * (1 - opacityPct)).toString(16);
    return `${baseColor}${('0' + opacity).slice(-2)}`;
}

const ChartContainer = styled.div`
    width: min(100vw, 95%);
    height: min(100vw, 20vh);
    margin-bottom: 2em;
`;

interface QualityChartContainerProps {
    name: string;
    baseColor: string;
    classifications: Array<ClassificationType>;
    totalCount: number;
};

function QualityChartContainer({ name, baseColor, classifications, totalCount } : QualityChartContainerProps){
    const countsAsObject = classifications
        .reduce((acc : Array<ClassificationResult>, d) => [...acc, ...d.results] , [])
        .reduce((acc : {[key: string]: number}, d) => {
            const old = acc[d.name] || 0;
            const updated = old + d.count;
            return { ...acc, [d.name]: updated };
        }, {})
    const countsAsArray = Object.entries(countsAsObject)
        .filter(([, count]) => count > 0)
        .map(([name, count], i, arr) => {
            return {
                name,
                count,
                color: getColor(baseColor, i / arr.length)
            }
        });
    const pieChartData = countsAsArray;

    const classificationCount = classifications.reduce((s : number, d) => s + d.count, 0);
    const classificationPct = classificationCount / totalCount;

    return (
        <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <Header>{name}</Header>
            <ChartContainer>
                {classificationCount === 0 &&
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100%' }}
                    >
                        <Typography fontSize='3em' style={{ color: 'lightgrey'}}>0%</Typography>
                        <Grid item xs={3}>
                        </Grid>
                    </Grid>
                }
                {classificationCount > 0 &&
                    <PieChart
                        data={pieChartData}
                        dataKey='count'
                        defaultText={`${parseFloat((classificationPct * 100).toFixed(1)).toLocaleString()}%`}
                    />
                }
            </ChartContainer>
        </Grid>
    );
}

interface QualityProps {
    theme: any;
    classifications: Array<ClassificationType>;
};
function Quality({ theme, classifications } : QualityProps){
    // Sort, or keep order from colors.result?
    const allClassifications = Object.values(availableClassifications)
        .filter(d => !d.hidden)
        .map(d => d.tag);
    const notOkClassifications = allClassifications.filter(name => name !== 'ok');
    const groupedNotOkClassifications = notOkClassifications
        .map(name => ({
            name: getDisplayname(name),
            baseColor: theme.results[name],
            classifications: classifications.filter((d : any) => d.classification === name)
        }));
    
    const overallClassifications = allClassifications.map(classification => ({
        name: getDisplayname(classification),
        classification: classification,
        color: theme.results[classification],
        count: classifications.filter((d : any) => d.classification === classification).reduce((s : number, d : any) => s + d.count, 0)
    }));

    const totalCount = overallClassifications.reduce((s, d) => s + d.count, 0);
    const okCount = overallClassifications.find(d => d.classification === 'ok')?.count || 0;
    const okPct = okCount / totalCount;

    return (
        <Grid container spacing={0} direction="row" justifyContent="space-around" alignItems="flex-start" style={{ minHeight: 'min(100vw,24vh)', width: '100%', marginTop: '2em', marginBottom: '2em', }}>
            <Grid item xs={12} sm={6} lg={2}>
            <Header>Classification</Header>
                <ChartContainer>
                    <PieChart
                        data={overallClassifications}
                        dataKey='count'
                        defaultText={`${parseFloat((okPct * 100).toFixed(1)).toLocaleString()}%`}
                    />
                </ChartContainer>
            </Grid>
            { groupedNotOkClassifications.map((d, idx) => <QualityChartContainer key={idx} totalCount={totalCount} {...d} />) }
        </Grid>
    )
}

export default withTheme(Quality);