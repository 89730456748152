import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// Assessment, DonutLarge, Equalizer, Timeline, ShowChart, TrendingDown/TrendingUp, MultilineChart, TableChart
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import { TitleContext } from '../../contexts';
import { drawerWidth } from '../../constants';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  logo: {
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      // 2em/-6em or 3em/-9em
      height: '3em',
      right: '-9em'
    },
    [theme.breakpoints.up('sm')]: {
      height: '3em',
      right: '1em'
    },
  },
}));

function TopBar({ isAuthenticated, onDrawerOpen, open }) {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
      <Toolbar>
        {isAuthenticated &&
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={onDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, { [classes.hide]: open })}
          size="large">
          <MenuIcon />
        </IconButton>
        }
        <TitleContext.Consumer>
          {({ pageTitle }) =>
            <Typography variant="h6" noWrap>
              {pageTitle}
            </Typography>
          }
        </TitleContext.Consumer>
        <img
          srcx="https://trivision.dk/wp-content/themes/conflict/lib/assets/logo/logo-white.svg"
          src="https://trivision.dk/wp-content/themes/trivision-wp-theme-2021/assets/img/logo.svg"
          alt="Logo"
          className={classes.logo}
        />
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;