import { Label, Tag, Text, Circle, Line, } from 'react-konva';
import { colors, overlayTypes, } from './constants';

function getDefaultStrokeWidthInCssUnits() { return `10px`; }

function convertToCssUnits(d, imageScale) {
    if (typeof d === 'string') {
        // i.e. 10.000000devs -> 5.000000em, no image scaling
        if (d.endsWith("devs"))
            return `${parseFloat(d.slice(0, -4)) / 2.0}em`;
        // 5.000000 -> 5.000000
        const f = parseFloat(d);
        if (Number.isFinite(f) && !Number.isNaN(f))
            return `${f * imageScale * 5}px`;
    }
    else if (Number.isFinite(parseFloat(d)) && !Number.isNaN(parseFloat(d))) {
        return `${d / imageScale * 5}px`;
    }
    throw Error(`Unable to parse "${d}" as a CSS unit.`);
}

function convertCssToPixelUnit(d, imageScale) {
    if (typeof d === 'string') {
        // Relative to html/root font size
        if (d.endsWith("rem"))
            return parseFloat(d.slice(0, -3)) * 12;
        // Relative to parent font size
        if (d.endsWith("em"))
            return parseFloat(d.slice(0, -2)) * 12;
        if (d.endsWith("px"))
            return parseFloat(d.slice(0, -2));
    }
    else if (Number.isFinite(parseFloat(d)) && !Number.isNaN(parseFloat(d)))
        return parseFloat(d) / imageScale;
    throw Error(`Unable to parse "${d}" as a CSS unit.`);
}
function convertToPixelUnit(d, imageScale) {
    const valueInCssUnits = convertToCssUnits(d, imageScale);
    return convertCssToPixelUnit(valueInCssUnits, imageScale);
}

function mapFromString(mapAsString) {
    const mapWithMissingCommas = mapAsString.replaceAll('][', '],[');
    const mapAsArray = JSON.parse(mapWithMissingCommas);
    return mapAsArray;
}

function matrixFromMap(mapAsString) {
    const arr = mapFromString(mapAsString);
    if (arr.length !== 3)
        throw Error(`Matrix must be 3x3 => The map should contain at three sub arrays`, arr);
    if (arr.some(d => d.length !== 3))
        throw Error(`Matrix must be 3x3 => All rows must contain three entries.`, arr);
    //const transpose = m => m[0].map((x,i) => m.map(x => x[i]));
    //const transposed = transpose(arr);
    return arr;
}

const getOffsetX = matrix => matrix[0][2] / matrix[2][2];
const getOffsetY = matrix => matrix[1][2] / matrix[2][2];
const getOffset = matrix => ({ x: getOffsetX(matrix), y: getOffsetY(matrix) });

// Rotation of unit vector (1, 0) by transform
const getAngleXRad = matrix => Math.atan2(matrix[1][0], matrix[0][0]);
// Rotation of unit vector (0, 1) by transform
//const getAngleYRad = matrix => Math.atan2(-matrix[0][1], matrix[1][1]);
const getAngleRad = getAngleXRad;

const getVectorSquaredLength = ({ x, y }) => Math.sqrt(x * x + y * y);
// Scale of unit vector (0, 1) by transform (= GetPreScale().X().PixPerMm())
const getScaleX = matrix => getVectorSquaredLength({ x: matrix[0][0], y: matrix[1][0] }) / matrix[2][2];
// Scale of unit vector (1, 0) by transform (= GetPreScale().Y().PixPerMm())
const getScaleY = matrix => getVectorSquaredLength({ x: matrix[0][1], y: matrix[1][1] }) / matrix[2][2];
const getScale = matrix => ({ x: getScaleX(matrix), y: getScaleY(matrix) });

// 11.226 11.925 1
function parseMatrix(mapAsString = '[[1,0,0][0,1,0][0,0,1]]') {
    const matrix = matrixFromMap(mapAsString);
    // Hack to compensate for 4k -> 2k image scaling in reduced images
    const useReducedImageSize = false;
    if (useReducedImageSize) {
        matrix[0][0] = matrix[0][0] / 2.0;
        matrix[0][1] = matrix[0][1] / 2.0;
        matrix[1][0] = matrix[1][0] / 2.0;
        matrix[1][1] = matrix[1][1] / 2.0;
    }
    const offset = getOffset(matrix);
    const angleRad = getAngleRad(matrix);
    const scale = getScale(matrix);
    return { offset, angleRad, scale, matrix };
}
const mapPoint = (matrix, pt) => {
    const d = matrix[2][0] * pt.x + matrix[2][1] * pt.y + matrix[2][2];
    return {
        x: (matrix[0][0] * pt.x + matrix[0][1] * pt.y + matrix[0][2]) / d,
        y: (matrix[1][0] * pt.x + matrix[1][1] * pt.y + matrix[1][2]) / d,
    };
};

// https://github.com/Fionoble/transformation-matrix-js/blob/fc38603c6ae43e1fde72e14bb8e22420c574eea3/src/matrix.js#L438

function rectFromString(rect) {
    const [left, top, right, bottom] = rect.slice(1, -1).split(',').map(parseFloat);
    const [x, y] = [left, top];
    const width = Math.abs(right - left);
    const height = Math.abs(bottom - top);
    return { left, top, right, bottom, x, y, width, height };
}

function getStroke({ penColor, penWidth, usePenWidth, overlayOpacity, imageScale }) {
    const strokeReduction = 3.0;
    const strokeWidth = usePenWidth ? convertToPixelUnit(penWidth, imageScale) / strokeReduction : convertCssToPixelUnit(getDefaultStrokeWidthInCssUnits(), imageScale);
    //const stroke = `#${parseInt(penColor).toString(16).slice(2, 8)}`;
    const hexToRgba = hex => {
        var bigint = parseInt(hex, 16);
        var a = (bigint >> 24) & 255;
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;

        return `rgba(${r},${g},${b},${a / (255.0 / overlayOpacity)})`;
    };

    const strokeRgba = hexToRgba(parseInt(penColor).toString(16));
    return { stroke: strokeRgba, strokeRgba, strokeWidth };
}

function OverlayEntryRect({ rect, stroke, strokeWidth, matrix, imageSize, getNextTextOffset, overlayOpacity, imageScale }) {
    const { left, top, right, bottom } = rectFromString(rect);

    const points = [{ x: left, y: top }, { x: right, y: top }, { x: right, y: bottom }, { x: left, y: bottom },];
    const pointsList = points.map(mapPoint.bind(null, matrix));
    let errorText = null;
    for (let { x, y } of pointsList) {
        if (y < 0 || y > imageSize.height)
            console.error(`y coordinat ${y} is outside image (0..${imageSize.height})`);
        if (x < 0 || x > imageSize.width)
            console.error(`x coordinat ${x} is outside image (0..${imageSize.width})`);
        errorText = `Overlay is drawn outside image`;
    }
    const pointsListFlat = pointsList.reduce((acc, { x, y }) => [...acc, x, y], []);
    const showErrorTextOverlay = false;

    return (
        <>
            {showErrorTextOverlay && errorText && <OverlayEntryText format={0} text={errorText} type={'Warn'} getNextTextOffset={getNextTextOffset} overlayOpacity={overlayOpacity } imageScale={imageScale} /> }
            <Line points={pointsListFlat} closed={true} stroke={stroke} strokeWidth={strokeWidth} />
        </>
    );


    // TODO: Why does below not work?
    // const { offset, angleRad, scale  } = parseMatrix(map);
    //return <Rect
    //x={rc.x}
    //y={rc.y}
    //width={rc.width}
    //height={rc.height}
    //stroke={stroke}
    //strokeWidth={strokeWidth}
    //offset={offset}
    //scale={scale}
    //rotation={angleRad * 180 / Math.PI}
    ///>
}

function OverlayEntryCircle({ point, radius, stroke, strokeWidth, imageScale, matrix }) {
    const r = convertToPixelUnit(radius, imageScale);
    const p = parsePoint(point);
    const center = mapPoint(matrix, p);

    // TODO: use Transformer? https://konvajs.org/api/Konva.Transform.html
    return <Circle
        radius={r}
        x={center.x}
        y={center.y}
        stroke={stroke}
        strokeWidth={strokeWidth}
    />;
}

// "(12.345678, 23.456789)" -> { x: 12.345678, y: 23.456789 }
const parsePoint = pointAsString => {
    const [x, y] = pointAsString.slice(1, pointAsString.length - 2).split(',').map(parseFloat);
    return { x, y };
}


function OverlayEntryCross({ point, size, stroke, strokeWidth, matrix, imageScale, ...rest }) {

    const { x, y } = parsePoint(point);
    const sizeInPixels = convertToPixelUnit(size, imageScale);
    const halfWidth = sizeInPixels / 2.0;

    const cross = [
        [{ x: x - halfWidth, y: y - halfWidth }, { x: x + halfWidth, y: y + halfWidth },],
        [{ x: x - halfWidth, y: y + halfWidth }, { x: x + halfWidth, y: y - halfWidth },],
    ];
    return cross.map((points, i) => {
        const [a, b] = points.map(mapPoint.bind(null, matrix));
        return <Line key={i} points={[a.x, a.y, b.x, b.y]} stroke={stroke} strokeWidth={strokeWidth} />;
    });


}

function OverlayEntryCurve({ isClosed, tension, points,  stroke, strokeWidth, matrix }) {
    const mappedPointsList = points.map(mapPoint.bind(null, matrix)).reduce((acc, { x, y }) => [...acc, x, y], []);
    return <Line points={mappedPointsList} stroke={stroke} strokeWidth={strokeWidth} closed={isClosed ?? true} tension={parseFloat(tension)} />;
}

function OverlayEntryLine({ startPoint, endPoint,  stroke, strokeWidth, matrix }) {
    const points = [startPoint, endPoint]
        .map(parsePoint)
        .map(mapPoint.bind(null, matrix))
        .reduce((acc, { x, y }) => [...acc, x, y], [])
        ;
    return <Line points={points} stroke={stroke} strokeWidth={strokeWidth} />;
}

function OverlayEntryText({ format, text, type, overlayOpacity, imageScale, getNextTextOffset }) {
    //const fontSize = imageScale * imageScale * 300;
    const fontSize = 12 / imageScale;
    const offsetY = getNextTextOffset() * - fontSize;
    const getColor = (type) => {
        const overlayType = overlayTypes[type] || { color: colors.white };
        return overlayType.color;
    };
    const color = getColor(type);
    return (
        <>
            <Label>
                <Tag fill={colors.black} offsetY={offsetY} opacity={overlayOpacity * 0.5} />
                <Text text={text} offsetY={offsetY} fontSize={fontSize} fill={color} opacity={overlayOpacity} />
            </Label>
        </>
    );
}

function OverlayRunlengthImage({ runLengthString, offset, scale, color, /*stroke, strokeWidth,*/ ...props}){
    const magicExpected = 'TVRUNLENGTHIMAGE';
    if (runLengthString.length <= magicExpected.length){
        console.log(`runlength string too short. Minimum length ${magicExpected.length}, but found ${runLengthString.length}`);
        return <></>;
    }
    const delimiter = runLengthString[magicExpected.length];
    // eslint-disable-next-line no-unused-vars
    const [magicFound, _widthAndHeight, ...runlength] = runLengthString.split(delimiter);
    if (magicExpected !== magicFound) {
        console.log(`Wrong magic found! Expected "${magicExpected}" but found "${magicFound}"`);
        return <></>;
    }
    const parsedData = runlength.reduce((acc, d, rowRelative) => {
        const row = scale.y * rowRelative + offset.y;
        // row format: [[x11,x12],[x21,x22],x31,x32],...]
        const rowContent = d.slice(1, -1);
        if (rowContent.length === 0)
            return acc;
        const cols = rowContent.split(']')
            .filter(Boolean).map(d => {
                const [x1, x2] = d.slice(1).split(',');
                return { x1: scale.x * parseInt(x1) + offset.x, x2: scale.x * parseInt(x2) + offset.x };
            })
        return [...acc, { row, cols }];
    }, []);
    //const toRectString = (row, x1, x2) => `(${x1},${row},${x2},${row+1})`;

                    //<OverlayEntryRect key={`${rowIndex}_${colIndex}`} rect={toRectString(row, x1, x2)} />
    const toColor = num => {
        num >>>= 0;
        var b = num & 0xFF,
            g = (num & 0xFF00) >>> 8,
            r = (num & 0xFF0000) >>> 16,
            a = ((num & 0xFF000000) >>> 24) / 255;
        return "rgba(" + [r, g, b, a].join(",") + ")";
    }
    const stroke = toColor(color);
    const strokeWidth = Math.max(scale.x, scale.y);
    return (
        <>
            {parsedData.map(({row, cols}, rowIndex) =>
                cols.map(({ x1, x2 }, colIndex) =>
                    <Line key={`${rowIndex}_${colIndex}`} points={[x1, row, x2, row]} stroke={stroke} strokeWidth={strokeWidth} />
                )
            )}   
        </>
    )
}

function OverlayEntry({ DrawableType: drawableType, overlayOpacity, imageScale, imageSize, getNextTextOffset, ...props }) {
    const getPoints = props => {
        const pointPrefix = 'Point';
        const prefixLength = pointPrefix.length;
        const points = Object.entries(props)
            .filter(([k, v]) => k.startsWith(pointPrefix))
            .sort((a, b) => parseInt(a[0].slice(prefixLength)) < parseInt(b[0].slice(prefixLength)) ? -1 : 1)
            .map(([k, v]) => parsePoint(v))
            ;
        if (points.length !== parseInt(props.NumPoints))
            console.error(`Warning: Unexpected number of points found. Found ${points.length} !== ${props.NumPoints}`, props, points);
        return points;
    }; 

    const usePenWidth = props.UsePenWidth ?? props.UseWidth ?? props.PenWidth ?? false;
    const { stroke, strokeWidth } = getStroke({ penColor: props.PenColor, penWidth: props.PenWidth, usePenWidth, overlayOpacity, imageScale });
    const { matrix, offset, scale } = parseMatrix(props.Map);
    const commonProps = {
        overlayOpacity,
        imageScale,
        imageSize,
        stroke,
        strokeWidth,
        matrix,
    };

    //console.log({ drawableType, offset, props })
    
    switch (drawableType) {
        case 'Rect': return <OverlayEntryRect rect={props.Rect} getNextTextOffset={getNextTextOffset} {...commonProps} />;
        case 'Circle': return <OverlayEntryCircle point={props.Point} radius={props.Radius} {...commonProps} />;
        case 'Cross': return <OverlayEntryCross point={props.Point} size={props.Size} {...commonProps} />;
        case 'Curve': return <OverlayEntryCurve isClosed={props.IsClosed} numPoints={props.NumPoints} tension={props.Tension} points={getPoints(props)} {...commonProps} />;
        case 'Line': return <OverlayEntryLine startPoint={props.StartPoint} endPoint={props.EndPoint} {...commonProps} />;
        case 'TextDebug': return <OverlayEntryText format={props.Format} text={props.Text} type={props.Type} getNextTextOffset={getNextTextOffset} {...commonProps} />;
        // TODO: Add text with correct position
        //case 'Text': return <OverlayEntryText format={props.Format} text={props.Text} type={props.Type} getNextTextOffset={getNextTextOffset} {...commonProps} />;
        case 'Text': return <></>;
        case 'Arrow': return <></>;
        case 'RunLengthImage': return <OverlayRunlengthImage runLengthString={props.RunLengthString} color={parseInt(props.Color)} offset={offset} scale={scale} {...commonProps} />
        default: console.error(`Unknown drawable type ${drawableType}!`);
    }
}

function Overlay({ name, overlay, overlayDebug, overlayOpacity, imageScale, imageSize, showDebugOverlay, getNextTextOffset }) {
    if (!overlay)
        return [];
    const overlayEntries = Object.entries(overlay).map(([k, v]) => <OverlayEntry key={`O${k}`} overlayOpacity={overlayOpacity} imageScale={imageScale} imageSize={imageSize} getNextTextOffset={getNextTextOffset} {...v} />);
    if (!showDebugOverlay)
        return overlayEntries;
    const overlayDebugEntries = Object.entries(overlayDebug).map(([k, v]) => <OverlayEntry key={`D${k}`} overlayOpacity={overlayOpacity} imageScale={imageScale} imageSize={imageSize} getNextTextOffset={getNextTextOffset} {...v} />);
    return [...overlayEntries, ...overlayDebugEntries];
}

function Overlays({ overlays, overlaysEnabled, overlayOpacity, showDebugOverlay , imageScale, imageSize }) {
    let textOverlayOffset = 0;
    const getNextTextOffset = () => textOverlayOffset++;
    if (!overlays)
        return <Text text="No overlays" />;
    return Object.entries(overlays).filter(([k, v]) => overlaysEnabled.includes(v.Name)).map(([k, v]) =>
        <Overlay
            key={k}
            name={v.Name}
            overlay={v.Overlay}
            overlayDebug={v.OverlayDebug}
            showDebugOverlay={showDebugOverlay}
            overlayOpacity={overlayOpacity}
            imageScale={imageScale}
            imageSize={imageSize}
            getNextTextOffset={getNextTextOffset}
        />
    ).filter(d => !!d);
}

export default Overlays;