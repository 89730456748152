const colors = {
    lightBlue: '#b8e1f2', // '#4EC3CF' 
    blue: '#249ba7',
    green: '#abd25f',
    yellow: '#f8c830',
    red: '#f1594a',
    white: '#FFFFFF',
    black: '#000000',
}
// Do not use array to make sure that index is same as in Inspector
const classifications = {
    0: { name: 'Good', color: colors.green },
    1: { name: 'Info', color: colors.blue },
    2: { name: 'Warning', color: colors.yellow },
    3: { name: 'Critical', color: colors.red },
};

const overlayTypes = {
    Info: { name: 'info', color: colors.lightBlue },
    Warn: { name: 'warning', color: colors.yellow},
    Fail: { name: 'failure', color: colors.red },
};

export { 
    colors,
    classifications,
    overlayTypes,
}