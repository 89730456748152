import React, { useContext, useEffect, useCallback } from 'react';
import ProductList from './ProductList';
import ImageData from './ImageData';
import { useState } from 'react';
import { FullWidthImage } from './Image';
import { getImageList, getImage, getImageData } from '../../api/images';
import { DataSelectionContext, usePageTitle } from '../../contexts';
import parse from 'date-fns/parse'
import { Grid, ListSubheader, Typography, Stack, Paper, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { makeStyles } from '@mui/styles';
import inMemoryJWT from '../../authentication/inMemoryJwt';
import { GET_PRODUCT_ERRORS  } from '../../api/results';
import { useQuery } from '@apollo/client';
import { addHours, startOfDay } from 'date-fns';
import { styled } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: '0em'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '2em'
    }
  },
  header: {
    textTransform: 'uppercase',
    letterSpacing: '0.2rem',
    fontSize: '1.0rem',
    marginTop: '1em',
    [theme.breakpoints.down('md')]: {
      '&': {
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        marginTop: '1.5em',
        marginBottom: '0.5em',
        color: theme.headerColor,
        fontFamily: 'gotham',
        position: 'relative',
        fontSize: '1.5rem',
        zIndex: 1,
        overflow: 'hidden',
        textAlign: 'center',
        fontWeight: 500,
      },
      '&:before, &:after': {
        position: 'absolute',
        top: '51%',
        overflow: 'hidden',
        width: '49.4%',
        height: '1px',
        content: '""',
        backgroundColor: theme.headerColor,
      },
      '&:after': {
        marginLeft: '0.4%',
      },
      '&:before': {
        marginLeft: '-49.9%',
        textAlign: 'right',
      }
    }
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Header({ children }){
  const classes = useStyles();
  return (
    <ListSubheader component="div" id="nested-list-subheader">
      <Typography variant="h4" className={classes.header}>{children}</Typography>
    </ListSubheader >
  );
}

function getOriginalImageSize(imageData) {
  const imageWidthKey = 'ImageWidth';
  const imageHeightKey = 'ImageHeight';
  const measurements = Object.entries(imageData?.Data?.Measurements || {});
  const getValue = key => {
    const [, v] = measurements.find(([, v]) => v.Name === key) || [];
    return v ? parseInt(v.Value) : null;
  };
  const width = getValue(imageWidthKey);
  const height = getValue(imageHeightKey);
  return { width, height };
}

function getImageDataPathFromImagePath(imagePath) {
  const splitPath = imagePath.split('/');
  const filename = splitPath[splitPath.length - 1];
  const folder = splitPath[splitPath.length - 2];

  const isImageInSubFolder = folder === 'reduced' || folder === 'full';
  const imageDataFolder = splitPath.slice(0, isImageInSubFolder ? -2 : -1).join('/');

  const imageDataExtension = '.bmp.json';
  const filenameWithoutExtension = filename.slice(0, filename.lastIndexOf('.'));
  const imageDataFilename = `${filenameWithoutExtension}${imageDataExtension}`;
  return `${imageDataFolder}/${imageDataFilename}`;
}

function getInfoFromImagePath(imagePath, bucket){
  const splitPath = imagePath.split('/');
  const filename = splitPath[splitPath.length - 1];
  // Assume that file name format is "20210727_134118_707_lane0_ImageTopHyperspectral.jpeg" for 27/7-2021 13:41:18.707 on lane 1, aspect top hyperspectral
  const [date, time, ms, lane, aspectWithExtension] = filename.split('_');
  const tm = parse(`${date}_${time}_${ms}`, "yyyyMMdd_HHmmss_SSS", new Date());
  // assume format is lane0 for index 0, i.e. ignore first 4 characters
  const laneIndex = lane.slice(4);
  const aspectName = aspectWithExtension.slice(0, filename.lastIndexOf('.'));
  const imageDataPath = getImageDataPathFromImagePath(imagePath);
  return {
    tm,
    laneIndex,
    aspectName,
    bucket,
    imagePath,
    imageDataPath,
  }
}

function ImageViewer() {
  const classes = useStyles();
  const { dateRange } = useContext(DataSelectionContext);
  const { startDate, endDate } = dateRange.value;
  usePageTitle('Images');
  const [pageSize/*, setPageSize*/] = useState(5);
  const [page, setPage] = useState(1);
  const [s3Images, setS3Images] = useState([]);
  const [moreDataParams, setMoreDataParams] = useState({});
  const [imageListLoading, setImageListLoading] = useState(false);

  const [product, setProduct] = useState(null);
  const [image, setImage] = useState(null);

  const [imageData, setImageData] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);

  const [overlayOpacity, setOverlayOpacity] = useState(1.0);
  const [imageScale, setImageScale] = useState(1.00);
  const [overlaysEnabled, setOverlaysEnabled] = useState(['Detection']);

  const [hiddenErrorTypes, setHiddenErrorTypes] = useState([]);

  const showDebugOverlayTag = 'Service info';

  // 2: detection
  // 6011683: topping amount
  // 6148164: topping position
  // 4674486: foreign bodies
  // 10661599: deformation
  const { data: productErrorsData, loading: productErrorsLoading, error: productErrorsError } = useQuery(GET_PRODUCT_ERRORS  , {
    variables: {
      tm_start: startDate,
      tm_end: endDate,
      //ignored_result_type_ids: [2, 6011683, 6148164]
      ignored_result_type_ids: [2]
    },
  });


  const fetchImages = useCallback(async (additionalParameters = {}, prependData = []) => {
    const tokenInfo = inMemoryJWT.getToken();
    const { accessToken } = tokenInfo;
    // convert selected local tz to UTC, since S3 images are stored using local timestamp.
    // This assumes that the selected interval is in the same tz as the vision machine tz.
    const convertLocalTzToUTC = tm => new Date(tm.getTime() - tm.getTimezoneOffset() * 60 * 1000);
    const parameters = { ...additionalParameters, tmStart: convertLocalTzToUTC(startDate), tmEnd: convertLocalTzToUTC(endDate) };
    setImageListLoading(true);
    setMoreDataParams({})
    const imageListResult = await getImageList(accessToken, parameters);
    setImageListLoading(false);
    if (!imageListResult || !Array.isArray(imageListResult.Contents)) {
      setS3Images(prependData);
      return;
    }

    const topColorList = imageListResult.Contents.filter(d => d.Key.includes("ImageTopColor"));
    const mappedData = topColorList.map(d => getInfoFromImagePath(d.Key, imageListResult.Name));
    const newData = [...prependData, ...mappedData];
    setS3Images(newData);
    const { IsTruncated, idx, maxIdx, NextContinuationToken } = imageListResult;
    setMoreDataParams({ isTruncated: IsTruncated, idx, maxIdx, nextContinuationToken: NextContinuationToken })
  }, [endDate, startDate]);

  useEffect(() => {
    fetchImages();
  }, [startDate, endDate, fetchImages]);

  const onProductSelected = async (product) => {
    const { accessToken } = inMemoryJWT.getToken();
    setProduct(product);
    setImage(null);
    setImageData(null);
    setLoadingImage(true);
    // Load image
    const pathImage = product?.imageInfo?.imagePath;
    if (pathImage) {
      try {
        const image = await getImage(accessToken, pathImage);
        setImage(image);
      }
      catch (err) {
        console.error(`Error fetching image`, err);
      }
    }
    // Load image data
    const pathImageData = product?.imageInfo?.imageDataPath;
    if (pathImageData) {
      try {
        const json = await getImageData(accessToken, pathImageData);
        setImageData(json);
      }
      catch (err) {
        console.error(`Error fetching image data`, err);
      }
    }
    setLoadingImage(false);
  };
  const toggleOverlay = overlayName => {
    if (overlaysEnabled.includes(overlayName))
      setOverlaysEnabled(overlaysEnabled.filter(d => d !== overlayName));
    else
      setOverlaysEnabled([...overlaysEnabled, overlayName]);
  };

  const productExample = {
    "time": "2022-01-26T15:02:09.929+00:00",
    "value": 3,
    "lane": 0,
    "product": {
        "type": { "name": "1976 Grandiosa 4 x ost 505 g" },
        "errors": [{ "type": { "name": "ForeignBodies" } } ]
    }
};

  const originalImageSize = getOriginalImageSize(imageData);

  const productErrors =  (productErrorsData?.productErrors || []).map(d => ({name: d.type.name, id: d.result_type_id, time: d.time, tm: new Date(d.time), lane: d.lane }))
  ;
  const productErrorsInImages = productErrors.filter(productError => s3Images.some(s3Image => s3Image.tm.getTime() === productError.tm.getTime()));
  const errorTypes = productErrorsInImages.reduce((acc, d)=> ({...acc, [d.name]: (acc[d.name] || 0) + 1 }), {})

  const shownErrorTypes = Object.keys(errorTypes).filter(d => !hiddenErrorTypes.includes(d));
  const productErrorsInImagesShown = productErrorsInImages.filter(d => shownErrorTypes.includes(d.name));
  const onlyShowImagesInDatabase = false;
  const shownS3Images = onlyShowImagesInDatabase ? s3Images.filter(s3Image => {
    const s3Time = s3Image.tm.getTime();
    // eslint-disable-next-line eqeqeq
    return productErrorsInImagesShown.some(d => d.tm.getTime() === s3Time && d.laneIndex == s3Image.lane);
  }) : s3Images;
  const imageListInWindow = shownS3Images.slice(pageSize * (page - 1), pageSize * page)
  const productCount = shownS3Images.length;

  const products = imageListInWindow.map(d => ({
    time: d.tm,
    tm: d.tm,
    lane: parseInt(d.laneIndex) + 1,
    imageInfo: d
  }))

  const onLoadMoreProducts = () => {
    const { nextContinuationToken, idx, maxIdx } = moreDataParams;
    if (nextContinuationToken)
      return fetchImages({ nextContinuationToken }, s3Images);
    if (idx < maxIdx)
      return fetchImages({ idx: moreDataParams.idx + 1 }, s3Images);
  };
  const onErrorTypeCheckChanged = errorTypeName => {
    const isHidden = hiddenErrorTypes.includes(errorTypeName);
    const newHiddenErrorTypes = isHidden ? hiddenErrorTypes.filter(d => d !== errorTypeName) : [...hiddenErrorTypes, errorTypeName];
    setHiddenErrorTypes(newHiddenErrorTypes);
  }

  const goToPrevProduct = React.useCallback(() => {
    const index = products.findIndex(d => d.imageInfo.imagePath === product?.imageInfo?.imagePath);
    const prevIndex = (!product || !~index) ? products.length - 1 : index - 1;
    const isOutsideOfPage = prevIndex < 0 && products.length > 0;
    if (isOutsideOfPage) {
      if (page > 1)
        setPage(page - 1);
    }
    else
      onProductSelected(products[prevIndex]);

  });
  const goToNextProduct = React.useCallback(() => {
    const index = products.findIndex(d => d.imageInfo.imagePath === product?.imageInfo?.imagePath);
    const nextIndex = (!product || !~index) ? 0 : index + 1;
    const isEndOfPage = nextIndex === products.length && products.length > 0;

    if (isEndOfPage){
      const pageCount = Math.ceil(productCount / pageSize);
      const isLastPage = page === pageCount;
      if (isLastPage)
        onLoadMoreProducts();
      else
        setPage(page + 1);
    }
    else
      onProductSelected(products[nextIndex]);
  })

  const handleKeyDown = React.useCallback(event => {
    const { key } = event
    if (key === 'ArrowRight')
      goToNextProduct();
    else if (key === 'ArrowLeft')
      goToPrevProduct();
  }, [goToNextProduct])

  //const handleKeyUp = React.useCallback(event => { const { key } = event; }, [])

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    //document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      //document.removeEventListener('keyup', handleKeyUp)
    }
  })

  return (
    <div className={classes.container}>
      <Grid container spacing={0} direction="row" justifyContent="space-around" alignItems="flex-start" style={{ minHeight: 'min(100vw,24vh)', width: '100%' }}>
        <Grid item xs={12} style={{ height: '100%' }}>
          <Stack direction='row' spacing={2} justifyContent='space-evenly'>
            {
              Object.entries(errorTypes).map(([errorTypeName, count]) =>
                <FormGroup key={errorTypeName}>
                  <FormControlLabel control={<Checkbox checked={!hiddenErrorTypes.includes(errorTypeName)} onChange={() => onErrorTypeCheckChanged(errorTypeName)} />} label={`${errorTypeName} (${count})`} />
                </FormGroup>
              )
            }
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} style={{ height: '100%' }}>
          <ProductList
            products={products}
            selectedProduct={product}
            productCount={productCount}
            pageSize={pageSize}
            setPage={setPage}
            currentPage={page}
            onClick={onProductSelected}
            loadingProducts={imageListLoading}
            loadingImageList={imageListLoading}
            loadingImage={loadingImage}
            loadMoreProducts={onLoadMoreProducts}
            canLoadMoreProducts={moreDataParams.nextContinuationToken || moreDataParams.idx < moreDataParams.maxIdx}
            Header={Header}
          />
        </Grid>
        {<ImageData
          imageData={imageData}
          toggleOverlay={toggleOverlay}
          overlaysEnabled={overlaysEnabled}
          showDebugOverlayTag={showDebugOverlayTag}
          Header={Header}
          overlayOpacity={overlayOpacity}
          setOverlayOpacity={setOverlayOpacity}
        />}
      </Grid>
      {product && image &&
        <FullWidthImage
          imagePath={URL.createObjectURL(image)}
          overlaysEnabled={overlaysEnabled}
          overlays={imageData?.Overlays}
          overlayOpacity={overlayOpacity}
          imageScale={imageScale}
          setImageScale={setImageScale}
          showDebugOverlay={overlaysEnabled.includes(showDebugOverlayTag)}
          originalImageSize={originalImageSize}
        />
      }
    </div>
  );
}

export default ImageViewer;
;