import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState  } from 'react';
import { useHistory } from 'react-router-dom';
import { login } from '../../authentication/authentication';
import inMemoryJWT from '../../authentication/inMemoryJwt';
import { Button, Input } from '../common';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    form: {
        maxWidth: '330px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        marginTop: '2em',
        '& > *': {
            margin: '1em 0',
        }
    },
    container: {
        [theme.breakpoints.down('md')]: {
            margin: '0.5em',
        },
        [theme.breakpoints.up('sm')]: {
            margin: '2em auto',
        },
        padding: '2em',
        maxWidth: 'min(600px, 90vw)',
    }
}))

function SignIn({ isAuthenticated, setToken }) {
    const classNames = useStyles();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleAuthenticate = async () => {
        const response = await login(username, password);
        if (response) {
            inMemoryJWT.setToken(response);
            const tokenInfo = inMemoryJWT.getToken();
            setToken(tokenInfo);
            history.push('/dashboard');
        }
    };
    const handleLogOut = () => {
        inMemoryJWT.ereaseToken();
        setToken(null);
    };
    return (
        <Paper className={classNames.container}>
            <Typography variant="h3">Sign in</Typography>
            <form className={classNames.form}>
                <Input labelText='username' type='text' value={username} onChange={e => setUsername(e.target.value)} />
                <Input labelText='password' type='password' value={password} onChange={e => setPassword(e.target.value)} />
                <Button variant='contained' color='primary' onClick={handleAuthenticate}>Log in</Button>
                {isAuthenticated && <Button onClick={handleLogOut}>Log out</Button>}
            </form>
        </Paper>
    )
}

export default SignIn;