import React from 'react';
import { Sector, Cell, PieChart, Pie, ResponsiveContainer } from 'recharts';
import withTheme from '@mui/styles/withTheme';
import { useMediaQuery } from '@mui/material';

// https://gist.github.com/emiloberg/ee549049ea0f6b83e25f1a1110947086
// https://react-gaugechart-emiloberg.stackblitz.io/
const GaugeChart = ({ theme, value, colorData }) => {
    // const { breakpoints } = useTheme();
    //const isMediumSizedScreen = useMediaQuery(breakpoints.up('md') && breakpoints.down('lg'));
    // Meaning span is 0-65, 65-85 and 85-100
    colorData = colorData || [{ value: 65, color: theme.results.fail }, { value: 20, color: theme.results.warn }, { value: 15, color: theme.results.ok }];

    const activeSectorIndex = colorData.findIndex((cur, index, arr) => {
        const curMax = [...arr].splice(0, index + 1).reduce((s, d) => s + d.value, 0);
        return (value > (curMax - cur.value)) && (value <= curMax);
    });

    const sumValues = colorData.map(d => d.value).reduce((s, d) => s + d, 0);

    const arrowData = [value, 0, sumValues - value].map(value => ({ value }));

    const pieProps = {
        startAngle: 180,
        endAngle: 0,
        // Move chart to bottom half of draw area
        cy: '85%'
    };

    // Cannot set pie radius using CSS media queries directly, so use this approach
    const pieRadiusNormal = { innerRadius: '120%', outerRadius: '140%' };
    const pieRadiusMediumScreens = { innerRadius: '60%', outerRadius: '80%' }
    const pieRadiusLargeScreens = { innerRadius: '80%', outerRadius: '100%' }
    const isMediumSizedScreen = useMediaQuery('(min-width:960px) and (max-width:1235px)');
    const isLargeSizedScreen = useMediaQuery('(min-width:1235px) and (max-width:1760px)');
    const pieRadius = isMediumSizedScreen ? pieRadiusMediumScreens : ( isLargeSizedScreen ? pieRadiusLargeScreens : pieRadiusNormal);

    const Arrow = ({ cx, cy, midAngle, outerRadius }) => { //eslint-disable-line react/no-multi-comp
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const mx = cx + (outerRadius * 1.08) * cos;
        const my = cy + (outerRadius * 1.08) * sin;
        return (
            <g>
                <circle cx={cx} cy={cy} r='2%' fill="#666" stroke="none" />
                <path d={`M${cx},${cy}L${mx},${my}`} strokeWidth="6" stroke="#666" fill="none" strokeLinecap="round" />
            </g>
        );
    };

    const ActiveSectorMark = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => { //eslint-disable-line react/no-multi-comp
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius * 1.2}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    };
    return (
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    activeIndex={activeSectorIndex}
                    activeShape={ActiveSectorMark}
                    data={colorData}
                    dataKey='value'
                    {...pieRadius}
                    {...pieProps}
                >
                    {colorData.map((entry, index) => <Cell key={`cell-${index}`} fill={colorData[index].color} />)}
                </Pie>
                <Pie
                    stroke="none"
                    activeIndex={1}
                    activeShape={Arrow}
                    data={arrowData}
                    dataKey='value'
                    outerRadius={pieRadius.innerRadius}
                    fill="none"
                    {...pieProps}
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default withTheme(GaugeChart);