import { Grid, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    chipEnabled: {

    },
    chipDisabled: {
        textDecoration: 'line-through',
    }
}));

function FilterGroup({ filters, onClick, isEnabled }) {
    const classes = useStyles();
    const chipClass = isEnabled ? classes.chipEnabled : classes.chipDisabled;
    return (
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" >
            {filters.map((d) =>
                <Chip
                    key={d}
                    className={chipClass}
                    color='primary'
                    variant={!isEnabled ? 'outlined' : ''}
                    onDelete={() => onClick(d, !isEnabled)}
                    avatar={<Avatar>{d[0].toLocaleUpperCase()}</Avatar>}
                    label={d}
                />)}
        </Grid>
    );
}

function Filters({ enabled = [], disabled = [], onClick }) {
    return (
        <Grid style={{ marginBottom: '1em' }}>
            <Typography variant="h5" gutterBottom component="div">
                Shown events
            </Typography >
            <FilterGroup filters={enabled} isEnabled={true} onClick={onClick} />
            {disabled.length > 0 &&
                <>
                    <Typography variant="h5" gutterBottom component="div" style={{ marginTop: '1em' }}>
                        Hidden events
                    </Typography >
                    <FilterGroup filters={disabled} isEnabled={false} onClick={onClick} />
                </>
            }
        </Grid>
    )
}

export default Filters;