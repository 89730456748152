import { gql } from '@apollo/client';

export const GET_LATEST_PRODUCT_TYPE_MEASUREMENTS = gql`
    query GetLatestProductTypeMeasurements(
        $limit: Int! = 1000,
        $offset: Int
    ) {
        productTypeMeasurements: production_intelligence_v_last_30_minutes_product_type_measurements (
            where: {
                customer_id: {_eq: 7},
                line_id: {_eq: 32}
            },
            limit: $limit,
            offset: $offset
        ){
            customer_id
            line_id
            lane
            name
            unit
            avg_value
            product_type_id
        }
    }
`

/*
export const GET_PRODUCT__MEASUREMENTS = gql`
    query GetProductMeasurementsx(
        $productTypeIds: [bigint!]!,
        $limit: Int! = 1000,
        $offset: Int
    ) {
        productTypeMeasurements: production_intelligence_v_product_type_measurement_aggregates(
            where: {
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                product_type_id: { _in: $productTypeIds }
                #time_segment: {_gte: "2021-09-13 00:00:00", _lte: "2021-09-13 23:00:00"}
                #order_name: {_eq: "Order 17"}
            },
            limit: $limit,
            offset: $offset
        ){
            customer_id
            line_id
            lane
            name
            unit
            avg_value
            product_type_id
        }
    }
`
*/

export interface MeasurementType {
    id: number;
    name: string;
};
export interface ProductType {
    id: number;
    name: string;
};
export interface ProductTypeMeasurement {
    measurement_type: MeasurementType;
    product_type: ProductType;
    value: number;
}

export const GET_PRODUCT_TYPE_MEASUREMENTS = gql`
    query GetProductTypeMeasurements(
        $product_type_id: bigint
    ) {
        measurements: production_intelligence_get_product_type_measurements(args: {
            _product_type_id: $product_type_id
        }){
            measurement_type {
                id,
                name
            }
            product_type {
                id
                name
            }
            value
        }
    }
`;

export interface ProductMeasurement {
    lane: number;
    measurement_type_id: number;
    value: number;
}
        //$order_by: [production_intelligence_orders_order_by!]
export const GET_PRODUCT_MEASUREMENTS = gql`
    query GetProductMeasurements(
        $tm_start: timestamptz,
        $tm_end: timestamptz,
        $measurement_type_ids: _int4
    ) {
        measurements: production_intelligence_get_product_measurements(args: {
            _tm_start: $tm_start
            _tm_end: $tm_end
            _measurement_type_ids: $measurement_type_ids
        }){
            lane
            measurement_type_id
            value
        }
    }
`;

