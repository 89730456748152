import { List, ListItem, ListItemText } from "@mui/material";
import format from 'date-fns/format';

function ProductInfoEntry({ tag, type, value }) {
  const getName = () => {
    if (tag === 'Lane id')
      return 'Lane';
    return tag;
  };
  const getValue = () => {
    // Return lane number instead of lane index
    if (tag === 'Lane id')
      return parseInt(value) + 1;
    if (tag === 'Time of acquisition')
      return format(new Date(value), 'HH:mm:ss, d. MMMM yyyy');
    return value;
  }
  return (
    <ListItem>
      <ListItemText primary={getValue()} secondary={getName()} />
    </ListItem>
  );
}

function ProductInfo({ productInfo, className, Header }) {
  const ignoredTags = ['Sequence number', 'Teach time']
  const entries = Object.entries(productInfo || {}).filter(([k, v]) => !ignoredTags.includes(v.Tag));
  return (
    <List
      className={className}
      subheader={<Header>Product info</Header>}
    >
      {entries.map(([k, v]) => <ProductInfoEntry key={k} tag={v.Tag} type={v.Type} value={v.Value} />)}
    </List>
  );
}

export default ProductInfo;