import { refresh, login, logout } from './authentication';
import { localStorageUsernameKey, localStoragePasswordKey } from '../constants';

const inMemoryJWTManager = () => {
    const logoutEventName = 'ra-logout';
    const refreshTokenStorageName = 'ra-refresh-token';
    let inMemoryJWT = null
    let refreshTimeOutId;

    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    const performTokenRefresh = async () => {
        const refreshToken = (inMemoryJWT && inMemoryJWT.refreshToken) || window.localStorage.getItem(refreshTokenStorageName);
        if (!refreshToken) {
            console.log(`performTokenRefresh, NO REFRESH TOKEN AVAILABLE`)
            const username = window.localStorage.getItem(localStorageUsernameKey);
            const password = window.localStorage.getItem(localStoragePasswordKey);
            if (username && password) {
                console.log(`Attempting new login with user ${username}`);
                const token = await login(username, password);
                // refresh failed, so delete old refresh token to avoid same problem next time (expired/logged out from server)
                if (!token) {
                    console.log(`performTokenRefresh login, NO TOKEN RECEIVED`)
                    ereaseToken();
                }
                return token && setToken(token);
            }
            else
                console.log(`Cannot attempt new login due to missing credentials`)
            return false;
        }
        else {
            const token = await refresh(refreshToken);
            // refresh failed, so delete old refresh token to avoid same problem next time (expired/logged out from server)
            if (!token) {
                console.log(`performTokenRefresh, NO TOKEN RECEIVED`)
                ereaseToken();
            }
            return token && setToken(token);
        }
    };

    // This countdown feature is used to renew the JWT in a way that is transparent to the user.
    // before it's no longer valid
    const refreshTokenWhenTokenExpires = () => {
        if (!inMemoryJWT)
            return console.error('Trying to start token refresh without any token present!');
        const refreshTokenRemainingTimeMs  = (inMemoryJWT.refreshTokenExpirationTime - (new Date()));
        const remainingTimeMs = (inMemoryJWT.tokenExpirationTime - (new Date()));
        if (refreshTokenRemainingTimeMs < remainingTimeMs)
            return console.error('Refresh token has shorter lifespan than access token!');
        const refreshDelay = remainingTimeMs - 10000;
        if (refreshTimeOutId)
            clearTimeout(refreshTimeOutId);
        refreshTimeOutId = window.setTimeout(performTokenRefresh, refreshDelay); // Validity period of the token in seconds, minus 5 seconds
    };

    const abortRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const getToken = () => inMemoryJWT;

    const setToken = (token) => {
        inMemoryJWT = token;
        window.localStorage.setItem(refreshTokenStorageName, token.refreshToken);
        refreshTokenWhenTokenExpires(token);
        return true;
    };

    const ereaseToken = () => {
        logout(inMemoryJWT);
        inMemoryJWT = null
        abortRefreshToken();
        window.localStorage.removeItem(refreshTokenStorageName);
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    return {
        ereaseToken,
        getToken,
        setToken,
        performTokenRefresh,
    }
};

export default inMemoryJWTManager();