import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withTheme from '@mui/styles/withTheme';
import { Theme } from '@mui/material/styles/createTheme';
import { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Label } from 'recharts';

const useStyles = makeStyles((theme : Theme ) => ({
    centerLabel: {
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.up('sm')]: {
            fontSize: (props : any) => `min(7vw, ${props.maxHeight})`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2em'
        }
    },
}));

interface renderActiveShapeWithoutLabelProps {
    cx?: number;
    cy?: number;
    innerRadius?: number;
    outerRadius: number;
    startAngle?: number;
    endAngle?: number;
    fill?: string;
}
const renderActiveShapeWithoutLabel  = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill  } : renderActiveShapeWithoutLabelProps) => {
    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
}

//const renderActiveShapeWithLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value }) => {
//    const RADIAN = Math.PI / 180;
//    const sin = Math.sin(-RADIAN * midAngle);
//    const cos = Math.cos(-RADIAN * midAngle);
//    const sx = cx + (outerRadius + 10) * cos;
//    const sy = cy + (outerRadius + 10) * sin;
//    const mx = cx + (outerRadius + 30) * cos;
//    const my = cy + (outerRadius + 30) * sin;
//    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
//    const ey = my;
//    const textAnchor = cos >= 0 ? 'start' : 'end';
//
//    return (
//        <g>
//            {/* replace center? <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}> {payload.name} </text> */}
//            <Sector
//                cx={cx}
//                cy={cy}
//                innerRadius={innerRadius}
//                outerRadius={outerRadius}
//                startAngle={startAngle}
//                endAngle={endAngle}
//                fill={fill}
//            />
//            <Sector
//                cx={cx}
//                cy={cy}
//                startAngle={startAngle}
//                endAngle={endAngle}
//                innerRadius={outerRadius + 6}
//                outerRadius={outerRadius + 10}
//                fill={fill}
//            />
//            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
//            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
//            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={getColor(value/100)}>{`${parseFloat((value).toFixed(0)).toLocaleString()}%`}</text>
//            {/* sub text <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999"> {`(Rate ${(percent * 100).toFixed(2)}%)`} </text> */}
//        </g>
//    );
//}
const renderActiveShape = renderActiveShapeWithoutLabel;

interface PizzaToppingProps {
    theme: any;
    coverage?: Array<number>;
    maxHeight?: string;
};

//function PizzaTopping({ theme, coverage = [0.637483, 0.0173, 0.97, 4.01], maxHeight = '2.6em'}) {
function PizzaTopping({ theme, coverage = [0.637483, 0.0173, 0.97, 4.01], maxHeight = '2.0em'} : PizzaToppingProps) {
    const getColor = (pct : number) => Math.abs(1 - pct) <= 0.15 ? theme.results.ok : (Math.abs(1 - pct) <= 0.4 ? theme.results.warn : theme.results.fail);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const totalAverageCoverage = coverage.reduce((s, d) => s + d, 0) / coverage.length;

    const classes = useStyles({ color: getColor(totalAverageCoverage), maxHeight });

    const sliceRadiusNominal = 30;
    const innerRadius = 60;
    const onMouseEnter = (sliceIndex : number)=> setActiveIndex(sliceIndex);
    const onMouseLeave = () => setActiveIndex(null);

    return (
        <ResponsiveContainer>
            <PieChart>
                {coverage.map((coveragePct, idx) =>
                    <Pie
                        key={idx}
                        activeIndex={activeIndex === idx ? 0 : undefined}
                        activeShape={renderActiveShape}
                        data={[{ name: `Q${idx + 1}`, value: coveragePct * 100 }]}
                        dataKey='value'
                        innerRadius={`${innerRadius}%`}
                        outerRadius={`${innerRadius + sliceRadiusNominal * Math.max(Math.min(coveragePct, 1.2), 0.2)}%`}
                        startAngle={idx * 90}
                        endAngle={(idx + 1) * 90}
                        fill={getColor(coveragePct)}
                        onMouseEnter={() => onMouseEnter(idx)}
                        onMouseLeave={onMouseLeave}
                        animationDuration={600}
                    >
                        {((activeIndex === null && idx === 0) || (activeIndex === idx)) &&
                            <Label
                                stroke='none'
                                strokeOpacity={0}
                                width='100%'
                                position="center"
                                className={classes.centerLabel}
                                style={{ fill: getColor(activeIndex === null ? totalAverageCoverage : coveragePct) }}
                                value={
                                    activeIndex === idx ?
                                    `${parseFloat((coveragePct * 100).toFixed(1)).toLocaleString()}%` :
                                    ( activeIndex === null && idx === 0 ? `${parseFloat((totalAverageCoverage * 100).toFixed(1)).toLocaleString()}%` : '' )
                                }
                            />
                        }
                    </Pie>
                )}
            </PieChart>
        </ResponsiveContainer>
    );
}


export default withTheme(PizzaTopping);