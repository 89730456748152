import React from 'react';
import ReactDOM from 'react-dom';
// import Router, { Route } from 'react-router';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
//import blue from '@mui/material/colors/blue';

const theme = createTheme({
  // https://material-ui.com/customization/palette/
  palette: {
    //primary: blue
    primary: {
      main: '#2b7fb3'
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
  },
  typography: {
    fontFamily: ['gotham']
  },
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  results: {
    ok: '#5cb85c',
    fail: '#d9534f',
    warn: '#f0ad4e',
    info: '#0275d8',
  },
  headerColor: 'grey',
  fontFamily: 'gotham',
});


ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
