import { gql } from '@apollo/client';

export const GET_EVENTS = gql`
    query GetEvents(
        $tmStart: timestamptz,
        $tmEnd: timestamptz,
        $limit: Int! = 1000,
        $offset: Int,
        $withAggregates: Boolean! = true,
        $order_by: [production_intelligence_events_order_by!]
    ) {
        eventAggregates: production_intelligence_events_aggregate (
            where: {
                time: {_gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32}
            },
        ) @include(if: $withAggregates){
            aggregate {
                count,
                min {
                    time
                }
                max {
                    time
                }
            },
        },
        events: production_intelligence_events(
            where: {
                time: { _gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32}
            },
            limit: $limit,
            offset: $offset,
            order_by: $order_by
        ){
            customer_id,
            line_id,
            id,
            time,
            type {
                name
            }
            details {
                key
                value
            }
            product_type {
                name
            }
        }
    }
`;
