import { Redirect } from 'react-router-dom';
import inMemoryJWT from '../../authentication/inMemoryJwt';
import { clearLocalStorage } from '../../constants';

function SignOut({ token, setToken }) {
    inMemoryJWT.ereaseToken();
    setToken(null);
    clearLocalStorage();
    return token ? <div /> : <Redirect to='/signin' />;
}

export default SignOut;