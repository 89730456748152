import { gql } from '@apollo/client';

export const GET_PRODUCT_TYPE_DETAILS = gql`
    query GetProductTypeDetails(
        $productTypeIds: [bigint!]!,
        $limit: Int! = 1000,
        $offset: Int,
        $withAggregates: Boolean! = false,
    ) {
        productTypeDetailsAggregates: production_intelligence_v_product_type_details_aggregate (
            where: {
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                product_type_id: { _in: $productTypeIds }
            },
        ) @include(if: $withAggregates){
            aggregate {
                count,
                min {
                    bucket
                }
            },
        },
        productTypeDetails: production_intelligence_v_product_type_details(
            where: {
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                product_type_id: { _in: $productTypeIds }
                #time_segment: {_gte: "2021-09-13 00:00:00", _lte: "2021-09-13 23:00:00"}
                #order_name: {_eq: "Order 17"}
            },
            limit: $limit,
            offset: $offset
        ){
            customer_id
            line_id
            lane
            product_type_id
            bucket
            count
            classification_index
            error_name
            product_type {
                name
            }
        }
    }
`;

export const GET_ORDER = gql`
    query GetOrder(
        $tmStart: timestamptz,
        $tmEnd: timestamptz,
        $id: bigint_comparison_exp!,
        $limit: Int! = 1000,
        $offset: Int,
        $withAggregates: Boolean! = true,
    ) {
        orderAggregates: production_intelligence_v_orders_overview_aggregate (
            where: {
                order_start: {_gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                order_id: $id,
            },
        ) @include(if: $withAggregates){
            aggregate {
                count,
                min {
                    order_start
                }
            },
        },
        orders: production_intelligence_v_orders_overview(
            where: {
                order_start: { _gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                order_id: $id,
            },
            limit: $limit,
            offset: $offset
        ){
            customer_id,
            line_id,
            order_id,
            order_name,
            order_start,
            order_end,
            product_types,
            product_count_total,
            product_count_failed,
            first_product,
            last_product
        }
    }
`;

export const GET_ORDERS = gql`
    query GetOrders(
        $tmStart: timestamptz,
        $tmEnd: timestamptz,
        $limit: Int! = 1000,
        $offset: Int,
        $withAggregates: Boolean! = true,
    ) {
        orderAggregates: production_intelligence_v_orders_overview_aggregate (
            where: {
                order_start: {_gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
            },
        ) @include(if: $withAggregates){
            aggregate {
                count,
                min {
                    order_start
                }
            },
        },
        orders: production_intelligence_v_orders_overview(
            where: {
                order_start: { _gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
            },
            limit: $limit,
            offset: $offset
        ){
            customer_id,
            line_id,
            order_id,
            order_name,
            order_start,
            order_end,
            product_types,
            product_count_total,
            product_count_failed,
            first_product,
            last_product
        }
    }
`;

export const GET_ORDER_SIMPLE = gql`
    query GetOrderSimple(
        $tmStart: timestamptz,
        $tmEnd: timestamptz,
        $id: bigint_comparison_exp!,
        $limit: Int! = 1000,
        $offset: Int,
        $order_by: [production_intelligence_orders_order_by!]
        $withAggregates: Boolean! = true,
    ) {
        orderAggregates: production_intelligence_orders_aggregate (
            where: {
                tm_start: {_gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                id: $id,
            },
        ) @include(if: $withAggregates){
            aggregate {
                count,
                min {
                    tm_start
                }
            },
        },
        orders: production_intelligence_orders(
            where: {
                tm_start: { _gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                id: $id,
            },
            limit: $limit,
            offset: $offset,
            order_by: $order_by
        ){
            customer_id,
            line_id,
            id,
            name,
            tm_start,
            tm_end,
            product_types {
                id,
                name
            }
        }
    }
`;
export const GET_ORDERS_SIMPLE = gql`
    query GetOrdersSimple(
        $tmStart: timestamptz,
        $tmEnd: timestamptz,
        $limit: Int! = 1000,
        $offset: Int,
        $order_by: [production_intelligence_orders_order_by!]
        $withAggregates: Boolean! = true,
    ) {
        orderAggregates: production_intelligence_orders_aggregate (
            where: {
                tm_start: {_gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
            },
        ) @include(if: $withAggregates){
            aggregate {
                count,
                min {
                    tm_start
                }
            },
        },
        orders: production_intelligence_orders(
            where: {
                tm_start: { _gte: $tmStart, _lte: $tmEnd },
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
            },
            limit: $limit,
            offset: $offset,
            order_by: $order_by
        ){
            customer_id,
            line_id,
            id,
            name,
            tm_start,
            tm_end,
            product_types {
                id,
                name
            }
        }
    }
`;



export const GET_PRODUCT_TYPE_DETAILS_SIMPLE = gql`
    query GetProductTypeDetailsSimple(
        $productTypeIds: [bigint!]!,
        $limit: Int! = 1000,
        $offset: Int
    ) {
        productTypeDetails: production_intelligence_v_product_type_details(
            where: {
                customer_id: {_eq: 7},
                line_id: {_eq: 32},
                product_type_id: { _in: $productTypeIds }
                #time_segment: {_gte: "2021-09-13 00:00:00", _lte: "2021-09-13 23:00:00"}
                #order_name: {_eq: "Order 17"}
            },
            limit: $limit,
            offset: $offset
        ){
            customer_id
            line_id
            lane
            product_type_id
            bucket
            count
            classification_index
            error_name
            product_type {
                name
            }
        }
    }
`;
