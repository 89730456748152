import Toppings from '../Dashboard/Toppings';
import Quality from './Quality';
import Productivity from './Productivity';
import * as constants from "../../../constants"
import { useEffect } from 'react';
import { DashboardRow } from '../Dashboard/containers';
import makeStyles from '@mui/styles/makeStyles';
import { usePageTitle } from '../../../contexts';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_PRODUCT_TYPES  } from '../../../api/productTypes';
import { GET_CLASSIFICATION_DETAILS  } from '../../../api/classifications';
import { GET_PRODUCTS_AGGREGATE  } from '../../../api/products';
import { GET_PRODUCT_TYPE_MEASUREMENTS, GET_PRODUCT_MEASUREMENTS } from '../../../api/measurements';
import { addDays, addMinutes, differenceInMilliseconds, format, isAfter, isEqual, set, subMinutes } from 'date-fns';
import { reload } from '../../../helpers';

const DASHBOARD_WINDOW_SIZE_MINUTES = 30;

const useStyles = makeStyles(theme => ({
    header: {
        '&': {
            textTransform: 'uppercase',
            letterSpacing: '0.2em',
            //marginTop: '2em',
            marginBottom: 0,
            color: theme.headerColor,
            fontFamily: 'gotham',
            position: 'relative',
            fontSize: '2em',
            zIndex: 1,
            overflow: 'hidden',
            textAlign: 'center',
            fontWeight: 500,
        },
        '&:before, &:after': {
            position: 'absolute',
            top: '51%',
            overflow: 'hidden',
            width: '49.4%',
            height: '1px',
            content: '""',
            backgroundColor: theme.headerColor,
        },
        '&:after': {
            marginLeft: '0.4%',
        },
        '&:before': {
            marginLeft: '-49.9%',
            textAlign: 'right',
        }
    }
}));

function Container({ name, children }) {
    const classes = useStyles();
    //<h1 className={classes.header}>{name}</h1>
    // or
    //<Header>{name}</Header>
    return (
        <div>
            <h1 className={classes.header}>{name}</h1>
            <DashboardRow>
                {children}
            </DashboardRow>
        </div>
    )
}

function floorMinute(time) {
    const timeToReturn = new Date(time);
    timeToReturn.setMilliseconds(0);
    timeToReturn.setSeconds(0);
    return timeToReturn;
}

function fillGaps(production){
    const durationInMinutes = DASHBOARD_WINDOW_SIZE_MINUTES;
    const end = floorMinute(addMinutes(new Date(), 1));
    const start = subMinutes(end, durationInMinutes);
    const emptyData = Array(durationInMinutes).fill().map((d, i) => addMinutes(start, i));
    const defaultClassifications = Object.entries(constants.classifications)
        .reduce((acc, [k, v]) => ({ ...acc, [v.tag]: 0 }), {});
    const productionWithoutGaps = emptyData.map(tm => 
        production.find(d => isEqual(d.tm, tm)) ||
        { tm, time_bucket: format(tm, "yyyy-MM-ddTHH:mm:00+00:00"), count_total: 0, count_failed: 0, ...defaultClassifications }
    );
    return productionWithoutGaps;
}

function mapToClassificationData(products, classificationDetails) {
    // eslint-disable-next-line no-unused-vars
    const inputClassificationDetails = [{
        "__typename": "production_intelligence_spec_return_type_classification_details",
         "tm": new Date("2022-01-19T18:32:00+00:00"),
         "time_bucket": "2022-01-19T18:32:00+00:00",
         "classification": 1,
         "count": 1,
         "result_id": 6011683,
         "result_name": "ToppingAmount" 
    }];
    // eslint-disable-next-line no-unused-vars
    const inputProducts = [{
        "__typename": "production_intelligence_spec_return_type_product_count",
         "time_bucket": "2022-01-19T17:53:00+00:00",
         "lane": 0,
         "product_type_id": 1642568840138234,
         "count_total": 6,
         "count_failed": 0
        }];
    const resultTypes = classificationDetails.map(d => d.result_name).filter((d, i, arr) => arr.indexOf(d) === i);

    const grouped = Object.entries(constants.classificationsDisplay).map(([classificationIndex, { tag }]) => {
        const results = resultTypes.map(resultType => {
            const count = classificationDetails
                .filter(d => {
                    const isSameResultName = d.result_name === resultType;
                    const isSameClassificationIndex =  d.classification_index === parseInt(classificationIndex);
                    return isSameResultName && isSameClassificationIndex;
                })
                .reduce((sum, d) => sum + (d.count || 0), 0);
            return { name: resultType, count };
        });
        const count = results.reduce((sum, d) => sum + d.count, 0);
        return { classification: tag, results, count };
    });
    const countOk = products.map(d => d.count_total - d.count_failed).reduce((sum, d) => sum + d, 0);
    const indexOk  = Object.entries(constants.classificationsDisplay).find(([classificationIndex, { tag }]) => tag === 'ok')[0];
    grouped[indexOk].count = countOk;
    return grouped;
}

function mapToProductionData(products, classificationDetails) {
    // eslint-disable-next-line no-unused-vars
    const inputClassificationDetails = [{
        "__typename": "production_intelligence_spec_return_type_classification_details",
         "tm": new Date("2022-01-19T18:32:00+00:00"),
         "time_bucket": "2022-01-19T18:32:00+00:00",
         "classification_index": 1,
         "count": 1,
         "result_id": 6011683,
         "result_name": "ToppingAmount" 
    }];
    // eslint-disable-next-line no-unused-vars
    const inputProducts = [{
        "__typename": "production_intelligence_spec_return_type_product_count",
        "time_bucket": "2022-01-19T17:53:00+00:00",
        "lane": 0,
        "product_type_id": 1642568840138234,
        "count_total": 6,
        "count_failed": 0
    }];
    const classificationTags = Object.values(constants.classifications).map(d => d.tag);

    const inputData = products.map(d => {
        const classificationInput = classificationDetails
            .filter(c => c.time_bucket === d.time_bucket)
            .map(c => ({ ...c, classification: classificationTags[c.classification_index] }))
        ;
        const classificationData = classificationTags.reduce((acc, t) => ({
            ...acc,
            [t]: (acc[t] || 0) + classificationInput.filter(c => c.classification === t).reduce((a, c) => a + c.count, 0)
        }), { ok: d.count_total - d.count_failed })
        return {
            ...d,
            ...classificationData
        }
    })
    return inputData;
}

function getToppingData(dataProductTypeMeasurements, dataProductMeasurements){
    if (!dataProductTypeMeasurements || !dataProductMeasurements)
        return [];
    // eslint-disable-next-line no-unused-vars
    const dptm = {
        "measurement_type":{"id":1,"name":"width"},
        "product_type":{"id":1643605631499826,"name":"1980 (156892 2pk) Grandiosa Classic 10x575g"},
        "value":280
    };
    // eslint-disable-next-line no-unused-vars
    const dpm = {lane: 1, measurement_type_id: 579089562, value: 0.18533061};
    // multiple dpm points to same target, i.e. 
    //  {id: 443798030, name: 'dist_Cheese_Q3} AND {id: 443798022, name: 'dist_Cheese_Q1'} points to
    // id: 647382705, name: "target_Cheese"
    // dpm does not have target name, only measurement_type_id (i.e. dist_Cheese_Q1 id and NOT target_Cheese id)
    // map id 443798022 to Cheese and then Cheese to 647382705/"target_Cheese"

    const targetPrefix = 'target_';
    const productTypeMeasurements = dataProductTypeMeasurements.measurements || [];
    const productMeasurements = dataProductMeasurements.measurements || [];
    const targets = productTypeMeasurements 
        .filter(d => d?.measurement_type?.name?.startsWith(targetPrefix))
        .map(d => ({
            name: d.measurement_type.name.slice(targetPrefix.length),
            target: d.value
        }))
    ;
    const getMeasurementTypeName = measurementTypeId =>
        productTypeMeasurements.find(ptm => ptm.measurement_type?.id === measurementTypeId)?.measurement_type.name
    const getTarget = measurementTypeId =>{
        const measurementTypeName = getMeasurementTypeName(measurementTypeId);
        if (!measurementTypeName)
            return null;
        //const distributionPrefix = 'dist_';
        //const distributionPrefixLength = distributionPrefix.length;
        const distributionSuffix = '_Qx';
        const distributionSuffixLength = distributionSuffix.length;
        //const getName = d => d.slice(distributionPrefixLength, -distributionSuffixLength);
        const quadrant = measurementTypeName.slice(-distributionSuffixLength+1);
        return {
            ...(targets.find(target => measurementTypeName.includes(target.name)) || {}),
            quadrant
        };
    }

    const productMeasurementsWithTargets = productMeasurements.map(dpm => {
        const target = getTarget(dpm.measurement_type_id);
        if (!target)
            return null;
        return {
            ...dpm,
            ...target
        };
    })
    // eslint-disable-next-line no-unused-vars
    const pmwt = {
        "lane": 0,
        "measurement_type_id": 631863132,
        "value": 0.1420918,
        "target": 0.135922,
        "name": "cheese",
        "quadrant": "Q3"
    };
    const common = productMeasurementsWithTargets.reduce((acc, d, i, arr) => {
        if (!d)
            return acc;
        const isAdded = acc.some(({ name }) => name === d.name);
        if (isAdded)
            return acc;
        const laneEntries = arr.filter(laneEntry => laneEntry && laneEntry.name && laneEntry.name === d?.name)
        const quadrants = Object.keys(laneEntries.reduce((acc, d) => ({ ...acc, [d.quadrant]: 0 }), {})).sort();
        const quadrantValues = quadrants.map(quadrant =>
            laneEntries
                .filter(d => d.quadrant === quadrant)
                .reduce((acc, d, i, arr) => acc + d.value / arr.length, 0)
        );
        const newEntry = {
            ...d,
            quadrantValues,
            lane: -1
        }
        return [...acc, newEntry];
    }, []);
    return common;
}

function Dashboard(props) {
    useEffect(() => {
        const now = new Date();
        const resetTime = set(now, { hours: 1, minutes: 0, seconds: 0, milliseconds: 0 });
        const runTomorrow = isAfter(now, resetTime);
        const nextReloadTime = runTomorrow ? addDays(resetTime, 1) : resetTime;
        const timeoutMs = differenceInMilliseconds(nextReloadTime, now);
        console.log(`Reloading in ${timeoutMs} ms`);
        const timer = setTimeout(() => { reload('Daily reload') }, Math.max(1, timeoutMs));
        return () => clearTimeout(timer);
    }, []);
    const { data: productTypesData, loading: productTypesLoading, error: productTypesError, refetch: productTypesRefetch, stopPolling: productTypesStopPolling } = useQuery(GET_PRODUCT_TYPES, {
        variables: { order_by: [{ id: 'desc'}], limit: 1 },
        pollInterval: 10000
    });
    const { data: dataProductTypeMeasurements, stopPolling: stopPollingMeasurements } = useQuery(GET_PRODUCT_TYPE_MEASUREMENTS, {
        pollInterval: 10000
    });
    ;
    const getProductTypeName = () => {
        if (productTypesError)
            return '';
        if (!Array.isArray(productTypesData?.product_types) || productTypesData.product_types.length === 0)
            return '';
        const { name } = productTypesData.product_types[0];
        return name;
    }

    const name = getProductTypeName();
    usePageTitle(name);

    const pollIntervalMs = 15000;
    const [getClassificationDetails, classificationResult] = useLazyQuery(GET_CLASSIFICATION_DETAILS, { pollInterval: pollIntervalMs });
    const { startPolling: startPollingClassificationDetails, data: dataClassificationDetails, loading: loadingClassificationDetails, error: errorClassificationDetails } = classificationResult;

    const [getProductAggregates, productAggregatesResult] = useLazyQuery(GET_PRODUCTS_AGGREGATE, { pollInterval: pollIntervalMs });
    const { startPolling: startPollingProductAggregates, data: dataProductAggregates, loading: loadingProductAggregates, error: errorProductAggregates } = productAggregatesResult;

    useEffect(() => {
        if (!Array.isArray(productTypesData?.product_types) || productTypesData.product_types.length === 0)
            return;

        const { id } = productTypesData.product_types[0];
        getClassificationDetails({ variables: { product_type_id: id, granularity_minutes: 1 } });
        startPollingClassificationDetails(pollIntervalMs);

        const tmStart = subMinutes(new Date(), DASHBOARD_WINDOW_SIZE_MINUTES);
        getProductAggregates({ variables: { product_type_id: id, granularity_minutes: 1, tm_start: tmStart  } });
        startPollingProductAggregates(pollIntervalMs);

        return () => {
            productTypesStopPolling();
        }
    }, [productTypesData, productTypesStopPolling, getClassificationDetails, getProductAggregates, startPollingClassificationDetails, startPollingProductAggregates]);

    const [getProductMeasurements, productMeasurementsResult] = useLazyQuery(GET_PRODUCT_MEASUREMENTS, { pollInterval: pollIntervalMs });
    const {
        data: dataProductMeasurements,
        //loading: loadingProductMeasurements,
        //error: errorProductMeasurements,
        //refetch: refetchProductMeasurements,
        startPolling: startPollingProductMeasurements,
        //stopPolling: stopPollingProductMeasurements
    } = productMeasurementsResult;
    useEffect(() => {
        const measurement_prefix = 'dist_';
        const measurementTypeIds = (dataProductTypeMeasurements?.measurements || [])
            .filter(d => d?.measurement_type?.name?.startsWith(measurement_prefix))
            .map(d => d.measurement_type.id)
            ;
        const tmStart = subMinutes(new Date(), DASHBOARD_WINDOW_SIZE_MINUTES);
        const tmEnd = new Date();
        const measurementTypeIdsAsString = `{${measurementTypeIds.toString()}}`
        getProductMeasurements({ variables: { tm_start: tmStart,  tm_end: tmEnd, measurement_type_ids: measurementTypeIdsAsString   } });
        startPollingProductMeasurements(pollIntervalMs);

    }, [dataProductTypeMeasurements, getProductMeasurements, startPollingProductMeasurements])


    const tmStart = subMinutes(new Date(), DASHBOARD_WINDOW_SIZE_MINUTES);
    const setTimestamp = d => ({...d, tm: new Date(d.time_bucket)});
    const isInWindow = d => d.tm >= tmStart;
    const products = Object.values((dataProductAggregates?.products || [])
        .map(setTimestamp)
        .filter(isInWindow)
        .reduce((acc, d) => {
            const key = d.time_bucket;
            const oldData = acc[key];
            const newData = {
                ...(oldData || d),
                count_total: (oldData?.count_total || 0) + d.count_total,
                count_failed: (oldData?.count_failed || 0) + d.count_failed
            };
            return { ...acc, [key]: newData }
        }, {})
    );
    const classificationDetails = Object.values((dataClassificationDetails?.classificationDetails || [])
        .map(setTimestamp)
        .filter(isInWindow)
        .reduce((acc, d) => {
            const key = `${d.time_bucket}#${d.classification}#${d.result_id}`;
            const oldData = acc[key];
            const newData = {
                ...(oldData || d),
                count: (oldData?.count || 0) + d.count
            };
            return {
                ...acc,
                [key]: newData
            }
        }, {})
    );
    ;
    const productionData = mapToProductionData(products, classificationDetails);
    const productionWithoutGaps = fillGaps(productionData );
    const classifications = mapToClassificationData(products, classificationDetails);

    // example
    const toppingExample = { name: 'cheese', coverage: [0.6, 0.7, 0.9, 0.8] };
    // eslint-disable-next-line no-unused-vars
    const toppings2 = [toppingExample];
    const toppingsData = getToppingData(dataProductTypeMeasurements, dataProductMeasurements)
    const toppings = toppingsData.map(d => ({
        name: d.name,
        //first is overall
        coverage: d.quadrantValues.slice(1).map(v => v / d.target)
    }))
    return (
        <div>
            <Container name='Productivity'>
                <Productivity production={productionWithoutGaps} />
            </Container>
            <Container name='Quality'>
                <Quality classifications={classifications} />
            </Container>
            <Container name='Ingredients'>
                <Toppings toppings={toppings} />
            </Container>
        </div>
    )
}

export default Dashboard;
