import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface ProgressDeterminateProps extends CircularProgressProps {
  value: number;
  fontSize?: string | number;
  disableText?: boolean;
};
function ProgressDeterminate( { fontSize, disableText, ...props }: ProgressDeterminateProps) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!disableText &&
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            fontSize={fontSize}
          >{`${Math.round(props.value)}%`}</Typography>
        }
      </Box>
    </Box>
  );
}

function ProgressIndeterminate(props: CircularProgressProps) {
  return <CircularProgress variant="indeterminate" {...props} />;
}

export {
    ProgressDeterminate,
    ProgressIndeterminate,
};