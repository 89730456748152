import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles/createTheme';
import { useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell, Label, Legend } from 'recharts';

interface renderActiveShapeProps {
    cx?: number;
    cy?: number;
    innerRadius?: number;
    outerRadius: number;
    startAngle?: number;
    endAngle?: number;
    fill?: string;
}
const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }: renderActiveShapeProps) => {
    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    centerLabel: {
        fontFamily: theme.typography.fontFamily,
        color: 'lime',
        fill: (props: any) => props.color ?? 'black',
        [theme.breakpoints.up('sm')]: {
            fontSize: (props: any) => `min(7vw, ${props.maxHeight})`,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2em'
        }
    },
    legend: {
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'none',
    },
    legendActive: {
        fontFamily: theme.typography.fontFamily,
        textDecoration: 'underline',
    }
}));

//function Chart({ data, defaultText, dataKey = 'value', maxHeight = '2.6em', innerRadius = '60%', outerRadius = '80%', startAngle = 0, endAngle = 360, labelHoverValue, disableLegend, height }) {
interface ChartProps {
    data: any;
    defaultText?: string;
    dataKey?: string;
    maxHeight?: string;
    innerRadius?: string;
    outerRadius?: string;
    startAngle?: number;
    endAngle?: number;
    labelHoverValue?: (d: any) => string;
    disableLegend?: boolean;
    height?: string | number;
}
function Chart({ data, defaultText, dataKey = 'value', maxHeight = '2.0em', innerRadius = '60%', outerRadius = '80%', startAngle = 0, endAngle = 360, labelHoverValue, disableLegend, height }: ChartProps) {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const getColor = () => data[activeIndex || 0]?.color;
    const classes = useStyles({ color: getColor(), maxHeight });

    const total = data.reduce((s: number, d: any) => s + d[dataKey], 0);
    defaultText = defaultText ?? total.toLocaleString();

    const onMouseEnter = (_: any, index: number) => setActiveIndex(index);
    const onMouseLeave = () => setActiveIndex(null);

    const getLabelValue = () => {
        if (activeIndex === null)
            return defaultText;
        if (labelHoverValue)
            return labelHoverValue(data[activeIndex]);
        const value = (data && data[activeIndex] && data[activeIndex][dataKey]) || 0;
        return `${parseFloat((value / total * 100).toFixed(1)).toLocaleString()}%`
        //return `${parseFloat((data[activeIndex][dataKey] / total * 100).toFixed(0)).toLocaleString()}%`
    };

    const onLegendMouseEnter = (_: any, index: number) => setActiveIndex(index);
    const onLegendMouseLeave = () => setActiveIndex(null);
    const formatLegend = (text: string, _: any, index: number) => <span className={activeIndex === index ? classes.legendActive : classes.legend}>{text}</span>;
    return (
        <ResponsiveContainer height={height}>
            <PieChart>
                <Pie
                    activeIndex={activeIndex || undefined}
                    activeShape={renderActiveShape}
                    data={data}
                    dataKey={dataKey}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    animationDuration={600}
                >
                    <Label
                        width='100%'
                        position="center"
                        className={classes.centerLabel}
                    >
                        {getLabelValue()}
                    </Label>
                    {data.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                {!disableLegend &&
                    <Legend
                        onMouseEnter={onLegendMouseEnter}
                        onMouseLeave={onLegendMouseLeave}
                        formatter={formatLegend}
                        verticalAlign='bottom'
                        height={20}
                    />
                }
            </PieChart>
        </ResponsiveContainer>
    );
}


export default Chart;
