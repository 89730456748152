import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Measurements from './Measurements';
import ProductInfo from './ProductInfo';
import Results from './Results';
import Overlays from './Overlays';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '98%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Data({ data, listClassName, Header }){
  return (
    <>
    <Grid item xs={12} sm={6} md={6} lg={2} xl={2} style={{ height: '100%' }}>
      <ProductInfo Header={Header} className={listClassName} productInfo={data?.ProductInfo} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={2} xl={2} style={{ height: '100%' }}>
      <Measurements Header={Header} className={listClassName} measurements={data?.Measurements} />
    </Grid>
    <Grid item xs={12} sm={6} md={4} lg={2} xl={2} style={{ height: '100%' }}>
      <Results Header={Header} className={listClassName} results={data?.Results} />
    </Grid>
    </>
  );
}

function ImageData({ imageData, toggleOverlay, overlaysEnabled, showDebugOverlayTag, Header, overlayOpacity, setOverlayOpacity }) {
  const classes = useStyles();

  const overlays = imageData?.Overlays || {};
  const overlaysAndDebugTag = { ...overlays, [showDebugOverlayTag]: { Name: showDebugOverlayTag } };
  return <>
    <Data Header={Header} data={imageData?.Data} listClassName={classes.list} />
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{ height: '100%' }}>
      <Overlays
        Header={Header}
        className={classes.list}
        overlays={overlaysAndDebugTag}
        toggleOverlay={toggleOverlay}
        overlaysEnabled={overlaysEnabled}
        overlayOpacity={overlayOpacity}
        setOverlayOpacity={setOverlayOpacity}
      />
    </Grid>
  </>;
}

export default ImageData;