import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles/createTheme';
import { Grid } from '@mui/material';
import { ProgressDeterminate, ProgressIndeterminate } from './Progress';

type ThemeAddons = {
  headerColor: string;
  //fontFamily: string;
  //results: {
  //[key : string]: string;
  //}
};

const useStyles = makeStyles((theme: Theme & ThemeAddons) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: '0em'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '2em'
    }
  },
  containerHeader: {
    '&': {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
      //marginTop: '2em',
      marginBottom: 0,
      color: theme.headerColor,
      fontFamily: 'gotham',
      position: 'relative',
      fontSize: '2em',
      zIndex: 1,
      overflow: 'hidden',
      textAlign: 'center',
      fontWeight: 500,
    },
    '&:before, &:after': {
      position: 'absolute',
      top: '51%',
      overflow: 'hidden',
      width: '49.4%',
      height: '1px',
      content: '""',
      backgroundColor: theme.headerColor,
    },
    '&:after': {
      marginLeft: '0.4%',
    },
    '&:before': {
      marginLeft: '-49.9%',
      textAlign: 'right',
    }
  },
}));

interface DashboardRowProps {
  children: JSX.Element;
}
function DashboardRow({ children }: DashboardRowProps) {
  return (
    <Grid container spacing={0} direction="row" justifyContent="space-around" alignItems="center" style={{ minHeight: 'min(100vw,24vh)', width: '100%' }}>
      {React.Children.toArray(children)}
    </Grid>
  );
}

interface ContainerProps {
  name: string | JSX.Element;
  children: JSX.Element;
};
function Container({ name, children }: ContainerProps) {
  const classes = useStyles();
  return (
    <div>
      <h1 className={classes.containerHeader}>{name}</h1>
      <DashboardRow>
        {children}
      </DashboardRow>
    </div>
  )
}

interface IsLoading {
  progressPct?: number;
  isLoading?: boolean;
  isAborted?: boolean;
};


const generateHeaderWithProgress = (name: string | JSX.Element, { isLoading, progressPct }: IsLoading) => {
  if (!isLoading)
    return name;
  return <>{name} {typeof progressPct === 'undefined' ? <ProgressIndeterminate size='2rem' /> : <ProgressDeterminate disableText size='2rem' value={100 * (progressPct ?? 0)} />}</>
};

type GridProps = React.ComponentProps<typeof Grid>;
interface GridContainerProps extends GridProps, IsLoading {
  header: string | JSX.Element;
  children: JSX.Element;
  hideContentWhenLoading?: boolean;
};
function GridContainer({ header, hideContentWhenLoading, children, isLoading, isAborted, progressPct, ...props }: GridContainerProps) {
  const gridStyle = { marginTop: '2em', ...(props.style || {}) };
  const name = (!hideContentWhenLoading && (isLoading ?? false)) ? generateHeaderWithProgress(header, { isLoading, progressPct }) : header;
  const showLoading = hideContentWhenLoading && isLoading;
  return (
    <Grid item xs={12} style={gridStyle} {...props}>
      <Container name={name}>
        {showLoading ? (typeof progressPct === 'undefined' ? <ProgressIndeterminate /> : <ProgressDeterminate value={100 * (progressPct ?? 0)} />) : children}
      </Container>
    </Grid>
  );
}


export {
  GridContainer,
}