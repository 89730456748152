import React from "react";
// @mui/material components
//import { makeStyles } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";

//const useStyles = makeStyles();

export default function CustomInput({ labelText, id, onChange, value, type }) {
  //const classes = useStyles();

  return (
    <FormControl >
      {labelText && <InputLabel htmlFor={id}>{labelText}</InputLabel>}
      <Input
        id={id}
        onChange={onChange}
        value={value}
        type={type}
      />
    </FormControl>
  );
}
