import React from 'react';
import { format } from 'date-fns'
import {
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination, Skeleton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    loadingIcon: {
        marginLeft: '1em',
        minWidth: '1em',
    },
    header: {
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        fontSize: '1.0rem',
        marginTop: '1em',
        [theme.breakpoints.down('md')]: {
            '&': {
                textTransform: 'uppercase',
                letterSpacing: '0.2rem',
                marginTop: '1.5em',
                marginBottom: '0.5em',
                color: theme.headerColor,
                fontFamily: 'gotham',
                position: 'relative',
                fontSize: '1.5rem',
                zIndex: 1,
                overflow: 'hidden',
                textAlign: 'center',
                fontWeight: 500,
            },
            '&:before, &:after': {
                position: 'absolute',
                top: '51%',
                overflow: 'hidden',
                width: '49.4%',
                height: '1px',
                content: '""',
                backgroundColor: theme.headerColor,
            },
            '&:after': {
                marginLeft: '0.4%',
            },
            '&:before': {
                marginLeft: '-49.9%',
                textAlign: 'right',
            }
        }
    }
}));

function SecondaryOrderListItem({ time = new Date() }) {
    const formatDate = time => format(new Date(time), "dd/MM-yy HH:mm:ss");
    return (
        <>
            {formatDate(time)}
        </>
    );
}

function OrderListItem({ name, errors, onClick, isSelected, loadingOrder, ...props }) {
    const primaryText = !Array.isArray(errors) ? name : `${name} (${errors[0]?.type?.name || ''})`;
    return <ListItemText
        onClick={onClick}
        primary={primaryText}
        secondary={<SecondaryOrderListItem {...props} />}
    />;
}

function Header({ children }){
  const classes = useStyles();
  return (
    <ListSubheader component="div" id="nested-list-subheader">
      <Typography variant="h4" className={classes.header}>{children}</Typography>
    </ListSubheader >
  );
}

function OrderList({ orders, selectedOrder, orderCount, pageSize = orders.length, currentPage = 0, setPage, loadingOrders, loadingOrder, onClick, onRefresh }) {
    const classes = useStyles();
    const pageCount = Math.ceil(orderCount / pageSize);
    const isSameOrder = (a, b) => a?.id === b?.id;
    const handlePageChange = (ev, page) => setPage(page);
    //console.log({ orders })

    const uniqueOrders = orders.filter((d, i, arr) => arr.findIndex(d2 => d2.id === d.id) === i);

    return <>
        <List
            className={classes.root}
            subheader={
                <Header>
                    Orders
                    <IconButton onClick={onRefresh} color="primary" aria-label="Refresh" size="large">
                        <RefreshIcon />
                    </IconButton>
                </Header>}
        >
            {loadingOrders && Array(pageSize).fill().map((d, i) =>
                <ListItem key={i} button>
                    <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
                </ListItem>
            )}
            {!loadingOrders && uniqueOrders.map((d, i) => {
                const isSelected = isSameOrder(d, selectedOrder);
                return (
                    <ListItem key={i} selected={isSelected} button>
                        <OrderListItem
                            onClick={() => onClick(d)}
                            name={d.name}
                            errors={d.errors}
                            time={d.tm_start}
                            loadingOrder={loadingOrder}
                            isSelected={isSelected}
                        />
                        {isSelected && loadingOrder &&
                            <ListItemIcon classes={{ root: classes.loadingIcon }}>
                                <CircularProgress size={15} />
                            </ListItemIcon>
                        }
                    </ListItem>
                );
            }
            )}
        </List>
        <Pagination count={pageCount} page={currentPage} onChange={handlePageChange} />
    </>;
}

export default OrderList;

