import { gql } from '@apollo/client';

export const GET_PRODUCT_TYPES = gql`
    query GetProductTypes (
        $limit: Int = 10,
        $offset: Int,
        $order_by: [production_intelligence_product_types_order_by!] = [ { id: desc } ],
        $customer_id: Int! = 7,
        $line_id: Int! = 32
    ){
        product_types: production_intelligence_product_types  (
            limit: $limit,
            offset: $offset,
            order_by: $order_by,
            where: { customer_id: { _eq: $customer_id }, line_id: { _eq: $line_id } }
        ){
            id
            created_at
            name
        }
    }
`;

export interface ProductTypeCount {
    customer_id: number;
    line_id: number;
    product_type_id: number;
    product_type_name: string;
    count_total: number;
    count_failed: number;
    tm_first_product: Date;
    tm_last_product: Date;
    created_at: Date;
}

export interface ProductTypeCountResult {
    product_types?: Array<ProductTypeCount>;
}

export interface ProductTypeCountVars {
        limit?: number;
        offset?: number;
        order_by?: any;
        customer_id?: number;
        line_id?: number;
        tm_start: Date;
        tm_end?: Date;
}

export const GET_PRODUCT_TYPE_COUNTS = gql`
    query GetProductTypeCounts (
        $limit: Int = 1000
        , $offset: Int
        , $order_by: [production_intelligence_v_product_type_counts_order_by!] = [ { created_at: desc } ]
        , $customer_id: Int! = 7
        , $line_id: Int! = 32
        , $tm_start: timestamptz
        , $tm_end: timestamptz

    ){
        product_types: production_intelligence_v_product_type_counts  (
            limit: $limit,
            offset: $offset,
            order_by: $order_by,
            where: {
                customer_id: { _eq: $customer_id },
                line_id: { _eq: $line_id }
                created_at: { _gte: $tm_start, _lte: $tm_end }
                tm_first_product: { _gte: $tm_start, _lte: $tm_end }
            }
        ){
            customer_id
            line_id
            product_type_id
            product_type_name
            count_total
            count_failed
            tm_first_product
            tm_last_product
            created_at
        }
    }
`;
