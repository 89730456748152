
// Do not use array to make sure that index is same as in Inspector
type ClassificationType = {
    displayName: string;
    tag: string;
    hidden?: boolean;
};
interface ClassificationMapType {
    [key: number]: ClassificationType
};
const classifications : ClassificationMapType = {
    0: { displayName: 'Good', tag: 'ok' },
    1: { displayName: 'Heads up', tag: 'info' },
    2: { displayName: 'Warning', tag: 'warn' },
    3: { displayName: 'Critical', tag: 'fail' },
    4: { displayName: 'Not detected', tag: 'notDetected', hidden: true },
};

const classificationsDisplay : ClassificationMapType = Object.entries(classifications)
    .filter(([k, v]) => !v.hidden)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    ;

// Image data
const overlayTypes = {
    Info: { name: 'info' },
    Warn: { name: 'warning' },
    Fail: { name: 'failure' },
};

const drawerWidth = 240;

const localStorageKey = 'dashboardautosignin';
const localStorageUsernameKey = `${localStorageKey}username`;
const localStoragePasswordKey  = `${localStorageKey}password`;

const clearLocalStorage = () => {
    window.localStorage.removeItem(localStorageUsernameKey);
    window.localStorage.removeItem(localStoragePasswordKey);
}

export { 
    ClassificationType,
    classifications,
    classificationsDisplay,
    overlayTypes,
    drawerWidth,
    localStorageUsernameKey,
    localStoragePasswordKey,
    clearLocalStorage,
}