import * as constants from '../../constants'
import * as React from 'react';
import withTheme from '@mui/styles/withTheme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { alpha, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { ForeignObjects } from '../../api/foreignObjects'; 
import { ProgressDeterminate } from '../common/Progress';

const useStyles = makeStyles((theme : Theme) => {
    const tableBackgroundColor = '#777';
    return {
        table: {
            textTransform: 'uppercase',
            backgroundColor: tableBackgroundColor,
            color: theme.palette.getContrastText(tableBackgroundColor),
        },
        headerRow: {
            letterSpacing: '0.1em',
            textTransform: 'uppercase',
        },
        headerCell: {
            fontWeight: 'bold',
            color: theme.palette.getContrastText(tableBackgroundColor),
        },
        valueRow: {
        },
        objectTypeCol: {
            textTransform: 'capitalize',
        },
        classificationCol: {
            textTransform: 'uppercase',
        },
        countCol: {

        }
    }
});

interface ForeignObjectsTableProps {
    data: Array<ForeignObjects>;
    isLoading?: boolean;
    progressPct?: number;
    theme: any;
}
function ForeignObjectsTable({ data, isLoading, progressPct, theme } : ForeignObjectsTableProps) {
    const classes = useStyles();

    interface DisplayDataType extends constants.ClassificationType {
        classification_index: number;
        result_name: string;
        count: number;
        backgroundColor: string;
        textColor: string;
    };
    const displayData = data.reduce((acc: Array<DisplayDataType>, { classification_index, result_name, count }: ForeignObjects) : Array<DisplayDataType> => {
        const oldEntryIdx = acc.findIndex(old => old.classification_index === classification_index);
        const classification = constants.classifications[classification_index];
        const color = alpha(theme.results[classification.tag], 0.9);
        const oldEntry : DisplayDataType = ~oldEntryIdx ? acc[oldEntryIdx] : {
            ...classification,
            classification_index,
            result_name,
            count: 0,
            backgroundColor: color,
            textColor: theme.palette.getContrastText(color)
        };
        const newEntry : DisplayDataType = { ...oldEntry, count: oldEntry.count + count };
        if (~oldEntryIdx)
            return [...acc.slice(0, oldEntryIdx), newEntry, ...acc.slice(oldEntryIdx + 1)];
        else
            return [...acc, newEntry];
    }, [])
        .sort((lhs, rhs) => lhs.classification_index > rhs.classification_index ? -1 : 1);

    if (isLoading)
        return <ProgressDeterminate fontSize='3rem' size='10rem' value={100 * (progressPct ?? 0)} />;

    return (
        <TableContainer component={Paper}>
            <Table sx={{}} aria-label="simple table" className={classes.table}>
                <TableHead>
                    <TableRow className={classes.headerRow}>
                        <TableCell className={classes.headerCell}>Classification</TableCell>
                        <TableCell className={classes.headerCell}>Object type</TableCell>
                        <TableCell className={classes.headerCell} align="right">Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayData.map((row) => (
                        <TableRow
                            key={`${row.result_name}_${row.classification_index}`}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: row.backgroundColor,
                                }}
                            className={classes.valueRow}
                        >
                            <TableCell className={classes.classificationCol}>
                                <Typography style={{ color: row.textColor }}>{row.displayName}</Typography>
                            </TableCell>
                            <TableCell className={classes.objectTypeCol}>
                                <Typography style={{ color: row.textColor }}>{row.result_name}</Typography>
                            </TableCell>
                            <TableCell align="right" className={classes.countCol}>
                                <Typography style={{ color: row.textColor }}>{row.count.toLocaleString()}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default withTheme(ForeignObjectsTable);