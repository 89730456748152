import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_EVENTS } from '../../api/events';
import { DataSelectionContext, usePageTitle } from '../../contexts';
import { Grid, List, ListItem, ListItemText, ListSubheader, Pagination, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withTheme from '@mui/styles/withTheme';
import { formatDistance, format, startOfDay, addDays, differenceInMinutes  } from 'date-fns';
import { formatInterval, percentageToString } from '../../helpers';
import Filters from './Filters';
import LogTable from './LogTable';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginTop: '0em'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '2em'
    }
  },
  header: {
    textTransform: 'uppercase',
    letterSpacing: '0.2rem',
    fontSize: '1.0rem',
    marginTop: '1em',
    [theme.breakpoints.down('md')]: {
      '&': {
        textTransform: 'uppercase',
        letterSpacing: '0.2rem',
        marginTop: '1.5em',
        marginBottom: '0.5em',
        color: theme.headerColor,
        fontFamily: 'gotham',
        position: 'relative',
        fontSize: '1.5rem',
        zIndex: 1,
        overflow: 'hidden',
        textAlign: 'center',
        fontWeight: 500,
      },
      '&:before, &:after': {
        position: 'absolute',
        top: '51%',
        overflow: 'hidden',
        width: '49.4%',
        height: '1px',
        content: '""',
        backgroundColor: theme.headerColor,
      },
      '&:after': {
        marginLeft: '0.4%',
      },
      '&:before': {
        marginLeft: '-49.9%',
        textAlign: 'right',
      }
    }
  },
  list: {
    width: '98%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  productivityText: {
    fontFamily: theme.fontFamily,
    color: theme.headerColor,
    fontWeight: 500,
    fontSize: 'min(10vw, 2em)'
  }
}));


function Events({ theme, history }){
  const classes = useStyles();
  const { dateRange } = useContext(DataSelectionContext);
  const { startDate, endDate } = dateRange.value;
  const [shownEventTypes, setShownEventTypes] = useState([]);
  const [hiddenEventTypes, setHiddenEventTypes] = useState([]);

  usePageTitle('Events');

  const pageSize = 25;
  const [page, setPage] = useState(1);

  const { data: dataEvents, error: errorEvents, refetch: refetchEvents } = useQuery(GET_EVENTS, {
    variables: {
      limit: pageSize,
      offset: pageSize * (page - 1),
      tmStart: startDate,
      tmEnd: endDate,
      order_by: [{ time: 'desc' }]
    }
  });


  if (errorEvents)
    console.warn(errorEvents);

  const totalNumberOfEvents = dataEvents?.eventAggregates?.aggregate?.count;
  const pageCount = Math.ceil(totalNumberOfEvents / pageSize);
  const events = dataEvents?.events || [];
  useEffect(() => {
    if (events.length === 0)
      return;
    const availableTypes = events.map(d => d?.type?.name).filter((d, i, arr) => d && arr.indexOf(d) === i).sort();
    const oldTypes = [...shownEventTypes, ...hiddenEventTypes];
    const newTypes = availableTypes.filter(d => !oldTypes.includes(d));
    const newShownEventTypes = [...shownEventTypes.filter(d => availableTypes.includes(d)), ...newTypes].sort();
    const newHiddenEventTypes = hiddenEventTypes.filter(d => availableTypes.includes(d));

    setShownEventTypes(newShownEventTypes);
    setHiddenEventTypes(newHiddenEventTypes);
  }, [dataEvents, setHiddenEventTypes, setShownEventTypes])

  const updateFilter = (name, enable) => {
    if (enable){
      setShownEventTypes([...shownEventTypes, name].sort());
      setHiddenEventTypes(hiddenEventTypes.filter(d => d !== name));
    }
    else{
      setHiddenEventTypes([...hiddenEventTypes, name].sort());
      setShownEventTypes(shownEventTypes.filter(d => d !== name));
    }
  };
  const handlePageChange = (ev, page) => setPage(page);
  const shownEvents = events.filter(ev => shownEventTypes.includes(ev?.type?.name))
  return (
    <div className={classes.container}>
      <Filters enabled={shownEventTypes} disabled={hiddenEventTypes} onClick={updateFilter} />
      <LogTable data={shownEvents} />
      <Pagination count={pageCount} page={page} onChange={handlePageChange} />
    </div>
  );
}

export default withTheme(Events);
