import React, { useCallback, useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { format, subDays } from 'date-fns';
import { DataSelectionContext, usePageTitle } from '../../contexts';
import { GET_PRODUCT_TYPE_COUNTS, ProductTypeCount, ProductTypeCountResult, ProductTypeCountVars } from '../../api/productTypes';
import ProductTypeTable, { HeadCell } from '../common/Table';
import { ProgressDeterminate, ProgressIndeterminate } from '../common/Progress';
import CheckIcon from '@mui/icons-material/Check';
import ProductTypeDetails from './ProductTypeDetails';

interface ProductType {
    id: number;
    name: string;
    tm_start: Date;
    tm_end: Date;
}

interface ProductTypeDetailsLoadingState {
    name: string;
    isLoading?: boolean;
    isLoaded?: boolean;
    progressPct?: number;
}

interface ProductTypeCountLocal extends ProductTypeCount {
    count_product_types: number;
    error_rate_pct: string;
    product_type_ids: Array<number>;
    product_types: Array<ProductType>;
    productTypeNameAndLoadingIndicator?: string | JSX.Element;
}

const HEAD_CELLS : Array<HeadCell<ProductTypeCountLocal>> = [
    { id: 'productTypeNameAndLoadingIndicator', label: 'Name', numeric: false, disablePadding: false },
    { id: 'count_product_types', label: 'Productions', numeric: true, disablePadding: false },
    { id: 'count_total', label: 'Products', numeric: true, disablePadding: false },
    { id: 'count_failed', label: 'Rejected products', numeric: true, disablePadding: false },
    { id: 'error_rate_pct', label: 'Rejection rate', numeric: true, disablePadding: false },
];

function groupByName(productTypes : Array<ProductTypeCount>, loadingStates: Array<ProductTypeDetailsLoadingState>){
    return productTypes.reduce((acc: Array<ProductTypeCountLocal>, productType: ProductTypeCount) => {
        const existingProductTypeIdx = acc.findIndex(productTypeCmp => productTypeCmp.product_type_name === productType.product_type_name);
        const existingProductType : ProductTypeCountLocal = ~existingProductTypeIdx ? acc[existingProductTypeIdx] : { ...productType, count_total: 0, count_failed: 0, count_product_types: 0, error_rate_pct: '0.0', product_type_ids: [], product_types: [] };
        const tmFirstProduct = new Date(productType.tm_first_product);
        const tmLastProduct = new Date(productType.tm_last_product);
        const name = productType.product_type_name;
        const loadingState = loadingStates.find(d => d.name === name);
        const isLoaded = loadingState?.isLoaded ?? false;
        const isLoading = loadingState?.isLoading ?? false;
        const progressPct = loadingState?.progressPct ?? 0;
        const icon = isLoading ? <ProgressDeterminate value={100 * progressPct} disableText style={{ marginLeft: '1rem'}} size='1rem' /> : ( isLoaded ? <CheckIcon /> : <> </>  )

        const updatedProductType : ProductTypeCountLocal = {
            ...existingProductType,
            tm_first_product: existingProductType.tm_first_product < tmFirstProduct ? existingProductType.tm_first_product : tmFirstProduct,
            tm_last_product: existingProductType.tm_last_product > tmLastProduct ? existingProductType.tm_last_product : tmLastProduct,
            count_total: existingProductType.count_total + productType.count_total,
            count_failed: existingProductType.count_failed + productType.count_failed,
            count_product_types: existingProductType.count_product_types + 1,
            product_type_ids: [...existingProductType.product_type_ids, productType.product_type_id],
            product_types: [...existingProductType.product_types, { id:  productType.product_type_id, tm_start: productType.tm_first_product, tm_end: productType.tm_last_product, name: productType.product_type_name }],
            productTypeNameAndLoadingIndicator: <>{productType.product_type_name}{icon} </>
        };
        updatedProductType.error_rate_pct = `${(100 * updatedProductType.count_failed / updatedProductType.count_total).toFixed(1)}%`;

        return ~existingProductTypeIdx ? [...acc.slice(0, existingProductTypeIdx), updatedProductType, ...acc.slice(existingProductTypeIdx + 1)] : [...acc, updatedProductType];
    }, [])
}

function ProductTypes() {
    const [selectedProductType, setSelectedProdutType] = useState<ProductTypeCountLocal | null>(null);
    const [productTypeDetailsLoadingStates, setProductTypeDetailsLoadingStates] = useState<Array<ProductTypeDetailsLoadingState>>([]); 
    const [data, setData] = useState<ProductTypeCountLocal | null>(null);
    const { dateRange } = useContext(DataSelectionContext);
    const { startDate, endDate } = dateRange ? dateRange.value : { startDate: new Date(), endDate: new Date() };
    const selectedProductTypeSuffix = selectedProductType ? ` / ${selectedProductType.product_type_name}` : ''
    const title = `Product types /  ${ startDate ? format(startDate, 'd. MMMM yyyy') : ''} .. ${endDate ? format(endDate, 'd. MMMM yyyy') : ''} ${selectedProductTypeSuffix}`;
    usePageTitle(title);
    const { data: dataProductTypes, error: errorProductTypes, loading: loadingProductTypes } = useQuery<ProductTypeCountResult, ProductTypeCountVars>(GET_PRODUCT_TYPE_COUNTS, {
        variables: {
            limit: 1000,
            tm_start: startDate ?? subDays(new Date(), 3),
            tm_end: endDate,
            order_by: { created_at: 'desc' }
        },
    });
    React.useEffect(() => {
        setProductTypeDetailsLoadingStates([]);
        setData(null);
    }, [dataProductTypes]);
    const groupedProductTypes = React.useMemo(() => groupByName(dataProductTypes?.product_types || [], productTypeDetailsLoadingStates), [dataProductTypes, productTypeDetailsLoadingStates]);

    const handleProductTypeSelectionChanged = useCallback((productTypes : Array<ProductTypeCountLocal>)  => {
        const productType = productTypes.length === 0 ? null : productTypes[0];
        setSelectedProdutType(productType);
        setData(productType);
        if (productType){
            if (!productTypeDetailsLoadingStates.some(d => d.name === productType.product_type_name))
                setProductTypeDetailsLoadingStates([...productTypeDetailsLoadingStates, { name: productType.product_type_name, isLoading: true }]);
        }
    }, [setSelectedProdutType, setProductTypeDetailsLoadingStates, productTypeDetailsLoadingStates]);

    const handleDetailsLoaded = useCallback((productTypeId : number, progressPct : number) => {
        const productTypes = dataProductTypes?.product_types || [];
        const productType = productTypes.find(d => d.product_type_id === productTypeId);
        if (!productType)
            return;
        const name = productType.product_type_name;
        const index = productTypeDetailsLoadingStates.findIndex(d => d.name === name);
        const isLoaded = progressPct === 1;
        const isLoading = progressPct < 1;
        const updatedState: ProductTypeDetailsLoadingState = { name, isLoaded, progressPct, isLoading };
        const updatedStates : Array<ProductTypeDetailsLoadingState> = !~index ? [...productTypeDetailsLoadingStates, updatedState] : [...productTypeDetailsLoadingStates.slice(0, index), updatedState, ...productTypeDetailsLoadingStates.slice(index + 1)];
        setProductTypeDetailsLoadingStates(updatedStates);
    }, [dataProductTypes, setProductTypeDetailsLoadingStates, productTypeDetailsLoadingStates]);

    if (errorProductTypes)
        console.error({ errorProductTypes })
    return (
        <div>
            {loadingProductTypes &&
                <div>
                    <h3>
                        Loading product types for { startDate ? format(startDate, 'd. MMMM yyyy') : ''} .. {endDate ? format(endDate, 'd. MMMM yyyy') : ''}
                    </h3>
                    <ProgressIndeterminate />
                </div>
            }
            {errorProductTypes && <h3>Error loading product types...</h3>}
            {!loadingProductTypes &&
                <ProductTypeTable
                    rows={groupedProductTypes}
                    getIdentifier={productTypeCountLocal => productTypeCountLocal.product_type_name}
                    onClick={handleProductTypeSelectionChanged}
                    headCells={HEAD_CELLS}
                />
            }
            <ProductTypeDetails productTypes={data?.product_types ?? []} tmFirstProduct={data?.tm_first_product} tmLastProduct={data?.tm_last_product} onDetailsLoaded={handleDetailsLoaded}  />
        </div>
    )
}

export default ProductTypes;