import jsrsasign from "jsrsasign";
import { reload } from '../helpers';

const authServerUrl = 'https://auth.trivision.dk/auth/realms/master/protocol/openid-connect';
const tokenUrl = `${authServerUrl}/token`;
const logoutUrl = `${authServerUrl}/logout`;
const clientId = 'tv_pi_frontend_users';

type ParsedClaims = {  name?: string, exp?: number, trivision?: { 'x-trivision-customers'?: object} };
const decodeToken = (rawToken : string) : ParsedClaims | null  => {
    const [ headerBase64, payloadBase64, /*signatureBase64*/ ] = rawToken.split(".");
    const header = jsrsasign.b64utos(headerBase64);
    const claims  = jsrsasign.b64utos(payloadBase64);
    const parsedHeader : { typ?: string } | null = jsrsasign.KJUR.jws.JWS.readSafeJSONString(header);
    const parsedClaims : ParsedClaims | null = jsrsasign.KJUR.jws.JWS.readSafeJSONString(claims);
    if (parsedHeader && parsedHeader.typ !== 'JWT')
        return null;
    return parsedClaims;
};

type Customer = {
    productionLines: object;
}
type CustomerInfo = Array<any>;
const getCustomerInfo = (decodedToken: ParsedClaims) : CustomerInfo | null => {
    const { trivision } = decodedToken;
    if (!trivision)
        return null;
    const customers = trivision['x-trivision-customers'];
    if (!customers)
        return null;
    // customers is an object, so transform to array, i.e. { 0: { name: 'a', ...}, 1: { name: 'b', ...}, 2: { name: 'c', ...}} => [ { name: 'a', ...}, { name: 'b', ...}, { name: 'c', ...}]
    return Object.values(customers);
};

const getExpirationTime = (decodedToken: ParsedClaims) : Date => {
    const { exp } = decodedToken;
    return new Date((exp ?? 0) * 1000);
};

export type DecodedToken = {
    accessToken: string;
    refreshToken: string;
    customerInfo: CustomerInfo | null;
    name?: string;
    tokenExpirationTime: Date;
    refreshTokenExpirationTime: Date | null;
};
const decodeLoginResponse = async (response : any) : Promise<DecodedToken | null> => {
        const success = response && response.ok && response.status === 200;
        if (!success){
            reload('Authentication failed', await response.json());
            return null;
        }
        try {
            const { access_token: accessToken, refresh_token: refreshToken } = await response.json();
            const decodedToken = decodeToken(accessToken);
            if (!decodedToken)
                return null;
            const { name } = decodedToken;
            const customerInfo = getCustomerInfo(decodedToken);
            const tokenExpirationTime = getExpirationTime(decodedToken);

            const decodedRefreshToken = decodeToken(refreshToken);
            const refreshTokenExpirationTime = decodedRefreshToken ? getExpirationTime(decodedRefreshToken) : null;
 
            return {
                accessToken,
                refreshToken,
                customerInfo,
                name,
                tokenExpirationTime,
                refreshTokenExpirationTime
            };
        }
        catch(err){
            console.error('Authentication failed', err);
            return null;
        }

}

interface CallServerPostInput {
    url: string;
    body?: string;
    headers?: HeadersInit;
};
type CallServerPostOutput = Promise<DecodedToken | null>;
const callServerPost = async ({url, body = '', headers = {}} : CallServerPostInput ) : CallServerPostOutput  => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: `client_id=${clientId}${body.length > 0 ? '&' : ''}${body}`,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...headers},
            //credentials: 'include'
        });
        const decoded = await decodeLoginResponse(response);
        return decoded;
    }
    catch(err){
        console.error('Failed to get response from server: ', err);
    }
    return null;
}

const authenticate = (body: string) : CallServerPostOutput => callServerPost({ url: tokenUrl, body });
export const refresh = (refreshToken : string) => authenticate(`refresh_token=${refreshToken}&grant_type=refresh_token`);
export const login = (username: string, password: string) => authenticate(`password=${password}&username=${username}&grant_type=password`);

export const logout = async (tokenInfo: DecodedToken) => {
    // TODO: terminating session does not seem to work with the current call...
    if (true)
        return;
    if (!tokenInfo)
        return console.error('Cannot logout without any token');
    const { refreshToken, accessToken } = tokenInfo;
    const body = `refresh_token=${refreshToken}`;
    const headers = { Authorization: `Bearer ${accessToken}` }
    return callServerPost({ url: logoutUrl, body, headers})
};
