import { gql } from '@apollo/client';


export interface ForeignObjects {
    time_min: Date;
    time_max: Date;
    classification_index: number;
    result_name: string;
    count: number;
}

export interface ForeignObjectsResult {
    foreignObjects?: Array<ForeignObjects>;
}
export interface GetForeignObjectsVars {
        tm_start: Date;
        tm_end: Date;
        limit?: number;
        offset?: number;
}

export const GET_FOREIGN_OBJECTS = gql`
query GetForeignObjects (
    $tm_start: timestamptz,
    $tm_end: timestamptz,
    $limit: Int,
    $offset: Int
    ){
  foreignObjects: production_intelligence_get_foreign_objects(
    args: {
        _tm_start: $tm_start,
        _tm_end: $tm_end,
        _limit: $limit,
        _offset: $offset,
    }){
    time_min
    time_max
    classification_index
    result_name
    count
  }
}
`

