import { createContext, useContext, useEffect } from 'react';
import { DecodedToken } from './authentication/authentication'

export type AuthenticationContextType = {
    token?: DecodedToken;
    isAuthenticated?: boolean;
}
export const AuthenticationContext = createContext<AuthenticationContextType>({});

export type TitleContextType = {
  setPageTitle?: (c: string) => void
}

export const TitleContext = createContext<TitleContextType>({});
export const usePageTitle = (newPageTitle: string) => {
    const { /*pageTitle: oldPageTitle,*/ setPageTitle } = useContext(TitleContext)
    useEffect(() => {
        setPageTitle?.(newPageTitle);
        //return () => console.log(`Resetting title to ${oldPageTitle}`) || setPageTitle(oldPageTitle);
    });
}

export type DateRangeType = {
    startDate?: Date;
    endDate?: Date;
};

export type DateRangeSetAndValueType = {
    set: (d: DateRangeType) => void;
    value: DateRangeType;
};

export type DataContextType = {
 dateRange: DateRangeSetAndValueType ; 
};

export const DataSelectionContext = createContext<DataContextType>({dateRange: { set: d => {}, value: {}}});