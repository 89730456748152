import React, { useEffect } from 'react';
import { format } from 'date-fns'
import {
    Badge,
    Button,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Pagination, Skeleton } from '@mui/material';
//import { CircularProgress } from '@mui/material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    loadingImageIcon: {
        marginLeft: '1em',
        minWidth: '1em',
    },
}));

function ImageAvailable({ loading, available }) {
    const getColor = () => {
        if (loading)
            return 'default';
        return available ? 'primary' : 'error';
    }
    return (
        <Badge color={getColor()} variant="dot">
            <PhotoLibraryIcon fontSize='small' />
        </Badge>
    );
}

function SecondaryProductListItem({ time, imageInfo, loadingImageList }) {
    const formatDate = time => format(new Date(time), "dd/MM-yy HH:mm:ss");
    return (
        <>
            {formatDate(time)}
            <ImageAvailable loading={loadingImageList} available={!!imageInfo} />
        </>
    );
}

function ProductListItem({ productType, lane, errors , onClick, isSelected, loadingImage, ...props }) {
    //const errorText = (errors || []).reduce((acc, d) => `${acc}${acc.length > 0 ? ', ' : ''}${d.type.name}`, '')
    //const productTypeName = productType?.name || '';
    //const primaryText = `${productTypeName} (${errorText})`;
    const primaryText = `Lane ${lane}`;

    return <ListItemText
        onClick={onClick}
        primary={primaryText}
        secondary={<SecondaryProductListItem {...props} />}
    />;
}

function ProductList({ products, selectedProduct, productCount, pageSize, currentPage, setPage, loadingProducts, loadingImageList, loadingImage, onClick, Header, onRefresh, loadMoreProducts, canLoadMoreProducts }) {
    const classes = useStyles();
    const pageCount = Math.ceil(productCount / pageSize);
    const isSameProduct = (a, b) => a?.imageInfo?.imagePath === b?.imageInfo?.imagePath;
    const handlePageChange = (ev, page) => setPage(page);

    const isLastPage = currentPage === pageCount;
    useEffect(() => {
      if (currentPage > pageCount)
        setPage(Math.max(1,pageCount));
    }, [currentPage, pageCount, setPage]);

    return <>
        <List
            className={classes.root}
            subheader={
                <Header>
                    Products
                    {onRefresh &&
                        <IconButton onClick={onRefresh} color="primary" aria-label="Refresh" size="large">
                            <RefreshIcon />
                        </IconButton>
                    }
                </Header>}
        >
            {loadingProducts && Array(pageSize).fill().map((d, i) =>
                <ListItem key={i} button>
                    <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
                </ListItem>
            )}
            {!loadingProducts && products.map((d, i) => {
                const isSelected = isSameProduct(d, selectedProduct);
                return (
                    <ListItem key={i} selected={isSelected} button>
                        <ProductListItem
                            onClick={() => onClick(d)}
                            lane={d?.lane}
                            productType={d?.product?.type}
                            errors={d?.product?.errors}
                            time={d.time}
                            imageInfo={d.imageInfo}
                            loadingImageList={loadingImageList}
                            loadingImage={loadingImage}
                            isSelected={isSelected}
                        />
        {isSelected && loadingImage &&
            <ListItemIcon classes={{ root: classes.loadingImageIcon }}>
                <CircularProgress size={15} />
            </ListItemIcon>
        }
                    </ListItem>
                );
            }
            )}
        </List>
        { isLastPage && canLoadMoreProducts && loadMoreProducts &&
        <div>
            <Button onClick={loadMoreProducts}>Load more...</Button>
        </div> }
        <Pagination count={pageCount} page={currentPage} onChange={handlePageChange} />
    </>;
}

export default ProductList;

