import { List, ListItem, ListItemText } from "@mui/material";

function MeasurementEntry({ name, precision, unit, value }) {
  const getValue = () => {
    if (name === 'classification'){
      if (value > 2)
        return 'Critical';
      if (value > 1)
        return 'Warning';
      if (value > 0)
        return 'Heads up';
      return 'OK';
    }
    return value;
  }
  return (
    <ListItem>
      <ListItemText primary={getValue()} secondary={name} />
    </ListItem>
  );
}

function Measurements({ measurements, className, Header}) {
  const ignoredMeasurements = ['ImageWidth', 'ImageHeight'];
  const ignoredPrefix = ['dist_'];
  const cleanedMeasurements = Object.entries(measurements || {})
    .filter(([k, v]) => !ignoredMeasurements.includes(v.Name))
    .filter(([k, v]) => !ignoredPrefix.some(prefix => v.Name.startsWith(prefix)))
    ;
  return (
    <List
      className={className}
      subheader={<Header>Measurements</Header>}
    >
      {cleanedMeasurements.map(([k, v]) => <MeasurementEntry key={k} name={v.Name} precision={v.Precision} unit={v.Unit} value={v.Value} />)}
    </List>
  );
}

export default Measurements;