import { Grid } from "@mui/material";
import withTheme from '@mui/styles/withTheme';
import { classifications } from "../../../constants"
import AreaChart from '../../charts/AreaChart';
import GaugeChart from "../../charts/GaugeChart";
import differenceInMinutes from 'date-fns/differenceInMinutes'
import format from "date-fns/format";
import { Header } from '../Dashboard/containers';

function Productivity({ theme, production }){
    const totalProductCount = production.reduce((s, d) => s + d.count_total, 0);
    const productionStartEnd = production.reduce((acc, d) => ({
        start: acc.start ? Math.min(acc.start, d.tm) : d.tm,
        end: acc.end ? Math.max(acc.end, d.tm) : d.tm,
    }), {});
    const productionTimeMinutes = differenceInMinutes(productionStartEnd.end, productionStartEnd.start);
    const avgProductsPerMinute = totalProductCount / (productionTimeMinutes || 1);

    const capacityProductsPerMinute = 120;
    const productivity = 100 * avgProductsPerMinute / capacityProductsPerMinute;

    const classificationTags =  Object.values(classifications).filter(d => !d.hidden);

    const data = production.map(d => ({
        ...d,
        tm: d.tm.getTime()
    }))
    const dataKeys = [classificationTags.map(({ displayName, tag }) => ({
        name: displayName,
        key: tag,
        color: theme.results[tag]
    }))];
    const maxValue = data.map(d => d.count_total).filter(d => isFinite(d) && (d | 0) === d).reduce((m, d) => d > m ? d : m, 0);
    const maxDomain = maxValue < 10 ? maxValue + 1 :
        ( maxValue < 100 ? Math.floor(maxValue/10.0)*10 + 10 :
        ( maxValue < 300 ? Math.floor(maxValue/10.0)*10 + 30 : 
                           Math.floor(maxValue/100.0)*100 + 100));
    const domain = [0, maxDomain];
    return (
        <>
            <Grid item xs={12} sm={12} md={4}>
                <div style={{ width: 'min(100vw,95%)', height: 'min(90vw,25vh)' }}>
                    <GaugeChart value={productivity} />
                </div>
                <Header>
                    {Math.round(avgProductsPerMinute).toLocaleString()} Products/min
                </Header>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <div style={{ width: 'min(100vw,95%)', height: 'min(100vw,300px)' }}>
                    <AreaChart
                        data={data}
                        yAxisDomain={domain}
                        dataKeys={dataKeys}
                        tickFormatter={d => format(d, 'HH:mm')}
                        tooltipLabelFormatter={(value, name) => format(new Date(value), 'HH:mm')}
                        tooltipFormatter={(value, tag, c) => [value, classificationTags.find(d => d.tag === tag)?.displayName || tag]}
                        disableLegend
                        disableAnimation
                        disableTooltip={false}
                        scale='time'
                        type='number'
                    />
                </div>
            </Grid>
        </>
    )
}

export default withTheme(Productivity);
