//const imageBaseUrl = 'https://q61mo20j3k.execute-api.eu-west-1.amazonaws.com/dev/customer-data';
//const imageBaseUrl = 'https://t42yo2fts9.execute-api.eu-west-1.amazonaws.com/dev/customer-data/orkla/stranda/line2/2021/07/reduced/20210727_134118_707_lane0_ImageTopHyperspectral.jpeg';
const imageBaseUrl = 'https://t42yo2fts9.execute-api.eu-west-1.amazonaws.com/dev/customer-data';
const imageListUrl = 'https://rixgct84d9.execute-api.eu-west-1.amazonaws.com/dev/images';

//export const getImageList = async (token, { nextContinuationToken, maxKeys, customer, productionLine, date, fullSize } = {} ) => {
export const getImageList = async (token, params = {} ) => {
    const url = new URL(imageListUrl)
    //const params = { nextContinuationToken, maxKeys, customer, productionLine, date, fullSize };
    //var params = { lat: 35.696233, long: 139.570431 } // or:
    //var params = [['lat', '35.696233'], ['long', '139.570431']]
    url.search = new URLSearchParams(params).toString();

    const result = await fetch(url, {
        method: 'GET',
        headers: new Headers({
            token
        })
    });
    const json = await result.json();
    return json.images;
};

export const getImage = async (token, path) => {
    const url = `${imageBaseUrl}/${path}`;
    const result = await fetch(url, {
        method: 'GET',
        headers: new Headers({
            'X-Amz-Security-Token': token
        }),
    });
    const blob = await result.blob();
    return blob;
}

export const getImageData = async (token, path) => {
    const url = `${imageBaseUrl}/${path}`;
    const result = await fetch(url, {
        method: 'GET',
        headers: new Headers({
            'X-Amz-Security-Token': token
        }),
    });
    // JSON.parse cannot handle trailing commas...
    const text = await result.text();
    const json = JSON.parse(text.replace(/,[ \t]*}/g, '}'));
    return json;

}