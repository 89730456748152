import React, { useEffect, useState } from 'react';
import { useApolloClient, ApolloClient, QueryOptions, OperationVariables, DocumentNode } from '@apollo/client';
import { addMinutes, differenceInDays, format, subMinutes } from 'date-fns';
import { Button, Grid } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import withTheme from '@mui/styles/withTheme';

import { GET_PRODUCTS_AGGREGATE, ProductsAggregate } from '../../api/products';
import { GET_CLASSIFICATION_DETAILS, ClassificationDetails } from '../../api/classifications';
import { GET_FOREIGN_OBJECTS, ForeignObjects } from '../../api/foreignObjects'; 
import { GET_PRODUCT_MEASUREMENTS, ProductMeasurement  } from '../../api/measurements'; 
import { GET_PRODUCT_TYPE_MEASUREMENTS , ProductTypeMeasurement  } from '../../api/measurements'; 

import { GridContainer } from '../common/Containers';
import * as constants from "../../constants"
import AreaChart from '../charts/AreaChart';
import Quality, { ClassificationType } from '../pizza/Dashboard2/Quality';
import ForeignObjectsTable from '../orders/ForeignObjectsTable';
import Toppings from '../pizza/Dashboard/Toppings';
import { formatInterval } from '../../helpers';

type ProductsAggregateWithTm = ProductsAggregate & { tm: Date };
type ClassificationDetailsWithTm = ClassificationDetails & { tm: Date };

interface IsLoading {
    progressPct?: number;
    isLoading?: boolean;
    isAborted?: boolean;
};
interface RequestMultiDataResponse<T> extends IsLoading  {
    data: Array<T>;
}

function mapToClassificationData(products : Array<ProductsAggregate>, classificationDetails : Array<ClassificationDetails>) : Array<ClassificationType> {
    const resultTypes = classificationDetails.map(d => d.result_name).filter((d, i, arr) => arr.indexOf(d) === i);
    const grouped = Object.entries(constants.classificationsDisplay).map(([classificationIndex, { tag }] : [string,  { tag : string}]) => {
        const results = resultTypes.map(resultType => {
            const count = classificationDetails
                .filter(d => {
                    const isSameResultName = d.result_name === resultType;
                    const isSameClassificationIndex =  d.classification_index === parseInt(classificationIndex);
                    return isSameResultName && isSameClassificationIndex;
                })
                .reduce((sum, d) => sum + (d.count || 0), 0);
            return { name: resultType, count };
        });
        const count = results.reduce((sum, d) => sum + d.count, 0);
        return { classification: tag, results, count };
    });
    const countOk = products.map(d => d.count_total - d.count_failed).reduce((sum, d) => sum + d, 0);
    const classificationOk = Object.entries(constants.classificationsDisplay).find(([classificationIndex, { tag }]) => tag === 'ok');
    // Object.entries(constants.classificationsDisplay) : Array<[string, any]>
    if (classificationOk){
        const indexOk : any = classificationOk[0]
        grouped[indexOk].count = countOk;
    }
    return grouped;
}

function mapToProductionData(products : Array<ProductsAggregateWithTm>, classificationDetails : Array<ClassificationDetailsWithTm>) {
    const classificationTags = Object.values(constants.classificationsDisplay).map((d : constants.ClassificationType) => d.tag);
    const inputData = products.map(d => {
        const classificationInput = classificationDetails
            .filter(c => c.time_bucket === d.time_bucket)
            .map(c => ({ ...c, classification: classificationTags[c.classification_index] }))
        ;
        const classificationData = classificationTags.reduce((acc : {[key : string] : number}, t) => ({
            ...acc,
            [t]: (acc[t] || 0) + classificationInput.filter(c => c.classification === t).reduce((a, c) => a + c.count, 0)
        }), { ok: d.count_total - d.count_failed })
        return { ...d, ...classificationData }
    })
    return inputData;
}


type RequestType<T> = {
    apolloClient: ApolloClient<object>;
    productTypes: Array<ProductType>;
    // called each time a product type request is completed, only with the incoming data
    setPartialData?: (data: RequestMultiDataResponse<T>, productType?: ProductType) => void;
    // called each time a product type request is completed, with the accumulated data set (current + all previous data)
    setAccumulatedData?: (data: RequestMultiDataResponse<T>, productType?: ProductType) => void;
    extractData: (queryResult: any) => Array<T>;
    isAborted: () => boolean;
    requestDone?: () => void;
    generateQueryParams: (productType: ProductType) => QueryOptions<OperationVariables, any>;
}
async function request<T>({ apolloClient, productTypes, setAccumulatedData, setPartialData, extractData, isAborted, requestDone, generateQueryParams } : RequestType<T>) {
    let data: Array<T> = [];
    setPartialData?.({ data, isLoading: true, progressPct: 0, isAborted: false });
    setAccumulatedData?.({ data, isLoading: true, progressPct: 0, isAborted: false });
    let requestsCompleted = 0;
    for (const productType of productTypes) {
        //const result = await apolloClient.query({ query: GET_PRODUCTS_AGGREGATE, variables: { product_type_id: productTypeId } })
        const result = await apolloClient.query(generateQueryParams(productType));
        const newData = extractData(result);
        if (newData){
            data = [...data, ...newData];
            ++requestsCompleted;
            const progressPct = requestsCompleted / productTypes.length;
            setPartialData?.({ data: newData, isLoading: progressPct < 1, progressPct, isAborted: false }, productType);
            setAccumulatedData?.({ data, isLoading: progressPct < 1, progressPct, isAborted: false }, productType);
        }
        if (isAborted()){
            setPartialData?.({ data: [], isLoading: false, isAborted: true });
            setAccumulatedData?.({ data: [], isLoading: false, isAborted: true });
            break;
        }
        requestDone?.();
    }
}

function getGranularityMinutes(tmFirstProduct?: Date, tmLastProduct?: Date) : number {
    if (!tmFirstProduct || !tmLastProduct)
        return 60;
    const spanDays = differenceInDays(tmLastProduct, tmFirstProduct);
    if (spanDays < 2)
        return 10;
    if (spanDays < 14)
        return 1*60;
    return 24*60;
}

function generateZeroProductionGapFiller(granularityMinutes: number, tmGapStart?: Date, tmGapEnd?: Date) {
    const zeroClassification = Object.values(constants.classificationsDisplay).map((d: constants.ClassificationType) => d.tag).reduce((acc: { [key: string]: number }, d) => ({ ...acc, [d]: 0 }), {});
    const generateProduct = (tm: Date) => ({
        ...zeroClassification,
        tm,
        count_total: 0,
        count_failed: 0,
    })
    const tmFirst = tmGapStart ? addMinutes(tmGapStart, granularityMinutes) : null;
    const tmSecond = tmGapEnd ? subMinutes(tmGapEnd, granularityMinutes) : null;
    return [tmFirst ? generateProduct(tmFirst) : null, tmSecond ?  generateProduct(tmSecond) : null].filter(d => d !== null);
}

function generateTooltipLabelFormatter(granularityMinutes: number) {
    return (label: any) => {
        const value: Date = typeof label === 'number' ? new Date(label) : new Date();
        const formatDay = (value: Date) => format(value, 'dd/MM-yy');
        const formatHour = (value: Date) => `${format(value, 'HH:mm')} .. ${format(addMinutes(value, granularityMinutes), 'HH:mm')}`;
        const formatInterval = (value: Date) => granularityMinutes === 24 * 60 ? formatDay(value) : formatHour(value);
        return (
            <span>
                {formatInterval(value)}
                <br />
                <span style={{ fontSize: 'small' }}>
                    {format(value, 'd. MMMM yyyy')}
                </span>
            </span>)
    };
}

interface Target {
    name: string;
    target: number;
}
interface QuadrantTarget extends Target {
    quadrant: string;
}
interface ProductMeasurementWithTarget extends ProductMeasurement, QuadrantTarget {};
interface MeasurementsAndTargets extends ProductMeasurementWithTarget {
    quadrantValues: number[];
    lane: number;
}
function getToppingData(productTargetTopping : Array<ProductTypeMeasurement>, productMeasurementData : Array<ProductMeasurement>) : Array<MeasurementsAndTargets> {
    if (!productTargetTopping || !productMeasurementData)
        return [];

    const targetPrefix = 'target_';
    const targets : Array<Target> = productTargetTopping 
        .filter(d => d.measurement_type.name.startsWith(targetPrefix))
        .map(d => ({
            name: d.measurement_type.name.slice(targetPrefix.length),
            target: d.value
        }))
    ;
    const getMeasurementTypeName = (measurementTypeId : number) : string | null =>
        productTargetTopping.find(ptm => ptm.measurement_type?.id === measurementTypeId)?.measurement_type.name ?? null
    
    const getTarget = (measurementTypeId : number) : QuadrantTarget | null =>{
        const measurementTypeName = getMeasurementTypeName(measurementTypeId);
        if (!measurementTypeName)
            return null;
        const target = targets.find(target => measurementTypeName.includes(target.name));
        if (!target)
            return null;
        const distributionSuffix = '_Qx';
        const distributionSuffixLength = distributionSuffix.length;
        const quadrant = measurementTypeName.slice(-distributionSuffixLength+1);
        return {
            ...target,
            quadrant
        };
    }
    const productMeasurementsWithTargets : Array<ProductMeasurementWithTarget | null> = productMeasurementData.map(dpm => {
        const target = getTarget(dpm.measurement_type_id);
        if (!target)
            return null;
        return {
            ...dpm,
            ...target
        };
    })
    const common : Array<MeasurementsAndTargets>  = productMeasurementsWithTargets.reduce((acc : Array<any>, d, _i, arr) => {
        if (!d)
            return acc;
        const isAdded = acc.some(({ name }) => name === d.name);
        if (isAdded)
            return acc;
        const laneEntries : Array<ProductMeasurementWithTarget> = arr.filter((laneEntry) : laneEntry is ProductMeasurementWithTarget => laneEntry !== null && laneEntry && laneEntry.name === d?.name)
        const quadrants = Object.keys(laneEntries.reduce((acc, d) => ({ ...acc, [d.quadrant]: 0 }), {})).sort();
        const quadrantValues = quadrants.map(quadrant =>
            laneEntries
                .filter(d => d?.quadrant === quadrant)
                .reduce((acc, d, _i, arr) => acc + (d?.value || 0) / arr.length, 0)
        );
        const newEntry = {
            ...d,
            quadrantValues,
            lane: -1
        }
        return [...acc, newEntry];
    }, []);
    return common;
}


interface ToppingsProductTypeProps {
    productTargetTopping: Array<ProductTypeMeasurement>;
    productMeasurementsData: Array<ProductMeasurement> ;
};
function ToppingsProductType({ productTargetTopping, productMeasurementsData }: ToppingsProductTypeProps) {
    const toppingsData = getToppingData(productTargetTopping, productMeasurementsData);
    const toppings = toppingsData.map(d => ({
        name: d.name,
        coverage: d.quadrantValues.slice(1).map(v => v / d.target)
    }));

    return <Toppings toppings={toppings} />;
}

interface ProductTargetTopping {
    productType: ProductType;
    data: Array<ProductTypeMeasurement>;
}
interface ProductTopping extends IsLoading {
    productType: ProductType;
    data: Array<ProductMeasurement>;
}
interface ToppingsGroupProps {
    productTargetTopping: Array<ProductTargetTopping>;
    productMeasurementsData: RequestMultiDataResponse<ProductTopping>;
    fetchProductMeasurementData: (productType: ProductType, data: Array<ProductTypeMeasurement>) => void;
};
function ToppingsGroup({ productTargetTopping, productMeasurementsData, fetchProductMeasurementData }: ToppingsGroupProps) {
    return (
        <>
            {productTargetTopping.map(({ productType, data }) => {
                const measurementData = productMeasurementsData.data.find(d => d.productType.id === productType.id);
                return (
                    <GridContainer
                        header={<span>Topping {formatInterval(new Date(productType.tm_start), new Date(productType.tm_end))}</span>}
                        key={productType.id}
                        isLoading={measurementData?.isLoading}
                        hideContentWhenLoading
                    >
                        <>
                            {!measurementData && <Button size='large' variant="outlined" endIcon={<CloudDownloadIcon />} onClick={() => fetchProductMeasurementData(productType, data)}>Load</Button>}
                            {measurementData && <ToppingsProductType productTargetTopping={data} productMeasurementsData={measurementData.data} />}

                        </>
                    </GridContainer>
                );
            })}
        </>
    )
}

interface ProductType {
    id: number;
    name: string;
    tm_start: Date;
    tm_end: Date;
}

interface ProductTypeDetailsProps {
    productTypes: Array<ProductType>;
    tmFirstProduct?: Date;
    tmLastProduct?: Date;
    theme: any; // Theme & ThemeAddons
    onDetailsLoaded?: (productTypeId: number, progressPct: number) => void;
}

function ProductTypeDetails({ productTypes, tmFirstProduct, tmLastProduct, theme, onDetailsLoaded }: ProductTypeDetailsProps): JSX.Element {
    const [productsAggregateData, setProductsAggregateData] = useState<RequestMultiDataResponse<ProductsAggregate>>({ data: [] });
    const [classificationDetailsData, setClassificationDetailsData] = useState<RequestMultiDataResponse<ClassificationDetails>>({ data: [] });
    const [foreignObjectsData, setForeignObjectsData] = useState<RequestMultiDataResponse<ForeignObjects>>({ data: [] });
    const [productTypeMeasurementsData, setProductTypeMeasurementsData] = useState<RequestMultiDataResponse<ProductTargetTopping>>({ data: [] });
    const [productMeasurementsData, setProductMeasurementsData] = useState<RequestMultiDataResponse<ProductTopping>>({ data: [] });
    const setters = [setProductsAggregateData, setClassificationDetailsData, setForeignObjectsData, setProductTypeMeasurementsData];

    React.useEffect(() => {
        for(const setter of setters)
            setter({ data: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productTypes])

    const apolloClient = useApolloClient();
    const granularityMinutes = getGranularityMinutes(tmFirstProduct, tmLastProduct);
    useEffect(() => {
        for(const setter of setters)
            setter({ data: [], isLoading: true, progressPct: 0 });
        let abort = false;
        const isAborted = () => abort;
        let requestsDone = 0;
        const totalRequestCount = setters.length * productTypes.length;
        const requestDone = () => {
            if (!onDetailsLoaded)
                return;
            const progressPct = ++requestsDone / totalRequestCount;
            onDetailsLoaded(productTypes[0]?.id, progressPct);
        };
        const defaultParams = { apolloClient, productTypes, isAborted, requestDone };
        const createQueryParamsGenerator = (query: DocumentNode) => (productType: ProductType) => ({ query, variables: { product_type_id: productType.id } });
        const createGranularityQueryParamsGenerator = (query: DocumentNode) => (productType: ProductType) => ({ query, variables: { product_type_id: productType.id, granularity_minutes: granularityMinutes } });
        const createTimePeriodQueryParmsGenerator = (query: DocumentNode) => ({ tm_start, tm_end }: ProductType) => ({ query, variables: { tm_start, tm_end } });
        (async () => {
            request({ ...defaultParams, setAccumulatedData: setProductsAggregateData, extractData: r => r.data.products || [], generateQueryParams: createGranularityQueryParamsGenerator(GET_PRODUCTS_AGGREGATE) });
            request({ ...defaultParams, setAccumulatedData: setClassificationDetailsData, extractData: r => r.data.classificationDetails || [], generateQueryParams: createGranularityQueryParamsGenerator(GET_CLASSIFICATION_DETAILS) });
            request({ ...defaultParams, setAccumulatedData: setForeignObjectsData, extractData: r => r.data.foreignObjects || [], generateQueryParams: createTimePeriodQueryParmsGenerator(GET_FOREIGN_OBJECTS) });

            const handleProductTypeMeasurementsReceived = (d : RequestMultiDataResponse<ProductTypeMeasurement>, productType?: ProductType) => {
                if (!productType){
                    setProductTypeMeasurementsData({ ...d, data: [] });
                    return;
                }
                const productTargetTopping: ProductTargetTopping = { productType, data: d.data };
                setProductTypeMeasurementsData(oldData => ({ ...d, data: [...oldData.data, productTargetTopping] }));

            };
            await request({ ...defaultParams, setPartialData: handleProductTypeMeasurementsReceived, extractData: r => r.data.measurements || [], generateQueryParams: createQueryParamsGenerator (GET_PRODUCT_TYPE_MEASUREMENTS) });
        })();
        return () => { abort = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productTypes]);


    const setTimestamp = <T extends { time_bucket : Date }>(d: T) : (T & { tm : Date }) => ({ ...d, tm: new Date(d.time_bucket) });
    const products: Array<ProductsAggregateWithTm> = React.useMemo(() =>Object.values((productsAggregateData.data)
        .map(setTimestamp)
        .reduce((acc : {[key: string]: ProductsAggregateWithTm }, d) => {
            const key : string = typeof d.time_bucket === 'string' ? d.time_bucket : (d.time_bucket instanceof Date ? d.time_bucket.toString() : 'unknown');
            const oldData = acc[key];
            const newData: ProductsAggregateWithTm = {
                ...(oldData || d),
                count_total: (oldData?.count_total || 0) + d.count_total,
                count_failed: (oldData?.count_failed || 0) + d.count_failed
            };
            return { ...acc, [key]: newData }
        }, {})
    ), [productsAggregateData]);

    const classificationDetails: Array<ClassificationDetailsWithTm> = React.useMemo(() => Object.values(classificationDetailsData.data
        .map(setTimestamp)
        .reduce((acc: { [key: string]: ClassificationDetailsWithTm }, d) => {
            const key = `${d.time_bucket}#${d.classification_index}#${d.result_id}`;
            const oldData = acc[key];
            const newData: ClassificationDetailsWithTm = {
                ...(oldData || d),
                count: (oldData?.count || 0) + d.count
            };
            return {
                ...acc,
                [key]: newData
            }
        }, {})
    ), [classificationDetailsData]);
    const productionData = React.useMemo(() => mapToProductionData(products, classificationDetails), [products, classificationDetails]);

    const granularityMs = granularityMinutes * 60 * 1000;
    const productionDateWithGapFills = React.useMemo(() => productionData
        .sort((a, b) => a.tm.getTime() - b.tm.getTime())
        .map((d, i, arr) => { 
            if (i === 0) 
                return d;
            const currentTime = d.tm;
            const prevTime = arr[i - 1].tm;
            if (currentTime.getTime() - prevTime.getTime() > granularityMs)
                return [...generateZeroProductionGapFiller(granularityMinutes, prevTime, currentTime), d];
            return d;
        })
        .flat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [productionData, granularityMinutes]) ;

    const chartData = React.useMemo(() => productionDateWithGapFills.map(d => ({
        ...d,
        tm: d?.tm.getTime()
    })), [productionDateWithGapFills]);

    const dataKeys = React.useMemo(() => [Object.values(constants.classificationsDisplay).map(({ displayName, tag }: { displayName: string, tag: string }) => ({
        name: displayName,
        key: tag,
        color: theme.results[tag]
    }))], [theme]);

    const tagToDisplayName: { [key: string]: string } = Object.values(constants.classificationsDisplay)
        .reduce((acc: { [key: string]: string }, { displayName, tag }: { displayName: string, tag: string }) =>
            ({ ...acc, [tag]: displayName }), {});

    const classifications = React.useMemo(() => mapToClassificationData(products, classificationDetails), [products, classificationDetails]);

    const joinLoading = (...arr : Array<IsLoading>) : IsLoading => {
        const loadingNotInitialized = arr.every(d => typeof d.isLoading === 'undefined');
        if (loadingNotInitialized )
            return { };
        const noneLoading = arr.every(d => d.isLoading === false);
        if (noneLoading )
            return { isLoading: false, progressPct: 1 };
        const sumProgressPct = arr.reduce((acc, d) => (d.isLoading ? (d?.progressPct || 0) : 1) + acc, 0);
        const progressPct = sumProgressPct / arr.length;
        return { isLoading: true, progressPct };
    }


    const fetchProductMeasurementData = async (productType: ProductType, data: Array<ProductTypeMeasurement>) => {
        setProductMeasurementsData(oldData => ({ data: [...oldData.data.filter(d => d.productType.id !== productType.id), { productType, data: [], isLoading: true }] }))

        const measurement_prefix = 'dist_';
        const measurementTypeIds = data.filter(d => d.measurement_type.name.startsWith(measurement_prefix)).map(d => d.measurement_type.id);
        const measurementTypeIdsAsString = `{${measurementTypeIds.toString()}}`
        const generateQueryParams = ({ tm_start, tm_end }: ProductType) => ({ query: GET_PRODUCT_MEASUREMENTS, variables: { tm_start, tm_end, measurement_type_ids: measurementTypeIdsAsString } });

        let abort = false;
        const isAborted = () => abort;
        const setData = (d: RequestMultiDataResponse<ProductMeasurement>/*, productType?: ProductType*/) => {
            setProductMeasurementsData(oldData => ({ data: [...oldData.data.filter(d => d.productType.id !== productType.id), { ...d, productType }] }))
        };
        request({
            apolloClient,
            productTypes: [productType],
            isAborted,
            requestDone: () => { },
            setPartialData: setData,
            extractData: r => r.data.measurements || [],
            generateQueryParams
        });
    };

    if (productTypes.length === 0)
        return <div />;
    return (
        <div>
            <Grid container spacing={0} direction="row" justifyContent="space-around" alignItems="flex-start" style={{ minHeight: 'min(100vw,24vh)', width: '100%' }}>
                <GridContainer header='Production' {...joinLoading(productsAggregateData, classificationDetailsData)} hideContentWhenLoading>
                    <div style={{ width: 'min(100vw,95%)', height: 'min(100vw,400px)' }}>
                        <AreaChart
                            data={chartData}
                            dataKeys={dataKeys}
                            tickFormatter={(d: Date) => granularityMinutes >= 24 * 60 ? format(d, 'dd/MM') : format(d, 'HH:mm')}
                            tooltipLabelFormatter={generateTooltipLabelFormatter(granularityMinutes)}
                            tooltipFormatter={(value: number, name: string) => [value.toLocaleString(), tagToDisplayName[name]]}
                            legendFormatter={(value: string) => tagToDisplayName[value]}
                            scale='time'
                            type='number'
                        />
                    </div>
                </GridContainer>
                <GridContainer header='Classification' {...joinLoading(productsAggregateData, classificationDetailsData)} hideContentWhenLoading>
                    <Quality classifications={classifications} />
                </GridContainer>
                <GridContainer header='Foreign objects' {...foreignObjectsData} hideContentWhenLoading>
                    <ForeignObjectsTable {...foreignObjectsData} />
                </GridContainer>
                <GridContainer header='' {...productTypeMeasurementsData} hideContentWhenLoading>
                    <ToppingsGroup productMeasurementsData={productMeasurementsData} fetchProductMeasurementData={fetchProductMeasurementData} productTargetTopping={productTypeMeasurementsData.data} />
                </GridContainer>
            </Grid>
        </div>
    )
}

export default withTheme(ProductTypeDetails);