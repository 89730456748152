import { gql } from '@apollo/client';

export interface ProductsAggregate {
    time_bucket: Date;
    lane: number;
    product_type_id: number;
    count_total: number;
    count_failed: number;
}

export interface ProductsAggregateResult {
    products?: ProductsAggregate [];
}
export interface GetProductsAggregateVars {
        product_type_id: number;
        tm_start?: Date;
        tm_end?: Date;
        granularity_minutes?: number;
        limit?: number;
        offset?: number;
}

export const GET_PRODUCTS_AGGREGATE = gql`
    query GetProductsAggregate(
        $product_type_id: bigint!,
        $tm_start: timestamptz,
        $tm_end: timestamptz,
        $granularity_minutes: Int! = 10,
        $limit: Int! = 10000,
        $offset: Int
    ){
        products: production_intelligence_get_product_count(args: {
            _product_type_id: $product_type_id
            _granularity_minutes: $granularity_minutes
            _tm_start: $tm_start
            _tm_end: $tm_end
        }, 
            limit: $limit,
            offset: $offset
        ){
            time_bucket
            lane
            product_type_id
            count_total
            count_failed
        }
    }
`;

export const GET_PRODUCTS = gql`
    query GetProducts(
        $withProductType: Boolean! = true,
        $withErrors: Boolean! = false,
        $withMeasurements: Boolean! = false,
        $tmStart: timestamptz,
        $tmEnd: timestamptz,
        $tm: timestamptz,
        $failed: Boolean,
        $limit: Int! = 10,
        $offset: Int,
        $orderBy: [production_intelligence_products_order_by!],
        $withAggregates: Boolean! = true,
    ){
        productAggregates: production_intelligence_products_aggregate (
            where: { time: {_eq: $tm, _gte: $tmStart, _lte: $tmEnd }, failed: { _eq: $failed} },
        ) @include(if: $withAggregates){
            aggregate{
                count,
                min {
                    time
                }
            },
        }
        products: production_intelligence_products(
            where: {
                time: { _eq: $tm, _gte: $tmStart, _lte: $tmEnd }
                failed: { _eq: $failed }
            },
            limit: $limit,
            offset: $offset
            order_by: $orderBy
        ){
            time
            lane
            failed
            production_time_sec
            type @include(if: $withProductType )  {
                name
            }
            errors @include(if: $withErrors ) {
                type {
                    name
                }
            }
            measurements @include(if: $withMeasurements) {
                value
            }
        }
    }
`;

export const GET_CLASSIFIED_PRODUCTS = gql`
    query GetClassifiedProducts(
          $tm_start: timestamptz
        , $tm_end: timestamptz
        , $classification_id: Float! = 3
        , $limit: Int! = 100
        , $offset: Int
        , $withAggregates: Boolean! = true
    ){
        productAggregates: production_intelligence_v_classified_products_aggregate(
            where:{
                    time: { _gte: $tm_start, _lte: $tm_end}
            }
        ) @include(if: $withAggregates){
            aggregate {
                count
            }
        }
        products: production_intelligence_v_classified_products(
            where:{
                    time: { _gte: $tm_start, _lte: $tm_end}
                    classification: { _eq: $classification_id }
            }
            limit: $limit
            offset: $offset
        ){
            time
            lane
            result_type_name
            product_type_name
        }
  }
`;

export const GET_CLASSIFIED_PRODUCTS_2 = gql`
    query GetClassifiedProducts2(
          $customer_id: Int! = 7
        , $line_id: Int! = 32
        , $tm_start: timestamptz
        , $tm_end: timestamptz
        , $max_lane_index: Int! = 1
        , $classification_id: Float! = 3
        , $limit: Int! = 100
        , $offset: Int
        , $withAggregates: Boolean! = true
    ) {
    productAggregates: production_intelligence_product_measurements_aggregate(
        where:{
            customer_id: { _eq: $customer_id }
            line_id: { _eq: $line_id }
            measurement_type_id: { _eq: 75405755 }
            time: { _gte: $tm_start, _lte: $tm_end}
            lane: { _in: [0, $max_lane_index] }
            value: { _eq: $classification_id }
            product: {
                errors: {
                    result_type_id: { _eq: 4674486 }
                }
            }
        }
    ) @include(if: $withAggregates){
        aggregate {
            count
        }
    }
    products: production_intelligence_product_measurements(where: {
        customer_id: { _eq: $customer_id }
        line_id: { _eq: $line_id }
        measurement_type_id: { _eq: 75405755 }
        time: { _gte: $tm_start, _lte: $tm_end}
        lane: { _in: [0, $max_lane_index] }
        value: { _eq: $classification_id }
        product: {
            errors: {
                result_type_id: { _eq: 4674486 }
            }
        }
    }
        limit: $limit
        offset: $offset
        order_by: { time: desc }
    ){
        time
        value
        lane
        product {
            type {
                name
            }
            errors{
                type{
                    name
                }
            }
        }
    }
    }
`;

//  production_intelligence_products(where: {customer_id: { _eq:7}, line_id: {_eq: 32}, time: { _gte: "2021-07-01" }, failed: {_eq:true} }, limit:10 ) {

