import { gql } from '@apollo/client';

export const GET_LATEST_CLASSIFICATIONS = gql`
    query GetLatestClassifications(
        $limit: Int,
        $offset: Int
    ){
        classifications: production_intelligence_v_last_30_minutes_classifications (
            limit: $limit,
            offset: $offset
        ){
            lane
            min_time
            error_name
            count
            classification_index
        }
    }
`;

export interface ClassificationDetails {
    time_bucket: Date;
    classification_index: number;
    count: number;
    result_id: number;
    result_name: string;
}

export interface ClassificationDetailsResult {
    classificationDetails?: Array<ClassificationDetails>;
}
export interface GetClassificationDetailsVars {
        product_type_id: number;
        granularity_minutes?: number;
        limit?: number;
        offset?: number;
}

export const GET_CLASSIFICATION_DETAILS = gql`
query GetClassificationDetails(
    $product_type_id: bigint,
    $granularity_minutes: Int = 10,
    $limit: Int,
    $offset: Int
) {
  classificationDetails: production_intelligence_get_classifications(
    args: { 
        _product_type_id: $product_type_id,
        _granularity_minutes: $granularity_minutes 
    }
    limit: $limit,
    offset: $offset,
    order_by: { time_bucket: asc }
  ){
    time_bucket
    classification_index
    count
    result_id
    result_name
  }
}
`