import { Tooltip, Checkbox, List, ListItem, ListItemIcon, ListItemText, Slider } from '@mui/material';

function OverlayEntry({ name, toggleOverlay, isEnabled }) {
  //return <li style={{ fontWeight: isEnabled ? 'bold' : 'normal' }} onClick={toggleOverlay}>{name}</li>;
  return (
    <ListItem button selected={isEnabled} onClick={toggleOverlay}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={isEnabled}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': name }}
        />
      </ListItemIcon>
      <ListItemText primary={name} id={name} />
    </ListItem>
  );
}


function ValueLabelComponent({ children, open, value }) {
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={`${parseInt(value)} %`}>
      {children}
    </Tooltip>
  );
}

function Overlays({ className, overlays, toggleOverlay, overlaysEnabled, Header, overlayOpacity, setOverlayOpacity }) {
          //<Slider ValueLabelComponent={ValueLabelComponent}
  const hasOverlays = Object.values(overlays).length > 1;
  return (
    <List
      className={className}
      subheader={<Header>Overlays</Header>}
    >
      {hasOverlays && Object.entries(overlays || {}).map(([k, v]) => <OverlayEntry
        key={k}
        name={v.Name}
        toggleOverlay={() => toggleOverlay(v.Name)}
        isEnabled={overlaysEnabled.includes(v.Name)}
      />)}
      {hasOverlays &&
        <ListItem>
          <Slider
            value={overlayOpacity * 100}
            onChange={(ev, newValue) => setOverlayOpacity(newValue / 100.0)}
            aria-label="custom thumb label"
            defaultValue={20}
            step={5}
            min={0}
            max={100}
          />
        </ListItem>
      }
    </List>
  );
}

export default Overlays;