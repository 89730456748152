import * as React from 'react';
import { styled } from "@mui/material";
import { format, isSameDay, isSameMonth, isSameYear, differenceInSeconds  } from "date-fns";

const AvoidLineBreak = styled('div')({
    display: 'inline-block'
})

export const formatPercentage = (pctAsDecimal : number) : string => ((Number.isFinite(pctAsDecimal) && !isNaN(pctAsDecimal)) ? (pctAsDecimal * 100).toFixed(1).toLocaleString() : '-' ) + ' %';
export const formatNumber  = (number : number) : string => (Number.isFinite(number) && !isNaN(number)) ? number.toLocaleString() : '-';

export const formatInterval = (start: Date, end: Date) => {
    const formatLong = (d: Date) => format(d, 'HH:mm d/M yyyy');
    const formatMedium = (d: Date) => format(d, 'HH:mm d/M');
    const formatShort = (d: Date) => format(d, 'HH:mm');
    if (!isSameYear(start, end))
        return <><AvoidLineBreak>{formatLong(start)}</AvoidLineBreak> .. <AvoidLineBreak>{formatLong(end)}</AvoidLineBreak></>;
    if (!isSameMonth(start, end) || !isSameDay(start, end))
        return <><AvoidLineBreak>{formatMedium (start)}</AvoidLineBreak> .. <AvoidLineBreak>{formatLong (end)}</AvoidLineBreak></>;
    return <><AvoidLineBreak>{formatShort(start)}</AvoidLineBreak> .. <AvoidLineBreak>{formatLong(end)}</AvoidLineBreak></>;
};

export const reload = (...message: any[]) => {
    // Do not reload page more than once per 10 minutes
    const minTimeBetweenReloadsSeconds = 10 * 60;
    const lastReloadKey = 'last_reload';
    const lastReloadString  = window.localStorage.getItem(lastReloadKey);
    if (lastReloadString && !isNaN(Date.parse(lastReloadString))) {
        const lastReloadTm = new Date(lastReloadString);
        const timeSinceLastReloadInMinutes = differenceInSeconds(new Date(), lastReloadTm);
        if (timeSinceLastReloadInMinutes < minTimeBetweenReloadsSeconds){
            logToLocalStorage(`Reload not performed, since last reload was ${timeSinceLastReloadInMinutes} minutes ago`, message);
            return;
        }
    }
    window.localStorage.setItem(lastReloadKey, (new Date()).toString());
    logToLocalStorage(`Performing reload`, ...message);
    window.location.reload();
}

export const logToLocalStorage = (...message: any[]) => {
    const maxLogEntries = 100; 
    const logKey = 'ls_log';
    console.log(message)
    const logString = window.localStorage.getItem(logKey);
    const log = logString ? JSON.parse(logString) : [];
    const tm = new Date();
    const newLog = [...log, { tm, message }].slice(-maxLogEntries);
    window.localStorage.setItem(logKey, JSON.stringify(newLog));
}